import React, { PureComponent } from 'react';
import { Field, reduxForm } from 'redux-form';
import { compose } from 'recompose';
import { connect } from 'react-redux';
import CouponIcon from 'mdi-react/CouponIcon';
import EyeIcon from 'mdi-react/EyeIcon';
import KeyVariantIcon from 'mdi-react/KeyVariantIcon';
import AccountOutlineIcon from 'mdi-react/AccountOutlineIcon';
import PropTypes from 'prop-types';

import Input from '../../../components/Input';
import {
  alphaNumeric,
  required,
  minLength6,
  maxLength30,
} from '../../../utils/validators';
import { getCoupon } from '../../../utils/coupon';
import auth from '../../../../auth';
import hideable from '../../../utils/hideable';

class CompleteRegisterForm extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      showPassword: false,
    };

    this.showPassword = this.showPassword.bind(this);
  }

  showPassword(e) {
    e.preventDefault();
    this.setState({
      showPassword: !this.state.showPassword,
    });
  }

  renderErrors() {
    const { registerErrors } = this.props;

    return registerErrors.map((error, i) => <li key={i}>{error.error_msg}</li>);
  }

  render() {
    const { handleSubmit, email } = this.props;

    return (
      <form className="form completion-form" onSubmit={handleSubmit}>
        <p className="welcome-back">
          Welcome back, <span>{email}</span>
        </p>
        <h3>Complete your registration</h3>
        <ul className="mb-3 errors-list">{this.renderErrors()}</ul>
        <div className="form__form-group">
          <span className="form__form-group-label">Username</span>
          <div className="form__form-group-field">
            <div className="form__form-group-icon">
              <AccountOutlineIcon />
            </div>
            <Field
              name="username"
              component={Input}
              type="text"
              label="Username"
              validate={[alphaNumeric, required, minLength6, maxLength30]}
            />
          </div>
        </div>
        <div className="form__form-group form__form-group--forgot">
          <span className="form__form-group-label">Password</span>
          <div className="form__form-group-field">
            <div className="form__form-group-icon">
              <KeyVariantIcon />
            </div>
            <Field
              name="password"
              component={Input}
              type={this.state.showPassword ? 'text' : 'password'}
              label="Password"
              validate={[required, minLength6]}
            />
            <button
              type="button"
              className={`form__form-group-button${
                this.state.showPassword ? ' active' : ''
              }`}
              onClick={e => this.showPassword(e)}
            >
              <EyeIcon />
            </button>
          </div>
        </div>
        <div className="form__form-group">
          <span className="form__form-group-label">Coupon Code</span>
          <div className="form__form-group-field">
            <div className="form__form-group-icon">
              <CouponIcon />
            </div>
            <Field
              name="coupon"
              component={Input}
              type="text"
              label="Coupon Code"
            />
          </div>
        </div>
        <div className="account__btns">
          <button type="submit" className="btn btn-primary account__btn">
            Complete registration
          </button>
        </div>
      </form>
    );
  }
}

CompleteRegisterForm.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  registerErrors: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  email: PropTypes.string.isRequired,
};

const mapStateToProps = state => ({
  registerErrors: auth.selectors.getRegisterErrors(state),
  isVisible: auth.selectors.isRegistrationCompletionVisible(state),
  email: auth.selectors.getConfirmationEmail(state),
});

const mapDispatchToProps = {
  onSubmit: auth.actions.register,
};

const enhance = compose(
  connect(
    mapStateToProps,
    mapDispatchToProps
  ),
  reduxForm({
    form: 'registerForm',
    initialValues: { coupon: getCoupon() },
  })
);

export default enhance(hideable(CompleteRegisterForm));
