import * as types from './actionTypes';
import { callAPI } from '../api';
import { requestHeaders } from '../api/utils';
import ui from '../ui';
import { actionCreator } from '../app/utils/actionCreator';

export const updatePaymentsChartData = actionCreator(
  types.UPDATE_PAYMENTS_CHART_VALUE
);

export const updateSelectedInvoice = actionCreator(
  types.UPDATE_SELECTED_INVOICE
);

export const sendGaEventOnPayoutRequest = actionCreator(
  types.REQUEST_PAYOUT_GA_EVENT
);

export const setInvoicesData = data => ({
  type: types.SET_INVOICES_DATA,
  payload: data.map(payout => ({
    ...payout,
    amount: payout.amount.toFixed(2),
  })),
});

const transformData = data =>
  data.map(({ date, status, amount }) => ({
    date,
    [status]: amount.toFixed(2),
  }));

export const transformPaymentsChartData = data => dispatch => {
  dispatch(updatePaymentsChartData(transformData(data)));
};

export const onInvoiceTableRowClick = uuid => dispatch => {
  dispatch(updateSelectedInvoice(uuid));
  dispatch(ui.actions.toggleModal(ui.constants.MODALS.INVOICE_TABLE_MODAL));
};

export const fetchPayouts = () => dispatch =>
  dispatch(
    callAPI({
      method: 'GET',
      endpoint: '/api/v1/payouts/',
      headers: requestHeaders(true),
      types: [
        types.FETCH_PAYOUTS_REQUEST,
        {
          type: types.FETCH_PAYOUTS_SUCCESS,
          payload: (action, state, res) =>
            res.json().then(data => {
              dispatch(transformPaymentsChartData(data));
              return data;
            }),
        },
        types.FETCH_PAYOUTS_FAILURE,
      ],
    })
  );

export const fetchInvoices = () => dispatch =>
  dispatch(
    callAPI({
      method: 'GET',
      endpoint: '/api/v1/invoices/',
      headers: requestHeaders(true),
      types: [
        types.FETCH_INVOICES_REQUEST,
        {
          type: types.FETCH_INVOICES_SUCCESS,
          payload: (action, state, res) =>
            res.json().then(data => {
              dispatch(setInvoicesData(data));
            }),
        },
        types.FETCH_INVOICES_FAILURE,
      ],
    })
  );

export const createInvoice = () => dispatch =>
  dispatch(
    callAPI({
      method: 'POST',
      endpoint: '/api/v1/invoices/',
      headers: requestHeaders(true),
      types: [
        types.CREATE_INVOICE_REQUEST,
        {
          type: types.CREATE_INVOICE_SUCCESS,
          payload: (action, state, res) => {
            dispatch(fetchPayouts());
            dispatch(fetchInvoices());
            return res;
          },
        },
        types.CREATE_INVOICE_FAILURE,
      ],
    })
  );

export const onRequestPayoutClick = () => dispatch => {
  dispatch(ui.actions.toggleModal(ui.constants.MODALS.USER_DETAILS_MODAL));
  dispatch(sendGaEventOnPayoutRequest());
};
