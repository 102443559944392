import { NAME } from './constants';

export const CREATE_APP_REQUEST = `${NAME}.CREATE_APP_REQUEST`;
export const CREATE_APP_SUCCESS = `${NAME}.CREATE_APP_SUCCESS`;
export const CREATE_APP_FAILURE = `${NAME}.CREATE_APP_FAILURE`;

export const FETCH_APPS_REQUEST = `${NAME}.FETCH_APPS_REQUEST`;
export const FETCH_APPS_SUCCESS = `${NAME}.FETCH_APPS_SUCCESS`;
export const FETCH_APPS_FAILURE = `${NAME}.FETCH_APPS_FAILURE`;

export const DOWNLOAD_SDK_REQUEST = `${NAME}.DOWNLOAD_SDK_REQUEST`;
export const DOWNLOAD_SDK_SUCCESS = `${NAME}.DOWNLOAD_SDK_SUCCESS`;
export const DOWNLOAD_SDK_FAILURE = `${NAME}.DOWNLOAD_SDK_FAILURE`;

export const DOWNLOAD_INVOICE_PDF_REQUEST = `${NAME}.DOWNLOAD_INVOICE_PDF_REQUEST`;
export const DOWNLOAD_INVOICE_PDF_SUCCESS = `${NAME}.DOWNLOAD_INVOICE_PDF_SUCCESS`;
export const DOWNLOAD_INVOICE_PDF_FAILURE = `${NAME}.DOWNLOAD_INVOICE_PDF_FAILURE`;

export const UPDATE_SELECTED_APP = `${NAME}.UPDATE_SELECTED_APP`;

export const FETCH_DISTRIBUTION_PLATFORMS_REQUEST = `${NAME}.FETCH_DISTRIBUTION_PLATFORMS_REQUEST`;
export const FETCH_DISTRIBUTION_PLATFORMS_SUCCESS = `${NAME}.FETCH_DISTRIBUTION_PLATFORMS_SUCCESS`;
export const FETCH_DISTRIBUTION_PLATFORMS_FAILURE = `${NAME}.FETCH_DISTRIBUTION_PLATFORMS_FAILURE`;
