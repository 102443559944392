import * as types from './actionTypes';
import { callAPI } from '../api';
import { requestHeaders } from '../api/utils';

export const fetchPages = () =>
  callAPI({
    method: 'GET',
    endpoint: '/api/v1/pages/',
    headers: requestHeaders(true),
    types: [
      types.FETCH_PAGES_REQUEST,
      types.FETCH_PAGES_SUCCESS,
      types.FETCH_PAGES_FAILURE,
    ],
  });

export const fetchElements = id =>
  callAPI({
    method: 'GET',
    endpoint: `/api/v1/pages/${id}/elements/`,
    headers: requestHeaders(true),
    types: [
      types.FETCH_ELEMENTS_REQUEST,
      types.FETCH_ELEMENTS_SUCCESS,
      types.FETCH_ELEMENTS_FAILURE,
    ],
  });
