import React from 'react';
import { connect } from 'react-redux';

import { isMacosInstructionsVisible } from '../../../ui/selectors';
import hideable from '../../utils/hideable';

const MacosInstructions = () => (
  <div>
    <h2 className="title">Install Instructions</h2>
    <ol>
      <li className="point">
        Extract <span className="inline-code">&lt;your_SDK_name&gt;</span>{' '}
        .framework from provided archive.
      </li>
      <li className="point">
        Move <span className="inline-code">.framework</span> to your project
        location.
      </li>
      <li className="point">
        Using Xcode, add{' '}
        <span className="inline-code">&lt;your_SDK_name&gt;.framework</span>{' '}
        file{' '}
        <span className="inline-code">
          (File -&gt; Add files to “&lt;your_project_name&gt;”)
        </span>
        .
      </li>
      <li className="point">
        Create Bridging Header to your project if it is not already there.
      </li>
      <ul>
        <li className="subpoint">
          The easiest way to create Bridging Header is by creating empty C/C++
          file and selecting option “Create Bridging Header”. Once header file
          is created, .cpp file is not needed and can be deleted.
        </li>
      </ul>
      <li className="point">
        In your Bridging Header file include SDK header by writing{' '}
        <span className="inline-code">
          “#include “&lt;path_to_header_file&gt;””
        </span>{' '}
        . For example, if we have an SDK called proxy.framework that is in the
        same directory as header file, the include would be{' '}
        <span className="inline-code">
          “#include “proxy.framework/Headers/proxy.h””
        </span>
        .
      </li>
      <li className="point">
        Make sure the framework is embedded by going to your app Target
        (TARGETS) and under “Frameworks, Libraries and Embedded Content” find{' '}
        <span className="inline-code">&lt;your_SDK_name.framework&gt;</span> and
        make sure option “Embed & Sign” is selected.
      </li>
      <li className="point">Sign the Application with your developer ID.</li>
    </ol>
    <h2 className="title">Launch instructions</h2>
    <ol>
      <li className="point">
        On your app start point call method{' '}
        <span className="inline-code">start_proxy()</span>. Method returns 0 if
        an app was started successfully and -1 if an error appeared. This way
        you can make sure SDK is started and working successfully.
      </li>
      <li className="point">
        On closing your app call method{' '}
        <span className="inline-code">stop_proxy()</span>. Method returns 0 if
        an app was started successfully and -1 if an error appeared. This way
        you can make sure SDK is stopped successfully. This call is optional. It
        makes sure SDK has closed properly before closing the app.
      </li>
    </ol>
    <h2 className="title">Other notes</h2>
    <p className="note">
      These instructions were tested with Swift 5.0 and OS X 10.14. SDK should
      be compatible with OS X versions 10.6 and newer. While SDK itself is not
      yet available to be installed from Cocoapods or Carthage, these package
      managers are fully compatible and can be used alongside SDK.
    </p>
  </div>
);

const mapStateToProps = state => ({
  isVisible: isMacosInstructionsVisible(state),
});

export default connect(mapStateToProps)(hideable(MacosInstructions));
