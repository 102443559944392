import React from 'react';

const hideable = Component =>
  function withConditionalComponent({ isVisible, ...props }) {
    if (isVisible) {
      return <Component {...props} />;
    }
    return null;
  };

export default hideable;
