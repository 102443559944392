import { applyMiddleware } from 'redux';
import thunk from 'redux-thunk';
import { routerMiddleware } from 'react-router-redux';
import { apiMiddleware } from 'redux-api-middleware';

import { sessionStorageMiddleware } from '../../auth/middleware';
import { analyticsMiddleware } from './analyticsMiddleware';

import { history } from './history';

export default applyMiddleware(
  thunk,
  routerMiddleware(history),
  apiMiddleware,
  sessionStorageMiddleware,
  analyticsMiddleware
);
