import React from 'react';
import PropTypes from 'prop-types';
import { Col, Row } from 'reactstrap';
import { connect } from 'react-redux';

import ui from '../../../ui';
import users from '../../../users';
import apps from '../../../apps';
import hideable from '../../utils/hideable';
import AppsTable from './components/AppsTable';
import { SDK_MODAL } from './components/modalNames';

const SDK = ({ onClick }) => (
  <>
    <Row>
      <Col md={12}>
        <h3 className="page-title">SDK</h3>
      </Col>
    </Row>
    <Row>
      <Col md={12}>
        <button className="btn btn-primary" onClick={onClick}>
          Request an SDK
        </button>
      </Col>
    </Row>
    <Row>
      <Col md={12}>
        <AppsTable />
      </Col>
    </Row>
  </>
);

SDK.propTypes = {
  onClick: PropTypes.func.isRequired,
};

const mapStateToProps = state => ({
  tosData: users.selectors.getTosData(state),
  isVisible: apps.selectors.userHasApps(state),
});

const mapDispatchToProps = dispatch => ({
  onClick: () => dispatch(ui.actions.toggleModal(SDK_MODAL)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(hideable(SDK));
