import React, { Component } from 'react';
import { hot } from 'react-hot-loader';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { withRouter } from 'react-router';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { compose } from 'recompose';

import { NotificationContainer } from 'react-notifications';
import ui from '../../../ui';
import content from '../../../content';
import auth from '../../../auth';

class Wrapper extends Component {
  constructor() {
    super();
    this.state = {
      loading: true,
      loaded: false,
    };

    this.load = this.load.bind(this);
  }

  componentDidMount() {
    const { fetchPages, pagesFetching, isAuthenticated } = this.props;

    this.load();
    if (!pagesFetching && isAuthenticated) {
      fetchPages();
    }
  }

  componentDidUpdate(prevProps) {
    const { pagesFetching, isAuthenticated, fetchPages } = this.props;
    if (
      prevProps.isAuthenticated !== isAuthenticated &&
      isAuthenticated &&
      !pagesFetching
    ) {
      fetchPages();
    }
  }

  load() {
    window.addEventListener('load', () => {
      this.setState({ loading: false });
      setTimeout(() => this.setState({ loaded: true }), 500);
    });
  }

  render() {
    const { loaded, loading } = this.state;
    const { sidebar } = this.props;

    const wrapperClass = classNames({
      wrapper: true,
      'wrapper--full-width': sidebar.collapse,
    });

    return (
      <div>
        {!loaded && (
          <div className={`load${loading ? '' : ' loaded'}`}>
            <div className="load__icon-wrap">
              <svg className="load__icon">
                <path
                  fill="#4ce1b6"
                  d="M12,4V2A10,10 0 0,0 2,12H4A8,8 0 0,1 12,4Z"
                />
              </svg>
            </div>
          </div>
        )}
        <div className="theme-light">
          <NotificationContainer />
          <div className={wrapperClass}>{this.props.routes}</div>
        </div>
      </div>
    );
  }
}

Wrapper.propTypes = {
  sidebar: PropTypes.shape({}).isRequired,
  routes: PropTypes.element.isRequired,
  fetchPages: PropTypes.func.isRequired,
  pagesFetching: PropTypes.bool.isRequired,
  isAuthenticated: PropTypes.bool.isRequired,
};

const enhance = compose(
  withRouter,
  connect(
    state => ({
      sidebar: ui.selectors.getSidebar(state),
      pagesFetching: content.selectors.pagesFetching(state),
      isAuthenticated: auth.selectors.isAuthenticated(state),
    }),
    dispatch => ({
      dispatch,
      fetchPages: bindActionCreators(content.actions.fetchPages, dispatch),
    })
  ),
  hot(module)
);

export default enhance(Wrapper);
