import { connect } from 'react-redux';

import billing from '../../../../../billing';
import PaymentsChart from './PaymentsChart';

const title = 'Payments';
const brushKey = 'date';
const brushStartIndex = 0;

const mapStateToProps = state => ({
  title,
  data: billing.selectors.getAllPayoutsChartData(state),
  hasBrush: true,
  brushKey,
  brushStartIndex,
  isFetching: billing.selectors.isPayoutsFetching(state),
});

export default connect(mapStateToProps)(PaymentsChart);
