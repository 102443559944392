import Immutable from 'seamless-immutable';

import * as types from './actionTypes';

const DEFAULT_COUNTRIES_STATE = {
  data: [],
  isFetching: false,
};

const DEFAULT_STATE = Immutable({
  countries: DEFAULT_COUNTRIES_STATE,
  selectedPricingCountry: null,
});

export default (state = DEFAULT_STATE, { payload, type }) => {
  switch (type) {
    case types.FETCH_COUNTRIES_REQUEST:
      return Immutable.set(state, 'countries', {
        ...DEFAULT_COUNTRIES_STATE,
        isFetching: true,
      });
    case types.FETCH_COUNTRIES_SUCCESS:
      return Immutable.set(state, 'countries', {
        ...DEFAULT_COUNTRIES_STATE,
        data: payload,
        isFetching: false,
      });
    case types.FETCH_COUNTRIES_FAILURE:
      return Immutable.set(state, 'countries', {
        ...DEFAULT_COUNTRIES_STATE,
        isFetching: false,
      });
    case types.PRICING_COUNTRY_SELECTED:
      return Object.assign({}, state, {
        selectedPricingCountry: payload,
      });

    default:
      return state;
  }
};
