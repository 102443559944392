import React from 'react';
import PropTypes from 'prop-types';

import Chart from '../../../../components/Charts/Chart';

const CHART_COLORS = {
  PAID: '#4ce1b6',
  PENDING: '#f6da6e',
  UNPAID: '#ff4861',
};

const PaymentsChart = ({
  title,
  data,
  brushStartIndex,
  brushKey,
  hasBrush,
  isFetching,
}) => (
  <Chart
    type={'bar'}
    title={title}
    data={data}
    lines={[
      {
        name: 'paid',
        type: 'monotone',
        dataKey: 'paid',
        fill: CHART_COLORS.PAID,
        stroke: CHART_COLORS.PAID,
      },
      {
        name: 'pending',
        type: 'monotone',
        dataKey: 'pending',
        fill: CHART_COLORS.PENDING,
        stroke: CHART_COLORS.PENDING,
      },
      {
        name: 'unpaid',
        type: 'monotone',
        dataKey: 'unpaid',
        fill: CHART_COLORS.UNPAID,
        stroke: CHART_COLORS.UNPAID,
      },
    ]}
    XAxisDataKey={'date'}
    isFetching={isFetching}
    hasBrush={hasBrush}
    brushOptions={{
      dataKey: brushKey,
      startIndex: brushStartIndex,
    }}
    legendOptions={{
      wrapperStyle: {
        paddingTop: '10px',
      },
    }}
  />
);

PaymentsChart.propTypes = {
  title: PropTypes.string.isRequired,
  data: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  hasBrush: PropTypes.bool.isRequired,
  isFetching: PropTypes.bool.isRequired,
  brushKey: PropTypes.string.isRequired,
  brushStartIndex: PropTypes.number.isRequired,
};

export default PaymentsChart;
