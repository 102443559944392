import React, { PureComponent } from 'react';
import { Col, Container, Row, Card, CardBody } from 'reactstrap';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

import stats from '../../../stats';
import CountryPicker from './components/CountryPickerContainer';
import MapContainer from './components/MapContainer';

class Pricing extends PureComponent {
  componentDidMount() {
    const { tierPricePerCountryFetching, fetchData } = this.props;
    if (!tierPricePerCountryFetching) {
      fetchData();
    }
  }

  render() {
    return (
      <Container className="pricing">
        <Row>
          <Col md={12}>
            <Card>
              <CardBody>
                <CountryPicker />
                <Col md={12} lg={12}>
                  <Card>
                    <MapContainer />
                  </Card>
                </Col>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    );
  }
}

Pricing.propTypes = {
  tierPricePerCountryFetching: PropTypes.bool.isRequired,
  fetchData: PropTypes.func.isRequired,
};

const enhance = connect(
  state => ({
    tierPricePerCountryFetching: stats.selectors.isTierPricePerCountryFetching(
      state
    ),
    data: stats.selectors.getFormatedTierPricePerCountryData(state),
  }),
  dispatch => ({
    dispatch,
    fetchData: bindActionCreators(
      stats.actions.fetchTierPricePerCountry,
      dispatch
    ),
  })
);

export default enhance(Pricing);
