import React from 'react';
import { Col, Container, Row } from 'reactstrap';
import moment from 'moment';

import AccountBalance from './components/AccountBalance';
import DailyActiveUsersChart from '../../components/Charts/DailyActiveUsersChart';
import DailyActiveUsersPerTierChart from '../../components/Charts/DailyActiveUsersPerTierChart';
import DailyActiveUsersPerAppChart from '../../components/Charts/DailyActiveUsersPerAppChart';
import DailyEarningsChartContainer from '../../components/Charts/DailyEarningsChart/DailyEarningsChartContainer';

const Dashboard = () => {
  const chartFilters = {
    date_from: moment()
      .subtract(30, 'days')
      .format('YYYY-MM-DD'),
    date_to: moment()
      .subtract(1, 'days')
      .format('YYYY-MM-DD'),
  };

  return (
    <Container className="dashboard">
      <Row>
        <Col md={12}>
          <h3 className="page-title">Dashboard</h3>
        </Col>
      </Row>
      <AccountBalance />
      <Row>
        <Col md={12}>
          <DailyActiveUsersChart overrideFilters={chartFilters} />
        </Col>
      </Row>
      <Row>
        <Col md={12}>
          <DailyActiveUsersPerTierChart overrideFilters={chartFilters} />
        </Col>
      </Row>
      <Row>
        <Col md={12}>
          <DailyActiveUsersPerAppChart overrideFilters={chartFilters} />
        </Col>
      </Row>
      <Row>
        <Col md={12}>
          <DailyEarningsChartContainer overrideFilters={chartFilters} />
        </Col>
      </Row>
    </Container>
  );
};

export default Dashboard;
