import React, { PureComponent } from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import users from '../../users';

class Footer extends PureComponent {
  componentDidMount() {
    const { fetchTos } = this.props;

    fetchTos();
  }

  render() {
    const {
      tosData: { user_agreed: userAgreed },
    } = this.props;

    return (
      <footer className="footer">
        {userAgreed ? (
          <p>
            <Link to={'/tos'}>Terms of Service</Link>
          </p>
        ) : (
          '© Globalhop 2019'
        )}
      </footer>
    );
  }
}

Footer.propTypes = {
  fetchTos: PropTypes.func.isRequired,
  tosData: PropTypes.shape().isRequired,
};

const mapStateToProps = state => ({
  tosData: users.selectors.getTosData(state),
});

const mapDispatchToProps = {
  fetchTos: users.actions.fetchTos,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Footer);
