import { connect } from 'react-redux';

import DailyEarningsChart from './DailyEarningsChart';
import stats from '../../../../stats';
import auth from '../../../../auth';
import { STATISTICS } from '../../../utils/routes';

const aggregatedLines = [
  {
    name: 'Daily earnings (USD)',
    type: 'monotone',
    dataKey: 'daily_earnings',
  },
];

const mapStateToProps = state => {
  const isSuperUser = auth.selectors.isSuperUser(state);
  const shouldPassNotAggregatedData =
    window.location.pathname === STATISTICS && isSuperUser;

  return {
    data: shouldPassNotAggregatedData
      ? stats.selectors.getDailyEarningsData(state)
      : stats.selectors.getFormatedDailyEarning(state),
    lines: shouldPassNotAggregatedData
      ? stats.selectors.getDailyEarningsChartLines(state)
      : aggregatedLines,
    isFetching: stats.selectors.isDailyEarningsFetching(state),
    filters: stats.selectors.getFilters(state),
  };
};

const mapDispatchToProps = dispatch => ({
  fetchData: filter => dispatch(stats.actions.fetchDailyEarnings(filter)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(DailyEarningsChart);
