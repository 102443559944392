import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import MaterialTableHead from './components/MaterialTableHead';

window.__MUI_USE_NEXT_TYPOGRAPHY_VARIANTS__ = true; // eslint-disable-line no-underscore-dangle
const EMPTY_ROW_HEIGHT = 49;

export default class MaterialTable extends PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      order: props.order,
      orderBy: props.orderBy,
      page: props.initialPage,
      rowsPerPage: props.rowsPerPage,
    };
  }

  componentDidUpdate(prevProps) {
    if (this.props.rowsPerPage !== prevProps.rowsPerPage) {
      this.setState({
        rowsPerPage: this.props.rowsPerPage,
      });
    }
  }

  getSorting(order, orderBy) {
    if (orderBy === 'date') {
      return order === 'desc'
        ? (a, b) => Date.parse(b[orderBy]) - Date.parse(a[orderBy])
        : (a, b) => Date.parse(a[orderBy]) - Date.parse(b[orderBy]);
    }
    return order === 'desc'
      ? (a, b) => b[orderBy] - a[orderBy]
      : (a, b) => a[orderBy] - b[orderBy];
  }

  handleRequestSort = (event, property) => {
    const orderBy = property;
    let order = 'desc';

    if (this.state.orderBy === property && this.state.order === 'desc') {
      order = 'asc';
    }

    this.setState({ order, orderBy });
  };

  handleChangePage = (event, page) => {
    this.setState({ page });
  };

  handleChangeRowsPerPage = event => {
    this.setState({ rowsPerPage: event.target.value });
  };

  render() {
    const { data, cols, onRowClick, paginationEnable } = this.props;
    const { order, orderBy, rowsPerPage, page } = this.state;
    const emptyRows =
      rowsPerPage - Math.min(rowsPerPage, data.length - page * rowsPerPage);

    return (
      <div>
        <div className="material-table__wrap">
          <Table className="material-table">
            <MaterialTableHead
              order={order}
              orderBy={orderBy}
              onRequestSort={this.handleRequestSort}
              cols={cols}
            />
            <TableBody>
              {data
                .asMutable()
                .sort(this.getSorting(order, orderBy))
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((row, i) => (
                  <TableRow
                    className="material-table__row"
                    key={`${i}_table_body_row`}
                    onClick={() => onRowClick(row)}
                  >
                    {cols(row).map(
                      ({ dataKey, Component, align = 'center' }) => (
                        <TableCell
                          key={`${dataKey}_col`}
                          style={{
                            textAlign: align,
                          }}
                        >
                          {row[dataKey] || Component}
                        </TableCell>
                      )
                    )}
                  </TableRow>
                ))}
              {emptyRows > 0 && (
                <TableRow style={{ height: EMPTY_ROW_HEIGHT * emptyRows }}>
                  <TableCell colSpan={6} />
                </TableRow>
              )}
            </TableBody>
          </Table>
        </div>
        {paginationEnable ? (
          <TablePagination
            component="div"
            className="material-table__pagination"
            count={data.length}
            rowsPerPage={rowsPerPage}
            page={page}
            backIconButtonProps={{ 'aria-label': 'Previous Page' }}
            nextIconButtonProps={{ 'aria-label': 'Next Page' }}
            onChangePage={this.handleChangePage}
            onChangeRowsPerPage={this.handleChangeRowsPerPage}
            rowsPerPageOptions={[5, 10, 15]}
          />
        ) : null}
      </div>
    );
  }
}

MaterialTable.propTypes = {
  data: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  cols: PropTypes.func.isRequired,
  order: PropTypes.string,
  orderBy: PropTypes.string,
  initialPage: PropTypes.number,
  rowsPerPage: PropTypes.number,
  onRowClick: PropTypes.func,
  paginationEnable: PropTypes.bool,
};

MaterialTable.defaultProps = {
  order: 'asc',
  orderBy: '',
  initialPage: 0,
  rowsPerPage: 5,
  paginationEnable: false,
  onRowClick: () => {},
};
