import React from 'react';
import idx from 'idx';
import { getName } from 'country-list';
import { createSelector } from 'reselect';

import { NAME } from './constants';
import TableCell from '../app/pages/Statistics/components/CountryStatisticsTable/TableCell';

export const isDailyActiveUsersFetching = state =>
  idx(state, p => p[NAME].dailyActiveUsers.isFetching);

export const isDailyActiveUsersFetched = state =>
  idx(state, p => p[NAME].dailyActiveUsers.isFetched);

export const isDailyActiveUsersPerTierFetching = state =>
  idx(state, p => p[NAME].dailyActiveUsersPerTier.isFetching);

export const isDailyActiveUsersPerTierFetched = state =>
  idx(state, p => p[NAME].dailyActiveUsersPerTier.isFetched);

export const getDailyActiveUsersPerAppData = state =>
  idx(state, p => p[NAME].dailyActiveUsersPerApp.data);

export const isDailyActiveUsersPerAppFetching = state =>
  idx(state, p => p[NAME].dailyActiveUsersPerApp.isFetching);

export const isDailyActiveUsersPerAppFetched = state =>
  idx(state, p => p[NAME].dailyActiveUsersPerApp.isFetched);

export const isDailyActiveUsersPerCountryFetching = state =>
  idx(state, p => p[NAME].dailyActiveUsersPerCountry.isFetching);

export const isDailyActiveUsersPerCountryFetched = state =>
  idx(state, p => p[NAME].dailyActiveUsersPerCountry.isFetched);

export const getDailyEarningsData = state =>
  idx(state, p => p[NAME].dailyEarnings.data);

export const isDailyEarningsFetching = state =>
  idx(state, p => p[NAME].dailyEarnings.isFetching);

export const isDailyEarningsFetched = state =>
  idx(state, p => p[NAME].dailyEarnings.isFetched);

export const getDailyEarningsPerTierData = state =>
  idx(state, p => p[NAME].dailyEarningsPerTier.data);

export const isDailyEarningsPerTierFetching = state =>
  idx(state, p => p[NAME].dailyEarningsPerTier.isFetching);

export const isDailyEarningsPerTierFetched = state =>
  idx(state, p => p[NAME].dailyEarningsPerTier.isFetched);

export const getAllUsersData = state => idx(state, p => p[NAME].allUsers.data);

export const isAllUsersFetching = state =>
  idx(state, p => p[NAME].allUsers.isFetching);

export const isAllUsersFetched = state =>
  idx(state, p => p[NAME].allUsers.isFetched);

export const getFilters = state => idx(state, p => p[NAME].filters.data);

export const getDailyActiveUsersPerTierData = state =>
  idx(state, p => p[NAME].dailyActiveUsersPerTier.data);

export const getDailyActiveUsersData = state =>
  idx(state, p => p[NAME].dailyActiveUsers.data);

export const getDailyActiveUsersPerCountryData = state =>
  idx(state, p => p[NAME].dailyActiveUsersPerCountry.data);

export const getTierPricePerCountryData = state =>
  idx(state, p => p[NAME].tierPricePerCountry.data);

export const isTierPricePerCountryFetching = state =>
  idx(state, p => p[NAME].tierPricePerCountry.isFetching);

const formatData = (data, valueProperty, objectProperty) => {
  const dailyActiveUsersPerTierData = [];
  if (data.length) {
    data.forEach((obj, i) => {
      dailyActiveUsersPerTierData.push({ date: obj.date, values: [] });
      obj.vals.forEach(user => {
        user.vals.forEach(value => {
          const formatedTierIndex = dailyActiveUsersPerTierData[i].values
            .map(formatedObject => formatedObject[objectProperty])
            .indexOf(value[objectProperty]);

          if (
            formatedTierIndex !== -1 &&
            dailyActiveUsersPerTierData[i].values.length
          ) {
            dailyActiveUsersPerTierData[i].values[formatedTierIndex][
              valueProperty
            ] += value.vals[valueProperty];
          } else {
            dailyActiveUsersPerTierData[i].values.push({
              [objectProperty]: value[objectProperty],
              [valueProperty]: value.vals[valueProperty],
            });
          }
        });
      });
    });

    return dailyActiveUsersPerTierData;
  }

  return data;
};

export const getFormatedDailyEarningsPerTierData = createSelector(
  getDailyEarningsPerTierData,
  data => formatData(data, 'daily_earnings', 'tier_name')
);

export const getFormatedDailyActiveUsersPerTierData = createSelector(
  getDailyActiveUsersPerTierData,
  data => formatData(data, 'daily_active_users', 'tier_name')
);

export const getFormatedDailyActiveUsersPerAppData = createSelector(
  getDailyActiveUsersPerAppData,
  data => formatData(data, 'daily_active_users', 'app_name')
);

const getAverageValue = (data, dataKey) =>
  data
    .map(({ vals: value }) => {
      const { [dataKey]: dynamicValue } = value;
      return dynamicValue;
    })
    .reduce((total, current) => total + current);

export const getFormatedDailyActiveUsersPerCountryData = createSelector(
  getDailyActiveUsersPerCountryData,
  data => {
    if (data.length) {
      return data.map(({ country_code: code, country_name: name, vals }) => ({
        code,
        name,
        avgDailyActiveUsers: getAverageValue(
          vals,
          'avg_daily_active_users'
        ).toFixed(2),
        avgEarnings: getAverageValue(vals, 'avg_earnings').toFixed(2),
        percentage: (getAverageValue(vals, 'percentage') / vals.length).toFixed(
          2
        ),
        countryName: <TableCell countryCode={code} countryName={name} />,
      }));
    }
    return data;
  }
);

export const getDailyActiveUsersPerCountryMapData = createSelector(
  getDailyActiveUsersPerCountryData,
  data => {
    if (data.length) {
      // eslint-disable-next-line camelcase
      return data.reduce((formattedData, { country_code, vals }) => {
        const obj = formattedData;
        const value = vals
          .map(values => values.vals.avg_daily_active_users)
          .reduce((total, current) => total + current);
        obj[country_code] = {
          code: getName(country_code),
          name: getName(country_code),
          value,
          colorValue: value,
        };
        return obj;
      }, {});
    }
    return null;
  }
);

export const getFormatedDailyActiveUsersData = createSelector(
  getDailyActiveUsersData,
  data => {
    if (data.length) {
      return data.map(obj => ({
        date: obj.date,
        daily_active_users:
          obj.vals.length &&
          obj.vals
            .map(userValue => userValue.vals.daily_active_users)
            .reduce((total, current) => total + current),
      }));
    }

    return data;
  }
);

export const getFormatedDailyEarning = createSelector(
  getDailyEarningsData,
  data => {
    if (data.length) {
      return data.map(obj => ({
        date: obj.date,
        daily_earnings:
          obj.vals.length &&
          obj.vals
            .map(userValue => userValue.vals.daily_earnings)
            .reduce((total, current) => total + current)
            .toFixed(2),
      }));
    }

    return data;
  }
);

export const getDailyEarningsUsers = createSelector(
  getDailyEarningsData,
  data => {
    const users = [];
    data.forEach(item => {
      item.vals.forEach(vals => {
        if (!users.includes(vals.username)) {
          users.push(vals.username);
        }
      });
    });

    return users;
  }
);

export const getDailyEarningsChartLines = createSelector(
  getDailyEarningsUsers,
  users => {
    const lines = [];
    users.forEach(user => {
      lines.push({
        name: `${user} - Daily earnings (USD)`,
        type: 'monotone',
        dataKey: dataEntry =>
          idx(dataEntry, p =>
            p.vals
              .find(obj => obj.username === user)
              .vals.daily_earnings.toFixed(2)
          ) || 0,
        fillOpacity: 0.2,
      });
    });

    return lines;
  }
);

const getPriceAverage = country => {
  const priceArr = [];
  country.vals.map(tier =>
    tier.vals.map(item => priceArr.push(item.vals.price))
  );
  return priceArr.reduce((total, current) => total + current) / priceArr.length;
};

const getTiersData = country =>
  country.vals.map(tier => ({
    tier: tier.tier_name,
    platforms: tier.vals.map(item => ({
      name: item.platform_name,
      price: (item.vals.price * 10000).toFixed(2),
    })),
  }));

const getPayoutTotal = country => {
  let total = 0;
  country.vals.forEach(tier => {
    tier.vals.forEach(item => {
      total += parseFloat((item.vals.price * 10000).toFixed(2));
    });
  });
  return total;
};

export const getFormatedTierPricePerCountryData = createSelector(
  getTierPricePerCountryData,
  data =>
    data.reduce((formattedData, country) => {
      const arr = formattedData;
      arr.push({
        code: country.code,
        name: getName(country.code),
        value: getPriceAverage(country),
        tiers: getTiersData(country),
      });
      return arr;
    }, [])
);

export const getFormatedCountryData = createSelector(
  getTierPricePerCountryData,
  data =>
    data.reduce((formattedData, country) => {
      const obj = formattedData;
      obj[country.code] = {
        name: getName(country.code),
        value: getPriceAverage(country),
        tiers: getTiersData(country),
        payoutTotal: getPayoutTotal(country),
        colorValue: getPayoutTotal(country),
      };
      return obj;
    }, {})
);

export const getPayoutValues = data => {
  const payouts = [];
  // eslint-disable-next-line
  for (let country in data) {
    payouts.push(data[country].payoutTotal);
  }
  return payouts;
};

export const getDailyActiveUsersPerCountryValues = data => {
  const payouts = [];

  // eslint-disable-next-line
  for (let country in data) {
    payouts.push(parseInt(data[country].value.toFixed(2), 10));
  }
  return payouts;
};

export const getCountrySelectionOptions = data =>
  data.map(({ prices, name, tiers }) => ({
    prices,
    value: name,
    label: name,
    tiers,
  }));

export const getParsedTableData = selectedCountry => {
  if (!selectedCountry) {
    return [];
  }
  const tableData = [];

  selectedCountry.tiers.map(tier =>
    tier.platforms.map(platform =>
      tableData.push({
        country: selectedCountry.label,
        platform: platform.name,
        price: `${platform.price} $`,
        tier: tier.tier,
      })
    )
  );

  return tableData;
};
