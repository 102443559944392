import React, { PureComponent } from 'react';
import { Button } from 'reactstrap';
import PropTypes from 'prop-types';

class Action extends PureComponent {
  render() {
    return (
      <Button
        className={this.props.className}
        type={this.props.type}
        onClick={this.props.onClick}
        color={this.props.color}
      >
        {this.props.content}
      </Button>
    );
  }
}

Action.propTypes = {
  className: PropTypes.string,
  color: PropTypes.string,
  type: PropTypes.string,
  onClick: PropTypes.func,
  content: PropTypes.string.isRequired,
};

Action.defaultProps = {
  className: '',
  color: '',
  type: 'button',
  onClick: null,
  content: '',
};

export default Action;
