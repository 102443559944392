import React from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { branch, compose, renderComponent } from 'recompose';
import { Redirect } from 'react-router-dom';
import { connect } from 'react-redux';

import auth from '../../../auth';
import LoginWrapper from '../../components/LoginWrapper';
import LogInForm from './components/LogInForm';

const Login = props => {
  const onSubmit = values => {
    const { isLoginFetching, login } = props;
    const data = JSON.stringify(values);
    if (!isLoginFetching) {
      login(data);
    }
  };

  return (
    <LoginWrapper>
      <div className="account__card">
        <h3>Sign in</h3>
        <div className="mb-3 error-block">{props.loginError}</div>
        <LogInForm onSubmit={onSubmit} />
      </div>
    </LoginWrapper>
  );
};

Login.propTypes = {
  isLoginFetching: PropTypes.bool.isRequired,
  loginError: PropTypes.string,
  login: PropTypes.func.isRequired,
};

Login.defaultProps = {
  loginError: null,
};

const enhance = compose(
  connect(
    state => ({
      isAuthenticated: auth.selectors.isAuthenticated(state),
      isLoginFetching: auth.selectors.isLoginFetching(state),
      loginError: auth.selectors.getLoginError(state),
    }),
    dispatch => ({
      dispatch,
      login: bindActionCreators(auth.actions.login, dispatch),
    })
  ),
  branch(
    props => props.isAuthenticated,
    renderComponent(() => <Redirect to={'/dashboard'} />)
  )
);

export default enhance(Login);
