import React from 'react';
import { connect } from 'react-redux';

import CodeHighlight from '../../components/CodeHighlight';
import CODE_SNIPPETS from './codeSnippets';
import { isUnityInstructionsVisible } from '../../../ui/selectors';
import hideable from '../../utils/hideable';

const UnityInstructions = () => (
  <div>
    <h2 className="title">
      Installing Unity Globalhop SDK for Android platform
    </h2>
    <ol>
      <li className="point">
        Import <span className="inline-code">.unitypackage</span> file to your
        project.
      </li>
      <li className="point">
        Add <span className="inline-code">AnalysisSdk.cs</span> class to any
        game object which exists during the start of the application. This
        component will start the SDK. You can find it in{' '}
        <span className="inline-code">Assets/AnalysisSdk/AnalysisSdk.cs.</span>{' '}
        If your application has several scenes, it`s enough to add it only to
        the first one.
      </li>
      <li className="point">
        Change your company name in AnalysisSdk parameters. Your company name
        will be displayed in the consent dialog.
      </li>
      <li className="point">
        Implement OPT-OUT option. You should use{' '}
        <span className="inline-code">AnalysisSdk.Enable()</span>,{' '}
        <span className="inline-code">AnalysisSdk.Disable()</span>,{' '}
        <span className="inline-code">AnalysisSdk.IsEnabled()</span> static
        methods to create this functionality. Refer to the example below.
      </li>
      <CodeHighlight code={CODE_SNIPPETS.UNITY_OPT_OUT} language="csharp" />
    </ol>
  </div>
);

const mapStateToProps = state => ({
  isVisible: isUnityInstructionsVisible(state),
});

export default connect(mapStateToProps)(hideable(UnityInstructions));
