import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { ButtonToolbar, Modal } from 'reactstrap';

export default class ModalComponent extends PureComponent {
  render() {
    const {
      toggle,
      isOpen,
      title,
      message,
      footer,
      confirmBtn,
      cancelBtn,
      className,
    } = this.props;

    return (
      <div>
        <Modal
          isOpen={isOpen}
          toggle={toggle}
          className={`${className} theme-light`}
        >
          <div className="modal__header">
            <button
              className="lnr lnr-cross modal__close-btn"
              onClick={toggle}
            />
            <h4 className="bold-text  modal__title text-center">{title}</h4>
          </div>
          <div className="modal__body">{message}</div>
          {footer && (
            <ButtonToolbar className="modal__footer">
              {cancelBtn.visible && (
                <button
                  className={cancelBtn.className}
                  type={cancelBtn.type}
                  onClick={cancelBtn.onClick}
                >
                  {cancelBtn.text}
                </button>
              )}
              {confirmBtn.visible && (
                <button
                  className={confirmBtn.className}
                  type={confirmBtn.type}
                  onClick={confirmBtn.onClick}
                >
                  {confirmBtn.text}
                </button>
              )}
            </ButtonToolbar>
          )}
        </Modal>
      </div>
    );
  }
}

ModalComponent.propTypes = {
  toggle: PropTypes.func.isRequired,
  isOpen: PropTypes.bool,
  title: PropTypes.string,
  message: PropTypes.oneOfType([PropTypes.string, PropTypes.node]).isRequired,
  footer: PropTypes.bool,
  confirmBtn: PropTypes.shape({
    type: PropTypes.string,
    text: PropTypes.string,
    onClick: PropTypes.func,
    className: PropTypes.string,
    visible: PropTypes.bool,
  }),
  cancelBtn: PropTypes.shape({
    type: PropTypes.string,
    text: PropTypes.string,
    onClick: PropTypes.func,
    className: PropTypes.string,
    visible: PropTypes.bool,
  }),
  className: PropTypes.string,
};

ModalComponent.defaultProps = {
  isOpen: false,
  title: '',
  footer: true,
  confirmBtn: {
    type: 'button',
    text: 'Confirm',
    onClick: () => {},
    className: '',
    visible: true,
  },
  cancelBtn: {
    type: 'button',
    text: 'Cancel',
    onClick: () => {},
    className: '',
    visible: true,
  },
  className: '',
};
