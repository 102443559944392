import { combineReducers } from 'redux';
import { routerReducer } from 'react-router-redux';
import { reducer as reduxFormReducer } from 'redux-form';

import apps from '../../apps';
import billing from '../../billing';
import auth from '../../auth';
import countries from '../../countries';
import stats from '../../stats';
import users from '../../users';
import ui from '../../ui';
import content from '../../content';

const appReducer = combineReducers({
  [apps.constants.NAME]: apps.reducer,
  [billing.constants.NAME]: billing.reducer,
  [auth.constants.NAME]: auth.reducer,
  [countries.constants.NAME]: countries.reducer,
  [stats.constants.NAME]: stats.reducer,
  [users.constants.NAME]: users.reducer,
  [ui.constants.NAME]: ui.reducer,
  [content.constants.NAME]: content.reducer,
  routing: routerReducer,
  form: reduxFormReducer,
});

const rootReducer = (state, action) => {
  let newState = state;
  if (
    action.type === auth.types.LOGOUT ||
    action.type === auth.types.VERIFY_TOKEN_FAILURE
  ) {
    newState = undefined;
  }

  return appReducer(newState, action);
};

export default rootReducer;
