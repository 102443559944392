const ANDROID_DEPENDENCIES = `dependencies {
  ...
  implementation files('libs/com.your.sdk.aar') // CHANGE THIS TO YOUR OWN FILE NAME!
  implementation 'com.android.support:support-fragment:28.0.0'
  implementation 'com.koushikdutta.async:androidasync:2.2.1'
  implementation 'com.squareup.okhttp3:okhttp:3.9.1'
  implementation 'org.msgpack:msgpack-core:0.8.16'
}
`;

const ANDROID_ONCREATE = `protected void onCreate(Bundle savedInstanceState) {
  super.onCreate(savedInstanceState)
  ServiceSetup.requestStart(this, "Your company name", getSupportFragmentManager(), null);
  ...
}
`;

const ANDROID_CONSENT_LISTENER = `protected void onCreate(Bundle savedInstanceState) {
  super.onCreate(savedInstanceState)
  ServiceSetup.requestStart(context, "Your company name", getSupportFragmentManager(), new ConsentListener() {

      // This method is called when user presses "agree" or "disagree" button
      @Override
      public void onAction(boolean agreed) {
          // variable agreed is true when user gives consent
      }
  });
}
`;

const ANDROID_ACTIVITY = `protected void onCreate(Bundle savedInstanceState) {
  super.onCreate(savedInstanceState)
  ServiceSetup.requestStart(this, "Your company name", getFragmentManager(), null);
  ...
}
`;

const ANDROID_CONSENT_DIALOG = `// Shows user consent dialog

ServiceSetup.enable(context, getSupportFragmentManager(), new ConsentListener() {
    @Override
    public void onAction(boolean agreed) {
        // Called when user agrees/disagrees with consent
        // State of your opt-out settings item should be updated here
        // For example: check/uncheck checkbox, toggle on/off toggle button
    }
});
`;

const ANDROID_EXAMPLE = `// ...

toggleButton.setChecked(ServiceSetup.isEnabled());
toggleButton.setOnCheckedChangeListener(new CompoundButton.OnCheckedChangeListener() {

    @Override
    public void onCheckedChanged(CompoundButton buttonView, boolean isChecked) {
        // enable or disable SDK
        if (isChecked) {
            ServiceSetup.enable(buttonView.getContext(), getSupportFragmentManager(), new ConsentListener() {
                @Override
                public void onAction(boolean agreed) {
                    // If user clicks "disagree" button toggle button's state should be set to "off"
                    if (!agreed) {
                        toggleButton.setChecked(false);
                    }
                }
            });
        } else {
            ServiceSetup.disable(buttonView.getContext());
        }
    }
});

// ...
`;

const UNITY_OPT_OUT = `using UnityEngine;
using UnityEngine.UI;

public class ToggleEvents : MonoBehaviour
{
    void OnEnable()
    {
        GetComponent<Toggle>().isOn = AnalysisSdk.IsEnabled();
    }

    public void CheckChanged()
    {
        var toggle = GetComponent<Toggle>();
        if (toggle.isOn)
        {
            AnalysisSdk.Enable();
        }
        else
        {
            AnalysisSdk.Disable();
        }
    }
}
`;

export default {
  ANDROID_DEPENDENCIES,
  ANDROID_ONCREATE,
  ANDROID_CONSENT_LISTENER,
  ANDROID_ACTIVITY,
  ANDROID_CONSENT_DIALOG,
  ANDROID_EXAMPLE,
  UNITY_OPT_OUT,
};
