import { connect } from 'react-redux';

import stats from '../../../../stats';
import Map from '../../../components/Map/Map';

const generateTooltipHtml = countryData => {
  if (countryData === null) {
    return null;
  }

  let html = `<p class="map-popup__title">${countryData.name}</p>`;
  if (countryData.tiers) {
    countryData.tiers.forEach(tier => {
      tier.platforms.forEach(platform => {
        html += `
        <p class="map-popup__text">
          ${platform.name} payout: ${platform.price} $
        </p>
      `;
      });
    });
  }
  return html;
};

const mapStateToProps = state => {
  const data = stats.selectors.getFormatedCountryData(state);
  return {
    dataIsFetching: stats.selectors.isTierPricePerCountryFetching(state),
    colorValues: stats.selectors.getPayoutValues(data),
    data,
    createTooltip: generateTooltipHtml,
  };
};

export default connect(mapStateToProps)(Map);
