import { NAME } from './constants';

export const FETCH_TOS_REQUEST = `${NAME}.FETCH_TOS_REQUEST`;
export const FETCH_TOS_SUCCESS = `${NAME}.FETCH_TOS_SUCCESS`;
export const FETCH_TOS_FAILURE = `${NAME}.FETCH_TOS_FAILURE`;

export const CREATE_TOS_AGREEMENT_REQUEST = `${NAME}.CREATE_TOS_AGREEMENT_REQUEST`;
export const CREATE_TOS_AGREEMENT_SUCCESS = `${NAME}.CREATE_TOS_AGREEMENT_SUCCESS`;
export const CREATE_TOS_AGREEMENT_FAILURE = `${NAME}.CREATE_TOS_AGREEMENT_FAILURE`;

export const UPDATE_LEGAL_INFO_REQUEST = `${NAME}.UPDATE_LEGAL_INFO_REQUEST`;
export const UPDATE_LEGAL_INFO_SUCCESS = `${NAME}.UPDATE_LEGAL_INFO_SUCCESS`;
export const UPDATE_LEGAL_INFO_FAILURE = `${NAME}.UPDATE_LEGAL_INFO_FAILURE`;

export const FETCH_LEGAL_INFO_REQUEST = `${NAME}.FETCH_LEGAL_INFO_REQUEST`;
export const FETCH_LEGAL_INFO_SUCCESS = `${NAME}.FETCH_LEGAL_INFO_SUCCESS`;
export const FETCH_LEGAL_INFO_FAILURE = `${NAME}.FETCH_LEGAL_INFO_FAILURE`;

export const FETCH_PAYMENT_INFO_REQUEST = `${NAME}.FETCH_PAYMENT_INFO_REQUEST`;
export const FETCH_PAYMENT_INFO_SUCCESS = `${NAME}.FETCH_PAYMENT_INFO_SUCCESS`;
export const FETCH_PAYMENT_INFO_FAILURE = `${NAME}.FETCH_PAYMENT_INFO_FAILURE`;

export const UPDATE_PAYMENT_INFO_REQUEST = `${NAME}.UPDATE_PAYMENT_INFO_REQUEST`;
export const UPDATE_PAYMENT_INFO_SUCCESS = `${NAME}.UPDATE_PAYMENT_INFO_SUCCESS`;
export const UPDATE_PAYMENT_INFO_FAILURE = `${NAME}.UPDATE_PAYMENT_INFO_FAILURE`;

export const UPDATE_USER_REQUEST = `${NAME}.UPDATE_USER_REQUEST`;
export const UPDATE_USER_SUCCESS = `${NAME}.UPDATE_USER_SUCCESS`;
export const UPDATE_USER_FAILURE = `${NAME}.UPDATE_USER_FAILURE`;

export const UPDATE_TOS = `${NAME}.UPDATE_TOS`;
