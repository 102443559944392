import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { Col, Row } from 'reactstrap';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { compose } from 'recompose';

import billing from '../../../../billing';

import CurrentBalanceCardContainer from '../../../components/CurrentBalanceCardContainer';
import PendingBalanceCardContainer from '../../../components/PendingBalanceCardContainer';
import TotalEarnedCardContainer from '../../../components/TotalEarnedCardContainer';

class AccountBalance extends PureComponent {
  componentDidMount() {
    const { isPayoutsFetching, fetchPayouts } = this.props;
    if (!isPayoutsFetching) {
      fetchPayouts();
    }
  }

  render() {
    return (
      <Row>
        <Col md={12} lg={4} xl={4}>
          <CurrentBalanceCardContainer />
        </Col>
        <Col md={12} lg={4} xl={4}>
          <PendingBalanceCardContainer />
        </Col>
        <Col md={12} lg={4} xl={4}>
          <TotalEarnedCardContainer />
        </Col>
      </Row>
    );
  }
}

AccountBalance.propTypes = {
  isPayoutsFetching: PropTypes.bool.isRequired,
  fetchPayouts: PropTypes.func.isRequired,
};

const enhance = compose(
  connect(
    state => ({
      isPayoutsFetching: billing.selectors.isPayoutsFetching(state),
    }),
    dispatch => ({
      dispatch,
      fetchPayouts: bindActionCreators(billing.actions.fetchPayouts, dispatch),
    })
  )
);

export default enhance(AccountBalance);
