import Cookies from 'js-cookie';

import { parseQueryParamsFromURL } from './urlHelpers';

const getCouponFromCookie = () => Cookies.get('globalhop_coupon');

const getCouponFromQueryParam = () =>
  parseQueryParamsFromURL(['coupon']).coupon;

export const getCoupon = () =>
  getCouponFromQueryParam() || getCouponFromCookie();
