import React from 'react';
import { Col } from 'reactstrap';

import Tabs from '../shared/UserProfile/Tabs';
import PaymentInfoForm from '../shared/UserProfile/PaymentInfoForm';
import LegalInfoForm from '../shared/UserProfile/LegalInfoForm';
import PasswordForm from './components/PasswordForm';

const Profile = () => (
  <div className="dashboard container">
    <div className="row">
      <Col xl={12}>
        <h3 className="page-title">Profile</h3>
      </Col>
      <Tabs
        tabs={[
          {
            index: '1',
            name: 'settings',
            tabText: 'settings',
            component: LegalInfoForm,
          },
          {
            index: '2',
            name: 'payments',
            tabText: 'payments',
            component: PaymentInfoForm,
          },
          {
            index: '3',
            name: 'password',
            tabText: 'password change',
            component: PasswordForm,
          },
        ]}
      />
    </div>
  </div>
);

export default Profile;
