import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import {
  AreaChart,
  Area,
  BarChart,
  Bar,
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
  Brush,
} from 'recharts';

import Panel from '../Panel';

class Chart extends PureComponent {
  getChartComponents() {
    let chartComponents;
    if (this.props.type === 'area') {
      chartComponents = {
        ChartComponent: AreaChart,
        ChartYAxisComponent: Area,
      };
    } else if (this.props.type === 'bar') {
      chartComponents = {
        ChartComponent: BarChart,
        ChartYAxisComponent: Bar,
      };
    } else if (this.props.type === 'line') {
      chartComponents = {
        ChartComponent: LineChart,
        ChartYAxisComponent: Line,
      };
    }
    return chartComponents;
  }

  renderYAxis(ChartYAxisComponent) {
    return this.props.lines.map(
      (
        {
          name,
          type,
          dataKey,
          fill = '#572ffd',
          stroke = '#572ffd',
          fillOpacity,
          stackId,
        },
        i
      ) => (
        <ChartYAxisComponent
          key={i}
          name={name}
          type={type}
          dataKey={dataKey}
          fill={fill}
          stroke={stroke}
          fillOpacity={fillOpacity}
          stackId={stackId}
        />
      )
    );
  }

  render() {
    const {
      data,
      XAxisDataKey,
      title,
      isFetching,
      hasBrush,
      brushOptions,
      legendOptions,
    } = this.props;
    const chartComponents = this.getChartComponents();
    const { ChartComponent, ChartYAxisComponent } = chartComponents;
    return (
      <Panel title={title} loading={isFetching}>
        <ResponsiveContainer height={400} className="dashboard__area">
          <ChartComponent data={data} margin={{ top: 20, left: 0, bottom: 20 }}>
            <XAxis dataKey={XAxisDataKey} tickLine={false} />
            <YAxis
              tickLine={false}
              tickFormatter={num => num.toLocaleString()}
            />
            <Tooltip formatter={value => value.toLocaleString()} />
            <Legend {...legendOptions} />
            <CartesianGrid />
            {this.renderYAxis(ChartYAxisComponent)}
            {hasBrush && <Brush {...brushOptions} />}
          </ChartComponent>
        </ResponsiveContainer>
      </Panel>
    );
  }
}

Chart.defaultProps = {
  hasBrush: false,
  brushOptions: {},
  legendOptions: {},
};

Chart.propTypes = {
  type: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  data: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  XAxisDataKey: PropTypes.oneOfType([PropTypes.string, PropTypes.func])
    .isRequired,
  lines: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  isFetching: PropTypes.bool.isRequired,
  hasBrush: PropTypes.bool,
  brushOptions: PropTypes.shape(),
  legendOptions: PropTypes.shape(),
};

export default Chart;
