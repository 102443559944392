import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import TableHead from '@material-ui/core/TableHead';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import TableSortLabel from '@material-ui/core/TableSortLabel';

export default class MatTableHead extends PureComponent {
  createSortHandler = property => event => {
    this.props.onRequestSort(event, property);
  };

  render() {
    const { order, orderBy, cols } = this.props;

    return (
      <TableHead>
        <TableRow>
          {cols().map(
            col => (
              <TableCell
                className="material-table__cell material-table__cell--sort"
                key={col.dataKey}
                numeric={col.numeric}
                padding={'none'}
                sortDirection={orderBy === col.dataKey ? order : false}
              >
                <TableSortLabel
                  active={orderBy === col.dataKey}
                  direction={order}
                  onClick={this.createSortHandler(col.dataKey)}
                  className="material-table__sort-label"
                >
                  {col.label}
                </TableSortLabel>
              </TableCell>
            ),
            this
          )}
        </TableRow>
      </TableHead>
    );
  }
}

MatTableHead.propTypes = {
  cols: PropTypes.func.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  order: PropTypes.string.isRequired,
  orderBy: PropTypes.string.isRequired,
};
