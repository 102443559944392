import React from 'react';
import { Route, Switch } from 'react-router-dom';
import Layout from './components/Layout';
import Footer from './components/Footer';

import requireAuth from './utils/authHelpers';
import Login from './pages/Login';
import { Register, CompleteRegistration } from './pages/Register';
import Dashboard from './pages/Dashboard';
import SDK from './pages/SDK';
import Statistics from './pages/Statistics';
import Profile from './pages/Profile';
import Billing from './pages/Billing';
import Tos from './pages/Tos';
import Faq from './pages/Faq';
import Pricing from './pages/Pricing';
import Instructions from './pages/SdkInstructions';

const DashboardPages = () => (
  <div>
    <Layout />
    <div className="container__wrap">
      <Route path="/dashboard" component={Dashboard} />
      <Route path="/profile" component={Profile} />
      <Route path="/sdk" component={SDK} />
      <Route path="/statistics" component={Statistics} />
      <Route path="/billing" component={Billing} />
      <Route path="/tos" component={Tos} />
      <Route path="/faq" component={Faq} />
      <Route path="/pricing" component={Pricing} />
      <Route path="/instructions" component={Instructions} />
    </div>
    <Footer />
  </div>
);

export default (
  <Switch>
    <Route exact path="/" component={Login} />
    <Route exact path="/register" component={Register} />
    <Route
      exact
      path="/email-confirmation/:token"
      component={CompleteRegistration}
    />
    <Route path="/" component={requireAuth(DashboardPages)} />
  </Switch>
);
