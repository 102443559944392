import React from 'react';
import PropTypes from 'prop-types';
import { Table } from 'reactstrap';

const BasicTable = props => {
  const { colNames, dataKeys, data } = props;

  const renderTableHead = () => (
    <thead>
      <tr>
        {colNames.map((colName, i) => (
          <th key={i}>{colName}</th>
        ))}
      </tr>
    </thead>
  );

  const renderTableData = () =>
    data.map((item, i) => (
      <tr key={i}>
        {dataKeys.map((dataKey, j) => (
          <td key={j}>
            {typeof dataKey === 'function' ? dataKey(item) : item[dataKey]}
          </td>
        ))}
      </tr>
    ));

  return (
    <Table responsive className="table--bordered">
      {renderTableHead()}
      <tbody>{renderTableData()}</tbody>
    </Table>
  );
};

BasicTable.propTypes = {
  colNames: PropTypes.arrayOf(PropTypes.string),
  dataKeys: PropTypes.arrayOf(
    PropTypes.oneOfType([PropTypes.string, PropTypes.func])
  ),
  data: PropTypes.arrayOf(PropTypes.shape()),
};

BasicTable.defaultProps = {
  colNames: [],
  dataKeys: [],
  data: [],
};

export default BasicTable;
