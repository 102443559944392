import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import auth from '../../../../auth';
import mailbox from '../../../img/login/mailbox.svg';
import hideable from '../../../utils/hideable';

const CheckYourEmail = ({ email, onClick, isButtonDisabled }) => (
  <div className="confirmation-info">
    <img src={mailbox} alt="" />
    <h3>Check your email</h3>
    <p>
      We`ve sent and email with confirmation link to{' '}
      <span className="email">{email}</span>, it will expire after
      <span className="marked"> 24 hours</span>
    </p>
    <button
      className="btn btn-primary account__btn"
      onClick={onClick}
      disabled={isButtonDisabled}
    >
      Resend confirmation Link
    </button>
  </div>
);

CheckYourEmail.propTypes = {
  email: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired,
  isButtonDisabled: PropTypes.bool.isRequired,
};

const mapStateToProps = state => ({
  isVisible: auth.selectors.isCheckYourEmailScreenVisible(state),
  email: auth.selectors.getConfirmationEmail(state),
  isButtonDisabled: auth.selectors.isResendClicked(state),
});

const mapDispatchToProps = {
  onClick: auth.actions.resendConfirmation,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(hideable(CheckYourEmail));
