import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import apps from '../../../apps';
import ui from '../../../ui';
import hideable from '../../utils/hideable';
import first from '../../img/login/1.svg';
import second from '../../img/login/2.svg';
import third from '../../img/login/3.svg';
import { SDK_MODAL } from './components/modalNames';

const OnboardingSdk = ({ onClick }) => (
  <div className="onboarding-container">
    <div className="onboarding">
      <h1 className="title">Let`s begin</h1>
      <p className="description">
        Integrate Glogalhop SDK by following these steps:
      </p>
      <div className="instructions">
        <div className="step">
          <img src={first} alt="" />
          <p>Request an SDK</p>
        </div>
        <div className="step">
          <img src={second} alt="" />
          <p>Receive email confirmation within 24 hours</p>
        </div>
        <div className="step">
          <img src={third} alt="" />
          <p>Integrate it to your app and start earning</p>
        </div>
      </div>
      <button className="btn btn-primary" onClick={onClick}>
        Request an SDK
      </button>
    </div>
  </div>
);

OnboardingSdk.propTypes = {
  onClick: PropTypes.func.isRequired,
};

const mapStateToProps = state => ({
  isVisible:
    !apps.selectors.userHasApps(state) && !apps.selectors.appsFetching(state),
});

const mapDispatchToProps = dispatch => ({
  onClick: () => dispatch(ui.actions.toggleModal(SDK_MODAL)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(hideable(OnboardingSdk));
