import { NotificationManager } from 'react-notifications';

import state from '../app/state';
import { tokenVerificationFailure } from '../auth/actions';

export const handleErrors = error => {
  if (error.status === 401) {
    state.store.dispatch(tokenVerificationFailure());
  }
  let errorMessage;
  if (error.response) {
    errorMessage =
      error.response.error_message || error.response.errors[0].error_msg;
  }
  NotificationManager.error(
    errorMessage || 'Unexpected error has occurred, please retry your action.'
  );
};

export const requestHeaders = tokenRequired => {
  const headers = {
    Accept: 'application/json',
    'Content-Type': 'application/json',
  };
  if (tokenRequired) {
    headers.Authorization = `JWT ${sessionStorage.getItem('token')}`;
  }
  return headers;
};

export const buildUrlWithQueryParams = (url, data) => {
  let params = '';
  Object.keys(data).forEach((key, i) => {
    if (i === 0 && data[key]) {
      params += `?${key}=${data[key]}`;
    } else if (data[key] && Array.isArray(data[key])) {
      data[key].forEach(keyValue => {
        if (i === 0) {
          params += `?${key}=${keyValue}`;
        } else {
          params += `&${key}=${keyValue}`;
        }
      });
    } else if (data[key]) {
      params += `&${key}=${data[key]}`;
    }
  });

  return `${url}${params}`;
};
