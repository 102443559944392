import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

const TopbarMenuLinks = props => {
  const { title, icon, path, onClick } = props;
  return (
    <div role="button" onClick={onClick} tabIndex={0}>
      <Link className="topbar__link" to={path}>
        <span className={`topbar__link-icon lnr lnr-${icon}`} />
        <p className="topbar__link-title">{title}</p>
      </Link>
    </div>
  );
};

TopbarMenuLinks.propTypes = {
  title: PropTypes.string.isRequired,
  icon: PropTypes.string.isRequired,
  path: PropTypes.string.isRequired,
  onClick: PropTypes.func,
};

TopbarMenuLinks.defaultProps = {
  onClick: null,
};

export default TopbarMenuLinks;
