import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

import stats from '../../../stats';
import Chart from './Chart';

class DailyActiveUsersChart extends PureComponent {
  componentDidMount() {
    const { isFetching, fetchData, filters, overrideFilters } = this.props;
    if (!isFetching) {
      fetchData(overrideFilters || filters);
    }
  }

  componentDidUpdate(prevProps) {
    const { fetchData, filters, overrideFilters } = this.props;
    if (overrideFilters === null && filters !== prevProps.filters) {
      fetchData(filters);
    }
  }

  render() {
    return (
      <Chart
        type={'area'}
        title={'Daily Active Users'}
        data={this.props.data}
        XAxisDataKey={'date'}
        lines={[
          {
            name: 'Daily Active Users',
            type: 'monotone',
            dataKey: 'daily_active_users',
          },
        ]}
        isFetching={this.props.isFetching}
      />
    );
  }
}

DailyActiveUsersChart.propTypes = {
  overrideFilters: PropTypes.shape(),

  data: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  isFetching: PropTypes.bool.isRequired,
  filters: PropTypes.shape().isRequired,
  fetchData: PropTypes.func.isRequired,
};

DailyActiveUsersChart.defaultProps = {
  overrideFilters: null,
};

const enhance = connect(
  state => ({
    data: stats.selectors.getFormatedDailyActiveUsersData(state),
    isFetching: stats.selectors.isDailyActiveUsersFetching(state),
    filters: stats.selectors.getFilters(state),
  }),
  dispatch => ({
    dispatch,
    fetchData: bindActionCreators(
      stats.actions.fetchDailyActiveUsers,
      dispatch
    ),
  })
);

export default enhance(DailyActiveUsersChart);
