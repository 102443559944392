import React, { PureComponent } from 'react';
import { Card, CardBody } from 'reactstrap';
import { Field, reduxForm } from 'redux-form';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { compose } from 'recompose';
import moment from 'moment';
import idx from 'idx';

import apps from '../../../../apps';
import auth from '../../../../auth';
import countries from '../../../../countries';
import stats from '../../../../stats';
import renderIntervalDatePickerField from '../../../components/Form/IntervalDatePicker';
import renderSelectField from '../../../components/Form/Select';
import MultiSelect from '../../../components/Form/MultiSelect';
import { dateRangeRequired } from '../../../utils/validators';
import AdminMultiSelect from './AdminMultiSelect';

class FiltersForm extends PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      appsMultiSelectValue: [],
      usersMultiSelectValue: [],
    };
  }
  componentDidMount() {
    const {
      appsFetching,
      fetchApps,
      countriesFetching,
      fetchCountries,
      isAllUsersFetching,
      fetchAllUsers,
      isSuperUser,
    } = this.props;
    if (!appsFetching) {
      fetchApps();
    }
    if (!countriesFetching) {
      fetchCountries();
    }
    if (!isAllUsersFetching && isSuperUser) {
      fetchAllUsers();
    }
  }

  componentDidUpdate(prevProps) {
    const { isSuperUser, fetchAllUsers } = this.props;
    if (prevProps.isSuperUser !== isSuperUser && isSuperUser) {
      fetchAllUsers();
    }
  }

  multiOptions(data, label, value) {
    const options = [{ value: 'selectAll', label: 'Select All' }];
    data.forEach(item => {
      options.push({
        value: item[value],
        label: item[label],
      });
    });

    return options;
  }

  multiSelectData(fieldName) {
    let data = [];
    if (fieldName === 'appsMultiSelectValue') {
      data = this.multiOptions(this.props.apps, 'name', 'uuid');
    } else if (fieldName === 'usersMultiSelectValue') {
      data = this.multiOptions(this.props.allUsers, 'username', 'uuid');
    }
    return data;
  }

  handleMultiSelect(fieldName, value) {
    if (value.find(obj => obj.value === 'selectAll')) {
      const data = this.multiSelectData(fieldName);
      const formatedData = data.splice(1, data.length - 1);
      this.setState({ [fieldName]: formatedData });
    } else {
      this.setState({ [fieldName]: value });
    }
  }

  selectOptions(data, label, value) {
    const options = [{ label: '-', value: null }];
    data.forEach(item => {
      options.push({
        label: item[label],
        value: item[value],
      });
    });
    return options;
  }

  updateFilters(values) {
    const { appsMultiSelectValue, usersMultiSelectValue } = this.state;
    const filters = {
      date_from: values.date_range.start.format('YYYY-MM-DD'),
      date_to: values.date_range.end.format('YYYY-MM-DD'),
      app_uuid: appsMultiSelectValue.map(selection => selection.value),
      country_code: idx(values, p => p.country_select.value),
      user_uuid: usersMultiSelectValue.map(selection => selection.value),
    };
    this.props.updateFilters(filters);
  }

  render() {
    const { handleSubmit, isSuperUser } = this.props;
    const { appsMultiSelectValue, usersMultiSelectValue } = this.state;
    return (
      <Card>
        <CardBody>
          <div className="card__title">
            <h5 className="bold-text">Filters</h5>
          </div>
          <form
            className="form"
            onSubmit={handleSubmit(values => this.updateFilters(values))}
          >
            <div className="form__form-group">
              <span className="form__form-group-label">Date Range</span>
              <div className="form__form-group-field">
                <Field
                  name="date_range"
                  maxDate={moment().subtract(1, 'days')}
                  component={renderIntervalDatePickerField}
                  validate={[dateRangeRequired]}
                />
              </div>
            </div>
            <div className="form__form-group">
              <span className="form__form-group-label">App</span>
              <div className="form__form-group-field">
                <MultiSelect
                  name="user_select"
                  onChange={value =>
                    this.handleMultiSelect('appsMultiSelectValue', value)
                  }
                  options={this.multiOptions(this.props.apps, 'name', 'uuid')}
                  value={appsMultiSelectValue}
                />
              </div>
            </div>
            <div className="form__form-group">
              <span className="form__form-group-label">Country</span>
              <div className="form__form-group-field">
                <Field
                  name="country_select"
                  component={renderSelectField}
                  options={this.selectOptions(
                    this.props.countries,
                    'name',
                    'code'
                  )}
                />
              </div>
            </div>
            <AdminMultiSelect
              isSuperUser={isSuperUser}
              onChange={value =>
                this.handleMultiSelect('usersMultiSelectValue', value)
              }
              value={usersMultiSelectValue}
              options={this.multiOptions(
                this.props.allUsers,
                'username',
                'uuid'
              )}
            />
            <button type="submit" className="btn btn-primary">
              Refresh data
            </button>
          </form>
        </CardBody>
      </Card>
    );
  }
}

FiltersForm.defaultProps = {
  isSuperUser: null,
};
FiltersForm.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  allUsers: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  appsFetching: PropTypes.bool.isRequired,
  isAllUsersFetching: PropTypes.bool.isRequired,
  apps: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  fetchApps: PropTypes.func.isRequired,
  countriesFetching: PropTypes.bool.isRequired,
  countries: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  fetchCountries: PropTypes.func.isRequired,
  fetchAllUsers: PropTypes.func.isRequired,
  updateFilters: PropTypes.func.isRequired,
  isSuperUser: PropTypes.bool,
};

const enhance = compose(
  connect(
    state => ({
      appsFetching: apps.selectors.appsFetching(state),
      apps: apps.selectors.getActiveAndPendingApps(state),
      countriesFetching: countries.selectors.countriesFetching(state),
      countries: countries.selectors.getCountries(state),
      allUsers: stats.selectors.getAllUsersData(state),
      isAllUsersFetching: stats.selectors.isAllUsersFetching(state),
      isSuperUser: auth.selectors.isSuperUser(state),
    }),
    dispatch => ({
      fetchApps: () => dispatch(apps.actions.fetchApps()),
      fetchCountries: () => dispatch(countries.actions.fetchCountries()),
      updateFilters: data => dispatch(stats.actions.onFilterFormSubmit(data)),
      fetchAllUsers: () => dispatch(stats.actions.fetchAllUsers()),
    })
  ),
  reduxForm({
    form: 'filters_form',
    initialValues: {
      date_range: {
        start: moment().subtract(30, 'days'),
        end: moment().subtract(1, 'days'),
      },
    },
  })
);

export default enhance(FiltersForm);
