import React from 'react';
import Highlight from 'react-syntax-highlighter';
import PropTypes from 'prop-types';
import Clipboard from 'react-clipboard.js';
import ContentCopyIcon from 'mdi-react/ContentCopyIcon';
import { atomOneDark } from 'react-syntax-highlighter/dist/esm/styles/hljs';

const highlightStyle = {
  position: 'relative',
};

const CodeHighlight = ({ language, code }) => (
  <div className="code-highlight">
    <Highlight
      showLineNumbers
      customStyle={highlightStyle}
      style={atomOneDark}
      language={language}
    >
      {code}
    </Highlight>
    <Clipboard data-clipboard-text={code} className="clipboard-icon">
      <ContentCopyIcon />
    </Clipboard>
  </div>
);

CodeHighlight.propTypes = {
  language: PropTypes.string.isRequired,
  code: PropTypes.string.isRequired,
};

export default CodeHighlight;
