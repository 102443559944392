import * as types from './actionTypes';
import { callAPI } from '../api';
import { requestHeaders } from '../api/utils';
import { downloadSdk } from '../apps/actions';
import { toggleModal } from '../ui/actions';
import { getSelectedAppURL } from '../apps/selectors';
import { tosAgreed } from './selectors';

export const fetchLegalInfo = () =>
  callAPI({
    method: 'GET',
    endpoint: `/api/v1/users/${sessionStorage.getItem('uuid')}/legal-info/`,
    headers: requestHeaders(true),
    types: [
      types.FETCH_LEGAL_INFO_REQUEST,
      types.FETCH_LEGAL_INFO_SUCCESS,
      types.FETCH_LEGAL_INFO_FAILURE,
    ],
  });

export const fetchPaymentInfo = () =>
  callAPI({
    method: 'GET',
    endpoint: `/api/v1/users/${sessionStorage.getItem('uuid')}/payment-info/`,
    headers: requestHeaders(true),
    types: [
      types.FETCH_PAYMENT_INFO_REQUEST,
      types.FETCH_PAYMENT_INFO_SUCCESS,
      types.FETCH_PAYMENT_INFO_FAILURE,
    ],
  });

export const fetchTos = () =>
  callAPI({
    method: 'GET',
    endpoint: `/api/v1/users/${sessionStorage.getItem('uuid')}/tos/`,
    headers: requestHeaders(true),
    types: [
      types.FETCH_TOS_REQUEST,
      types.FETCH_TOS_SUCCESS,
      types.FETCH_TOS_FAILURE,
    ],
  });

export const updateTos = tos => ({
  type: types.UPDATE_TOS,
  payload: tos,
});

export const createTosAgreement = data => dispatch =>
  dispatch(
    callAPI({
      method: 'POST',
      endpoint: `/api/v1/users/${sessionStorage.getItem(
        'uuid'
      )}/tos-agreement/`,
      headers: requestHeaders(true),
      types: [
        types.CREATE_TOS_AGREEMENT_REQUEST,
        {
          type: types.CREATE_TOS_AGREEMENT_SUCCESS,
          payload: (action, state, res) => {
            dispatch(updateTos({ data: { user_agreed: true } }));
            dispatch(toggleModal('TosModal'));
            dispatch(downloadSdk(getSelectedAppURL(state)));
            return res;
          },
        },
        types.CREATE_TOS_AGREEMENT_FAILURE,
      ],
      body: data,
    })
  );

export const updateLegalInfo = (
  data,
  downloadSDKOnSuccess = false
) => dispatch =>
  dispatch(
    callAPI({
      method: 'PATCH',
      endpoint: `/api/v1/users/${sessionStorage.getItem('uuid')}/legal-info/`,
      headers: requestHeaders(true),
      types: [
        types.UPDATE_LEGAL_INFO_REQUEST,
        {
          type: types.UPDATE_LEGAL_INFO_SUCCESS,
          payload: (action, state, res) => {
            if (downloadSDKOnSuccess) {
              if (tosAgreed(state)) {
                dispatch(downloadSdk(getSelectedAppURL(state)));
              } else {
                dispatch(toggleModal('TosModal'));
              }
            }
            return res;
          },
        },
        types.UPDATE_LEGAL_INFO_FAILURE,
      ],
      body: data,
    })
  );

export const updatePaymentInfo = data =>
  callAPI({
    method: 'PATCH',
    endpoint: `/api/v1/users/${sessionStorage.getItem('uuid')}/payment-info/`,
    headers: requestHeaders(true),
    types: [
      types.UPDATE_PAYMENT_INFO_REQUEST,
      types.UPDATE_PAYMENT_INFO_SUCCESS,
      types.UPDATE_PAYMENT_INFO_FAILURE,
    ],
    body: data,
  });

export const updateUser = data =>
  callAPI({
    method: 'PATCH',
    endpoint: `/api/v1/users/${sessionStorage.getItem('uuid')}/`,
    headers: requestHeaders(true),
    types: [
      types.UPDATE_USER_REQUEST,
      types.UPDATE_USER_SUCCESS,
      types.UPDATE_USER_FAILURE,
    ],
    body: data,
  });
