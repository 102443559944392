import React from 'react';
import { Provider } from 'react-redux';
import { ConnectedRouter } from 'react-router-redux';

import 'bootstrap/dist/css/bootstrap.css';
import './scss/app.scss';
import Wrapper from './components/Layout/Wrapper';
import state, { initialize } from './state';
import { history } from './state/history';
import middleware from './state/middlewares';
import reducers from './state/reducers';
import routes from './routes';
import { redirectIfAuthenticated } from './utils/authHelpers';
import { setCookiesFromQueryParams } from './utils/cookies';

export default class App extends React.Component {
  constructor(props) {
    super(props);
    initialize(history, middleware, reducers);
    setCookiesFromQueryParams();
  }

  componentDidMount() {
    redirectIfAuthenticated(state.store);
  }

  render() {
    const { store } = state;
    return (
      <Provider store={store}>
        <ConnectedRouter history={history}>
          <Wrapper routes={routes} />
        </ConnectedRouter>
      </Provider>
    );
  }
}
