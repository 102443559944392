import Immutable from 'seamless-immutable';

import * as types from './actionTypes';

const DEFAULT_SIDEBAR_STATE = {
  show: false,
  collapse: false,
};

const DEFAULT_MODALS_STATE = {
  SDKModal: {
    isOpen: false,
  },
  UserLegalInfoModal: {
    isOpen: false,
  },
  TosModal: {
    isOpen: false,
  },
  InvoiceModal: {
    isOpen: false,
  },
  UserDetailsModal: {
    isOpen: false,
  },
};

const DEFAULT_SDK_INSTRUCTIONS_STATE = {
  isAndroidInstructionsVisible: true,
  isUnityInstructionsVisible: false,
  isWindowsInstructionsVisible: false,
  isMacosInstructionsVisible: false,
};

const DEFAULT_STATE = Immutable({
  sidebar: DEFAULT_SIDEBAR_STATE,
  modals: DEFAULT_MODALS_STATE,
  sdkInstructions: DEFAULT_SDK_INSTRUCTIONS_STATE,
});

export default (state = DEFAULT_STATE, { type, payload }) => {
  switch (type) {
    case types.CHANGE_SIDEBAR_VISIBILITY:
      return Immutable.set(state, 'sidebar', {
        ...DEFAULT_SIDEBAR_STATE,
        collapse: !state.sidebar.collapse,
      });
    case types.CHANGE_MOBILE_SIDEBAR_VISIBILITY:
      return Immutable.set(state, 'sidebar', {
        ...DEFAULT_SIDEBAR_STATE,
        show: !state.sidebar.show,
      });
    case types.TOGGLE_MODAL:
      return Immutable.set(state, 'modals', {
        ...DEFAULT_MODALS_STATE,
        [payload.modalName]: {
          ...state.modals[payload.modalName],
          isOpen: !state.modals[payload.modalName].isOpen,
        },
      });
    case types.SET_ANDROID_INSTRUCTION_VISIBILITY:
      return Immutable.set(state, 'sdkInstructions', {
        ...DEFAULT_SDK_INSTRUCTIONS_STATE,
        isAndroidInstructionsVisible: true,
      });
    case types.SET_UNITY_INSTRUCTION_VISIBILITY:
      return Immutable.set(state, 'sdkInstructions', {
        ...DEFAULT_SDK_INSTRUCTIONS_STATE,
        isUnityInstructionsVisible: true,
        isAndroidInstructionsVisible: false,
      });
    case types.SET_WINDOWS_INSTRUCTION_VISIBILITY:
      return Immutable.set(state, 'sdkInstructions', {
        ...DEFAULT_SDK_INSTRUCTIONS_STATE,
        isWindowsInstructionsVisible: true,
        isAndroidInstructionsVisible: false,
      });
    case types.SET_MACOS_INSTRUCTION_VISIBILITY:
      return Immutable.set(state, 'sdkInstructions', {
        ...DEFAULT_SDK_INSTRUCTIONS_STATE,
        isMacosInstructionsVisible: true,
        isAndroidInstructionsVisible: false,
      });
    default:
      return state;
  }
};
