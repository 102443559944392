import React from 'react';
import PropTypes from 'prop-types';
import { Card, CardBody, Badge } from 'reactstrap';
import { connect } from 'react-redux';
import idx from 'idx';
import classNames from 'classnames';

import apps from '../../../../apps';
import ui from '../../../../ui';
import users from '../../../../users';
import Table from '../../../components/Table/BasicTable';
import { TOS_MODAL, USER_LEGAL_INFO_MODAL } from './modalNames';

const COLORS_MAP = {
  active: 'success',
  pending: 'primary',
  disabled: 'danger',
  default: 'primary',
};

const colorByStatus = status =>
  COLORS_MAP[status] ? COLORS_MAP[status] : COLORS_MAP.default;

const AppsTable = ({
  downloadSdk,
  toggleUserDetailsModal,
  toggleTosModal,
  updateSelectedApp,
  apps: applications,
  userAgreed,
  legalType,
}) => (
  <div>
    <Card>
      <CardBody>
        <div className="card__title">
          <h5 className="bold-text">Your Apps</h5>
        </div>
        <Table
          colNames={[
            'App Name',
            'Distribution Platform',
            'Request Date',
            'SDK Status',
            'SDK Download',
          ]}
          dataKeys={[
            'name',
            item => idx(item, p => p.distribution_platform.name),
            'created_at',
            item => (
              <Badge color={colorByStatus(item.status)}>{item.status}</Badge>
            ),
            item => (
              <button
                className={classNames({
                  'btn btn-outline-primary btn-sm mb-0': true,
                  disabled: item.sdk === null || item.status !== 'active',
                })}
                onClick={() => {
                  updateSelectedApp(item);
                  if (userAgreed && legalType !== null) {
                    downloadSdk(item.sdk);
                  } else if (userAgreed && legalType === null) {
                    toggleUserDetailsModal();
                  } else if (!userAgreed && legalType === null) {
                    toggleUserDetailsModal();
                  } else {
                    toggleTosModal();
                  }
                }}
              >
                Download
              </button>
            ),
          ]}
          data={applications}
        />
      </CardBody>
    </Card>
  </div>
);

AppsTable.propTypes = {
  toggleUserDetailsModal: PropTypes.func.isRequired,
  toggleTosModal: PropTypes.func.isRequired,
  apps: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  downloadSdk: PropTypes.func.isRequired,
  updateSelectedApp: PropTypes.func.isRequired,
  userAgreed: PropTypes.bool.isRequired,
  legalType: PropTypes.string.isRequired,
};

AppsTable.defaultProps = {
  legalType: null,
};

const mapStateToProps = state => {
  const { user_agreed: userAgreed } = users.selectors.getTosData(state);
  const { legal_type: legalType } = users.selectors.getLegalInfoData(state);

  return {
    apps: apps.selectors.getApps(state),
    userAgreed,
    legalType,
  };
};

const mapDispatchToProps = dispatch => ({
  downloadSdk: url => dispatch(apps.actions.downloadSdk(url)),
  updateSelectedApp: item => dispatch(apps.actions.updateSelectedApp(item)),
  toggleTosModal: () => dispatch(ui.actions.toggleModal(TOS_MODAL)),
  toggleUserDetailsModal: () =>
    dispatch(ui.actions.toggleModal(USER_LEGAL_INFO_MODAL)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AppsTable);
