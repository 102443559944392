/* eslint-disable react/no-children-prop */
import React from 'react';
import TextField from '@material-ui/core/TextField';
import PropTypes from 'prop-types';

const renderTextField = ({
  input,
  label,
  type,
  meta: { touched, error },
  validationname,
  children,
  placeholder,
}) => {
  const errorSpan =
    touched && error != null ? (
      <div className="material-form__field-error">{error}</div>
    ) : null;
  return (
    <div>
      <TextField
        {...input}
        className="material-form__field"
        label={label}
        type={type}
        error={touched && error != null}
        children={children}
        validationname={validationname}
        placeholder={placeholder}
      />
      {errorSpan}
    </div>
  );
};

renderTextField.propTypes = {
  input: PropTypes.shape().isRequired,
  label: PropTypes.string.isRequired,
  defaultValue: PropTypes.string,
  type: PropTypes.string,
  meta: PropTypes.shape({
    touched: PropTypes.bool,
    error: PropTypes.string,
  }),
  select: PropTypes.bool,
  children: PropTypes.arrayOf(PropTypes.element),
  placeholder: PropTypes.string,
  validationname: PropTypes.string,
};

renderTextField.defaultProps = {
  meta: null,
  select: false,
  children: [],
  type: 'text',
  defaultValue: '',
  placeholder: '',
  validationname: '',
};

export default renderTextField;
