import React from 'react';
import PropTypes from 'prop-types';
import { Card, CardBody, Badge } from 'reactstrap';

import Loader from './Loader';

const Panel = props => (
  <Card>
    <CardBody className="panel__body">
      <Loader isLoading={props.loading} />
      {props.titleElementVisible && (
        <div className="panel__title">
          <h5 className="bold-text">
            {props.icon ? (
              <span className={`panel__icon lnr lnr-${props.icon}`} />
            ) : (
              ''
            )}
            {props.title}
            <Badge className="panel__label">{props.label}</Badge>
          </h5>
          <h5 className="subhead">{props.subhead}</h5>
        </div>
      )}
      <div className="panel__content">{props.children}</div>
    </CardBody>
  </Card>
);

Panel.propTypes = {
  title: PropTypes.string,
  icon: PropTypes.string,
  label: PropTypes.string,
  subhead: PropTypes.string,
  titleElementVisible: PropTypes.bool,
  loading: PropTypes.bool,
  children: PropTypes.node.isRequired,
};

Panel.defaultProps = {
  title: '',
  icon: null,
  label: '',
  subhead: '',
  titleElementVisible: true,
  loading: false,
};

export default Panel;
