import Immutable from 'seamless-immutable';

import * as types from './actionTypes';
import {
  SESSION_EXPIRED_ERROR,
  INVALID_CREDENTIAL_ERROR,
  LOGIN,
  REGISTER,
} from './constants';

const DEFAULT_LOGIN_STATE = {
  username: null,
  email: null,
  token: null,
  uuid: null,
  isAuthenticated: false,
  fetching: false,
  error: null,
};

const DEFAULT_REGISTER_STATE = {
  fetching: false,
  errors: [],
  isCheckYourEmailScreenVisible: false,
  isRegistrationCompletionVisible: false,
  isRegistrationTokenExpired: false,
  resendClicked: false,
  email: '',
  token: '',
};

const DEFAULT_STATE = Immutable({
  [LOGIN]: DEFAULT_LOGIN_STATE,
  [REGISTER]: DEFAULT_REGISTER_STATE,
});

export default (state = DEFAULT_STATE, { payload, type }) => {
  switch (type) {
    // Login
    case types.LOGIN_REQUEST:
      return Immutable.set(state, LOGIN, {
        ...DEFAULT_LOGIN_STATE,
        fetching: true,
      });
    case types.LOGIN_SUCCESS:
    case types.AUTH_VALIDATED:
      return Immutable.set(state, LOGIN, {
        ...DEFAULT_LOGIN_STATE,
        ...payload,
        isAuthenticated: true,
      });
    case types.LOGIN_FAILURE:
      return Immutable.set(state, LOGIN, {
        ...DEFAULT_LOGIN_STATE,
        error: INVALID_CREDENTIAL_ERROR,
      });
    case types.VERIFY_TOKEN_FAILURE:
      return Immutable.set(state, LOGIN, {
        ...DEFAULT_LOGIN_STATE,
        error: SESSION_EXPIRED_ERROR,
      });

    // Logout
    case types.LOGOUT:
      return DEFAULT_STATE;

    // Register
    case types.REGISTER_REQUEST:
    case types.SEND_CONFIRMATION_REQUEST:
      return Immutable.set(state, REGISTER, {
        ...state.register,
        fetching: true,
      });
    case types.REGISTER_SUCCESS:
      return Immutable.set(state, REGISTER, {
        ...DEFAULT_REGISTER_STATE,
        fetching: false,
      });
    case types.REGISTER_FAILURE:
    case types.SEND_CONFIRMATION_FAILURE:
      return Immutable.set(state, REGISTER, {
        ...state.register,
        errors: payload.response.errors,
        fetching: false,
      });
    case types.SEND_CONFIRMATION_SUCCESS:
      return Immutable.set(state, REGISTER, {
        ...state.register,
        isCheckYourEmailScreenVisible: true,
        fetching: false,
      });
    case types.SET_CONFIRMATION_EMAIL:
      return Immutable.set(state, REGISTER, {
        ...state.register,
        email: payload,
      });
    case types.SET_TOKEN_EXPIRED:
      return Immutable.set(state, REGISTER, {
        ...state.register,
        isRegistrationTokenExpired: payload,
      });
    case types.SET_RESEND_CLICKED:
      return Immutable.set(state, REGISTER, {
        ...state.register,
        resendClicked: true,
      });
    case types.HANDLE_REGISTRATION_COMPLETION:
      return Immutable.set(state, REGISTER, {
        ...state.register,
        isRegistrationCompletionVisible: payload,
      });
    case types.SET_TOKEN:
      return Immutable.set(state, REGISTER, {
        ...state.register,
        token: payload,
      });

    default:
      return state;
  }
};
