import { connect } from 'react-redux';

import stats from '../../../../stats';
import countries from '../../../../countries';
import CountryPicker from './CountryPicker';

const title = 'Daily rate per 10k active users';
const tableColumnNames = ['Country', 'Platform', 'Tier', 'Daily earnings'];
const tableDataKeys = ['country', 'platform', 'tier', 'price'];

const mapStateToProps = state => {
  const data = stats.selectors.getFormatedTierPricePerCountryData(state);

  return {
    title,
    selectedCountry: state.countries.selectedPricingCountry,
    options: stats.selectors.getCountrySelectionOptions(data),
    tableColumnNames,
    tableDataKeys,
    tableData: stats.selectors.getParsedTableData(
      state.countries.selectedPricingCountry
    ),
  };
};

const mapDispatchToProps = dispatch => ({
  handleSelect: selectedCountry =>
    dispatch(countries.actions.onCountrySelected(selectedCountry)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CountryPicker);
