import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { Container, Col, Row } from 'reactstrap';

import users from '../../../users';

class Tos extends PureComponent {
  render() {
    const {
      tosData: { content },
    } = this.props;

    return (
      <Container>
        <Row>
          <Col md={12}>
            <h3 className="page-title">TOS</h3>
          </Col>
        </Row>
        <Row>
          <Col md={12}>
            <div
              className="tos-container"
              dangerouslySetInnerHTML={{ __html: content }}
            />
          </Col>
        </Row>
      </Container>
    );
  }
}

Tos.propTypes = {
  tosData: PropTypes.shape().isRequired,
};

const enhance = connect(state => ({
  tosData: users.selectors.getTosData(state),
}));

export default enhance(Tos);
