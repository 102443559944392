import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Container } from 'reactstrap';

import apps from '../../../apps';
import users from '../../../users';
import Sdk from './Sdk';
import OnboardingSdk from './OnboardingSdk';
import SdkModals from './components/SdkModals';
import Loader from '../../components/Loader';

class SDKContainer extends PureComponent {
  componentDidMount() {
    const {
      appsFetching,
      fetchApps,
      legalInfoFetching,
      fetchLegalInfo,
    } = this.props;

    if (!appsFetching) {
      fetchApps();
    }
    if (!legalInfoFetching) {
      fetchLegalInfo();
    }
  }

  render() {
    const { appsFetching } = this.props;

    return (
      <Container className="sdk">
        <OnboardingSdk />
        <Sdk />
        <SdkModals />
        <Loader isLoading={appsFetching} />
      </Container>
    );
  }
}

SDKContainer.propTypes = {
  appsFetching: PropTypes.bool.isRequired,
  legalInfoFetching: PropTypes.bool.isRequired,
  fetchApps: PropTypes.func.isRequired,
  fetchLegalInfo: PropTypes.func.isRequired,
};

const mapStateToProps = state => ({
  appsFetching: apps.selectors.appsFetching(state),
  legalInfoFetching: users.selectors.isLegalInfoFetching(state),
});

const mapDispatchToProps = {
  fetchApps: apps.actions.fetchApps,
  fetchLegalInfo: users.actions.fetchLegalInfo,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(SDKContainer);
