import React from 'react';
import PropTypes from 'prop-types';
import Select from 'react-select';
import Table from '../../../components/Table/BasicTable';

const CountryPicker = ({
  title,
  handleSelect,
  selectedCountry,
  options,
  tableColumnNames,
  tableDataKeys,
  tableData,
}) => (
  <div>
    <div className="card__title">
      <h5 className="bold-text">{title}</h5>
    </div>
    <div className="pricing__select__container">
      <div className="form pricing__select__picker">
        <div className="form__form-group-input-wrap">
          <Select
            name="Select"
            options={options}
            placeholder="Select a country"
            onChange={country => handleSelect(country)}
            value={selectedCountry}
            className="form__form-group-select"
          />
        </div>
      </div>
      {selectedCountry && (
        <Table
          colNames={tableColumnNames}
          dataKeys={tableDataKeys}
          data={tableData}
        />
      )}
    </div>
  </div>
);

CountryPicker.propTypes = {
  title: PropTypes.string.isRequired,
  handleSelect: PropTypes.func.isRequired,
  selectedCountry: PropTypes.shape(),
  options: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  tableColumnNames: PropTypes.arrayOf(PropTypes.string).isRequired,
  tableDataKeys: PropTypes.arrayOf(PropTypes.string).isRequired,
  tableData: PropTypes.arrayOf(PropTypes.shape()),
};

CountryPicker.defaultProps = {
  selectedCountry: null,
  tableData: [],
};

export default CountryPicker;
