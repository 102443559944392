import * as types from './actionTypes';
import { callAPI } from '../api';
import { requestHeaders } from '../api/utils';

export const onCountrySelected = country => ({
  type: types.PRICING_COUNTRY_SELECTED,
  payload: country,
});

export const fetchCountries = () =>
  callAPI({
    method: 'GET',
    endpoint: '/api/v1/countries/',
    headers: requestHeaders(true),
    types: [
      types.FETCH_COUNTRIES_REQUEST,
      types.FETCH_COUNTRIES_SUCCESS,
      types.FETCH_COUNTRIES_FAILURE,
    ],
  });
