import Immutable from 'seamless-immutable';

import * as types from './actionTypes';

const DEFAULT_CREATE_APP_STATE = {
  data: {},
  errors: [],
  isFetching: false,
};

const DEFAULT_APPS_STATE = {
  data: [],
  isFetching: false,
};

const DEFAULT_SELECTED_APP_STATE = {
  sdkUrl: null,
};

const DEFAULT_DISTRIBUTION_PLATFORMS_STATE = {
  data: [],
  isFetching: false,
};

const DEFAULT_STATE = Immutable({
  createdApp: DEFAULT_CREATE_APP_STATE,
  apps: DEFAULT_APPS_STATE,
  selectedApp: DEFAULT_SELECTED_APP_STATE,
  distributionPlatforms: DEFAULT_DISTRIBUTION_PLATFORMS_STATE,
});

export default (state = DEFAULT_STATE, { payload, type }) => {
  switch (type) {
    case types.CREATE_APP_REQUEST:
      return Immutable.set(state, 'createdApp', {
        ...DEFAULT_CREATE_APP_STATE,
        isFetching: true,
      });
    case types.CREATE_APP_SUCCESS:
      return Immutable.merge(state, {
        ...DEFAULT_STATE,
        createdApp: {
          ...DEFAULT_CREATE_APP_STATE,
          data: payload,
          isFetching: false,
        },
        apps: {
          ...DEFAULT_APPS_STATE,
          ...state.apps,
          data: [payload, ...state.apps.data],
        },
      });
    case types.CREATE_APP_FAILURE:
      return Immutable.set(state, 'createdApp', {
        ...DEFAULT_CREATE_APP_STATE,
        errors: payload.response.error_messages || [],
        isFetching: false,
      });

    case types.FETCH_APPS_REQUEST:
      return Immutable.set(state, 'apps', {
        ...DEFAULT_APPS_STATE,
        isFetching: true,
      });
    case types.FETCH_APPS_SUCCESS:
      return Immutable.set(state, 'apps', {
        ...DEFAULT_APPS_STATE,
        data: payload,
        isFetching: false,
      });
    case types.FETCH_APPS_FAILURE:
      return Immutable.set(state, 'apps', {
        ...DEFAULT_APPS_STATE,
        isFetching: false,
      });

    case types.UPDATE_SELECTED_APP:
      return Immutable.set(state, 'selectedApp', {
        ...DEFAULT_SELECTED_APP_STATE,
        sdkUrl: payload.sdk,
      });

    case types.FETCH_DISTRIBUTION_PLATFORMS_REQUEST:
      return Immutable.set(state, 'distributionPlatforms', {
        ...DEFAULT_DISTRIBUTION_PLATFORMS_STATE,
        isFetching: true,
      });
    case types.FETCH_DISTRIBUTION_PLATFORMS_SUCCESS:
      return Immutable.set(state, 'distributionPlatforms', {
        ...DEFAULT_DISTRIBUTION_PLATFORMS_STATE,
        data: payload,
        isFetching: false,
      });
    case types.FETCH_DISTRIBUTION_PLATFORMS_FAILURE:
      return Immutable.set(state, 'distributionPlatforms', {
        ...DEFAULT_DISTRIBUTION_PLATFORMS_STATE,
        isFetching: false,
      });

    default:
      return state;
  }
};
