import { NAME } from './constants';

export const FETCH_PAYOUTS_REQUEST = `${NAME}.FETCH_PAYOUTS_REQUEST`;
export const FETCH_PAYOUTS_SUCCESS = `${NAME}.FETCH_PAYOUTS_SUCCESS`;
export const FETCH_PAYOUTS_FAILURE = `${NAME}.FETCH_PAYOUTS_FAILURE`;

export const FETCH_INVOICES_REQUEST = `${NAME}.FETCH_INVOICES_REQUEST`;
export const FETCH_INVOICES_SUCCESS = `${NAME}.FETCH_INVOICES_SUCCESS`;
export const FETCH_INVOICES_FAILURE = `${NAME}.FETCH_INVOICES_FAILURE`;

export const CREATE_INVOICE_REQUEST = `${NAME}.CREATE_INVOICE_REQUEST`;
export const CREATE_INVOICE_SUCCESS = `${NAME}.CREATE_INVOICE_SUCCESS`;
export const CREATE_INVOICE_FAILURE = `${NAME}.CREATE_INVOICE_FAILURE`;

export const UPDATE_SELECTED_INVOICE = `${NAME}.UPDATE_SELECTED_INVOICE`;

export const SET_INVOICES_DATA = `${NAME}.SET_INVOICES_DATA`;

export const UPDATE_PAYMENTS_CHART_VALUE = `${NAME}.UPDATE_PAYMENTS_CHART_VALUE`;

export const REQUEST_PAYOUT_GA_EVENT = `${NAME}.REQUEST_PAYOUT_GA_EVENT`;
