import React from 'react';
import { Link } from 'react-router-dom';

import RegisterForm from './components/RegisterForm';
import LoginWrapper from '../../components/LoginWrapper';
import CheckYourEmail from './components/CheckYourEmail';
import { LOGIN } from '../../utils/routes';

const Register = () => (
  <LoginWrapper>
    <div className="account__card">
      <RegisterForm />
      <CheckYourEmail />
      <div className="account__have-account">
        <p>
          Already have an account? <Link to={LOGIN}>Login</Link>
        </p>
      </div>
    </div>
  </LoginWrapper>
);

export default Register;
