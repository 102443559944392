import { NAME } from './constants';

export const CHANGE_SIDEBAR_VISIBILITY = `${NAME}.CHANGE_SIDEBAR_VISIBILITY`;
export const CHANGE_MOBILE_SIDEBAR_VISIBILITY = `${NAME}.CHANGE_MOBILE_SIDEBAR_VISIBILITY`;
export const TOGGLE_MODAL = `${NAME}.TOGGLE_MODAL`;

export const SET_ANDROID_INSTRUCTION_VISIBILITY = `${NAME}.SET_ANDROID_INSTRUCTION_VISIBILITY`;
export const SET_UNITY_INSTRUCTION_VISIBILITY = `${NAME}.SET_UNITY_INSTRUCTION_VISIBILITY`;
export const SET_WINDOWS_INSTRUCTION_VISIBILITY = `${NAME}.SET_WINDOWS_INSTRUCTION_VISIBILITY`;
export const SET_MACOS_INSTRUCTION_VISIBILITY = `${NAME}.SET_MACOS_INSTRUCTION_VISIBILITY`;
