import { connect } from 'react-redux';

import hideable from '../../../utils/hideable';
import stats from '../../../../stats';
import MapWrapper from './MapWrapper';

const title = 'Average daily active users per country';

// Use old syntax because of third party highmap library
const generateTooltipHtml = countryData => `
  <b>${countryData.name}</b>
  ${
    countryData.value != null
      ? `</br>
        Average daily active users:
        <b>
          ${countryData.value.toFixed()}
        </b>`
      : ''
  }
`;

const mapStateToProps = state => {
  const data = stats.selectors.getDailyActiveUsersPerCountryMapData(state);
  return {
    title,
    dataIsFetching: stats.selectors.isDailyActiveUsersPerCountryFetching(state),
    colorValues: stats.selectors.getDailyActiveUsersPerCountryValues(data),
    data,
    createTooltip: generateTooltipHtml,
    isVisible: !!stats.selectors.getDailyActiveUsersPerCountryMapData(state),
  };
};

export default connect(mapStateToProps)(hideable(MapWrapper));
