import idx from 'idx';

import { NAME } from './constants';

export const isAuthenticated = state =>
  idx(state, p => p[NAME].login.isAuthenticated);

export const isLoginFetching = state => idx(state, p => p[NAME].login.fetching);

export const getLoginError = state => idx(state, p => p[NAME].login.error);

export const getUsername = state => idx(state, p => p[NAME].login.username);

export const isSuperUser = state => idx(state, p => p[NAME].login.is_superuser);

export const hadPayout = state => idx(state, p => p[NAME].login.had_payout);

export const getUUID = state => idx(state, p => p[NAME].login.uuid);

export const getMinPayoutAmount = state =>
  idx(state, p => p[NAME].login.min_payout_amount);

export const getMinPayoutDays = state =>
  idx(state, p => p[NAME].login.min_payout_days);

export const isRegisterFetching = state =>
  idx(state, p => p[NAME].register.fetching);

export const getRegisterErrors = state =>
  idx(state, p => p[NAME].register.errors);

export const isCheckYourEmailScreenVisible = state =>
  idx(state, p => p[NAME].register.isCheckYourEmailScreenVisible);

export const isRegistrationTokenExpired = state =>
  idx(state, p => p[NAME].register.isRegistrationTokenExpired);

export const isRegistrationCompletionVisible = state =>
  idx(state, p => p[NAME].register.isRegistrationCompletionVisible);

export const getConfirmationEmail = state =>
  idx(state, p => p[NAME].register.email);

export const getRegistrationToken = state =>
  idx(state, p => p[NAME].register.token);

export const isResendClicked = state =>
  idx(state, p => p[NAME].register.resendClicked);
