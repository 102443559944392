import idx from 'idx';
import { createSelector } from 'reselect';

import { NAME, DECLINED_STATUS } from './constants';

export const isCreateAppFetching = state =>
  idx(state, p => p[NAME].createdApp.isFetching);

export const getCreateAppErrors = state =>
  idx(state, p => p[NAME].createdApp.errors);

export const appsFetching = state => idx(state, p => p[NAME].apps.isFetching);

export const getApps = state => idx(state, p => p[NAME].apps.data);

export const userHasApps = state => idx(state, p => !!p[NAME].apps.data.length);

export const getSelectedAppURL = state =>
  idx(state, p => p[NAME].selectedApp.sdkUrl);

export const getDistributionPlatforms = state =>
  idx(state, p => p[NAME].distributionPlatforms.data);

export const getActiveAndPendingApps = createSelector(getApps, apps =>
  apps.filter(app => app.status !== DECLINED_STATUS)
);
