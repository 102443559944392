import Immutable from 'seamless-immutable';

import * as types from './actionTypes';

const DEFAULT_PAGES_STATE = {
  data: [],
  isFetching: false,
};

const DEFAULT_ELEMENTS_STATE = {
  data: [],
  isFetching: false,
};

const DEFAULT_STATE = Immutable({
  pages: DEFAULT_PAGES_STATE,
  elements: DEFAULT_ELEMENTS_STATE,
});

export default (state = DEFAULT_STATE, { payload, type }) => {
  switch (type) {
    case types.FETCH_PAGES_REQUEST:
      return Immutable.set(state, 'pages', {
        ...DEFAULT_PAGES_STATE,
        isFetching: true,
      });
    case types.FETCH_PAGES_SUCCESS:
      return Immutable.set(state, 'pages', {
        ...DEFAULT_PAGES_STATE,
        data: payload,
        isFetching: false,
      });
    case types.FETCH_PAGES_FAILURE:
      return Immutable.set(state, 'pages', {
        ...DEFAULT_PAGES_STATE,
        isFetching: false,
      });
    case types.FETCH_ELEMENTS_REQUEST:
      return Immutable.set(state, 'elements', {
        ...DEFAULT_PAGES_STATE,
        isFetching: true,
      });
    case types.FETCH_ELEMENTS_SUCCESS:
      return Immutable.set(state, 'elements', {
        ...DEFAULT_PAGES_STATE,
        data: payload,
        isFetching: false,
      });
    case types.FETCH_ELEMENTS_FAILURE:
      return Immutable.set(state, 'elements', {
        ...DEFAULT_PAGES_STATE,
        isFetching: false,
      });

    default:
      return state;
  }
};
