import idx from 'idx';

import { NAME } from './constants';

export const getSidebar = state => idx(state, p => p[NAME].sidebar);

export const isSDKModalOpen = state =>
  idx(state, p => p[NAME].modals.SDKModal.isOpen);

export const isUserLegalInfoModalOpen = state =>
  idx(state, p => p[NAME].modals.UserLegalInfoModal.isOpen);

export const isTosModalOpen = state =>
  idx(state, p => p[NAME].modals.TosModal.isOpen);

export const isInvoiceModalOpen = state =>
  idx(state, p => p[NAME].modals.InvoiceModal.isOpen);

export const isUserDetailsModalOpen = state =>
  idx(state, p => p[NAME].modals.UserDetailsModal.isOpen);

export const isAndroidInstructionsVisible = state =>
  idx(state, p => p[NAME].sdkInstructions.isAndroidInstructionsVisible);

export const isUnityInstructionsVisible = state =>
  idx(state, p => p[NAME].sdkInstructions.isUnityInstructionsVisible);

export const isWindowsInstructionsVisible = state =>
  idx(state, p => p[NAME].sdkInstructions.isWindowsInstructionsVisible);

export const isMacosInstructionsVisible = state =>
  idx(state, p => p[NAME].sdkInstructions.isMacosInstructionsVisible);
