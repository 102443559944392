import React from 'react';
import PropTypes from 'prop-types';

import MultiSelect from '../../../components/Form/MultiSelect';

const AdminMultiSelect = props => {
  const { isSuperUser, onChange, options, value } = props;
  if (!isSuperUser) {
    return null;
  }

  return (
    <div className="form__form-group">
      <span className="form__form-group-label">Users</span>
      <div className="form__form-group-field">
        <MultiSelect
          name="user_select"
          onChange={onChange}
          options={options}
          value={value}
        />
      </div>
    </div>
  );
};

AdminMultiSelect.defaultProps = {
  allUsers: [],
  isSuperUser: null,
};
AdminMultiSelect.propTypes = {
  options: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  value: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.string,
      label: PropTypes.string,
    })
  ).isRequired,
  onChange: PropTypes.func.isRequired,
  isSuperUser: PropTypes.bool,
};

export default AdminMultiSelect;
