import { NAME } from './constants';

export const FETCH_DAILY_ACTIVE_USERS_REQUEST = `${NAME}.FETCH_DAILY_ACTIVE_USERS_REQUEST`;
export const FETCH_DAILY_ACTIVE_USERS_SUCCESS = `${NAME}.FETCH_DAILY_ACTIVE_USERS_SUCCESS`;
export const FETCH_DAILY_ACTIVE_USERS_FAILURE = `${NAME}.FETCH_DAILY_ACTIVE_USERS_FAILURE`;

export const FETCH_DAILY_ACTIVE_USERS_PER_TIER_REQUEST = `${NAME}.FETCH_DAILY_ACTIVE_USERS_PER_TIER_REQUEST`;
export const FETCH_DAILY_ACTIVE_USERS_PER_TIER_SUCCESS = `${NAME}.FETCH_DAILY_ACTIVE_USERS_PER_TIER_SUCCESS`;
export const FETCH_DAILY_ACTIVE_USERS_PER_TIER_FAILURE = `${NAME}.FETCH_DAILY_ACTIVE_USERS_PER_TIER_FAILURE`;

export const FETCH_DAILY_ACTIVE_USERS_PER_APP_REQUEST = `${NAME}.FETCH_DAILY_ACTIVE_USERS_PER_APP_REQUEST`;
export const FETCH_DAILY_ACTIVE_USERS_PER_APP_SUCCESS = `${NAME}.FETCH_DAILY_ACTIVE_USERS_PER_APP_SUCCESS`;
export const FETCH_DAILY_ACTIVE_USERS_PER_APP_FAILURE = `${NAME}.FETCH_DAILY_ACTIVE_USERS_PER_APP_FAILURE`;

export const FETCH_DAILY_ACTIVE_USERS_PER_COUNTRY_REQUEST = `${NAME}.FETCH_DAILY_ACTIVE_USERS_PER_COUNTRY_REQUEST`;
export const FETCH_DAILY_ACTIVE_USERS_PER_COUNTRY_SUCCESS = `${NAME}.FETCH_DAILY_ACTIVE_USERS_PER_COUNTRY_SUCCESS`;
export const FETCH_DAILY_ACTIVE_USERS_PER_COUNTRY_FAILURE = `${NAME}.FETCH_DAILY_ACTIVE_USERS_PER_COUNTRY_FAILURE`;

export const FETCH_DAILY_EARNINGS_REQUEST = `${NAME}.FETCH_DAILY_EARNINGS_REQUEST`;
export const FETCH_DAILY_EARNINGS_SUCCESS = `${NAME}.FETCH_DAILY_EARNINGS_SUCCESS`;
export const FETCH_DAILY_EARNINGS_FAILURE = `${NAME}.FETCH_DAILY_EARNINGS_FAILURE`;

export const FETCH_DAILY_EARNINGS_PER_TIER_REQUEST = `${NAME}.FETCH_DAILY_EARNINGS_PER_TIER_REQUEST`;
export const FETCH_DAILY_EARNINGS_PER_TIER_SUCCESS = `${NAME}.FETCH_DAILY_EARNINGS_PER_TIER_SUCCESS`;
export const FETCH_DAILY_EARNINGS_PER_TIER_FAILURE = `${NAME}.FETCH_DAILY_EARNINGS_PER_TIER_FAILURE`;

export const FETCH_ALL_USERS_REQUEST = `${NAME}.FETCH_ALL_USERS_REQUEST`;
export const FETCH_ALL_USERS_SUCCESS = `${NAME}.FETCH_ALL_USERS_SUCCESS`;
export const FETCH_ALL_USERS_FAILURE = `${NAME}.FETCH_ALL_USERS_FAILURE`;

export const FETCH_TIER_PRICE_PER_COUNTRY_REQUEST = `${NAME}.FETCH_TIER_PRICE_PER_COUNTRY_REQUEST`;
export const FETCH_TIER_PRICE_PER_COUNTRY_SUCCESS = `${NAME}.FETCH_TIER_PRICE_PER_COUNTRY_SUCCESS`;
export const FETCH_TIER_PRICE_PER_COUNTRY_FAILURE = `${NAME}.FETCH_TIER_PRICE_PER_COUNTRY_FAILURE`;

export const UPDATE_FILTERS = `${NAME}.UPDATE_FILTERS`;
