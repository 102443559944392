import { connect } from 'react-redux';

import PayoutCard from './PayoutCard';
import billing from '../../billing';

const title = 'Pending Balance';
const isPending = true;

const mapStateToProps = state => ({
  title,
  label: `$${billing.selectors.getPendingAmount(state).toLocaleString()}`,
  isPending,
});

export default connect(mapStateToProps)(PayoutCard);
