import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import idx from 'idx';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

import stats from '../../../stats';
import Chart from './Chart';

class DailyActiveUsersPerAppChart extends PureComponent {
  componentDidMount() {
    const { isFetching, fetchData, filters, overrideFilters } = this.props;
    if (!isFetching) {
      fetchData(overrideFilters || filters);
    }
  }

  componentDidUpdate(prevProps) {
    const { fetchData, filters, overrideFilters } = this.props;
    if (overrideFilters === null && filters !== prevProps.filters) {
      fetchData(filters);
    }
  }

  getApps() {
    const apps = [];
    this.props.data.forEach(item => {
      item.values.forEach(app => {
        if (!apps.includes(app.app_name)) {
          apps.push(app.app_name);
        }
      });
    });

    return apps;
  }

  getLines(apps) {
    const lines = [];
    apps.forEach(appValue => {
      lines.push({
        name: `${appValue}`,
        type: 'monotone',
        dataKey: dataEntry =>
          idx(
            dataEntry,
            p =>
              p.values.find(obj => obj.app_name === appValue).daily_active_users
          ) || 0,
        fillOpacity: 0.2,
      });
    });

    return lines;
  }

  render() {
    const apps = this.getApps();
    return (
      <Chart
        type={'area'}
        title={'Daily Active Users Per App'}
        data={this.props.data}
        XAxisDataKey={'date'}
        lines={this.getLines(apps)}
        isFetching={this.props.isFetching}
      />
    );
  }
}

DailyActiveUsersPerAppChart.propTypes = {
  overrideFilters: PropTypes.shape(),

  data: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  isFetching: PropTypes.bool.isRequired,
  filters: PropTypes.shape().isRequired,
  fetchData: PropTypes.func.isRequired,
};

DailyActiveUsersPerAppChart.defaultProps = {
  overrideFilters: null,
};

const enhance = connect(
  state => ({
    data: stats.selectors.getFormatedDailyActiveUsersPerAppData(state),
    isFetching: stats.selectors.isDailyActiveUsersPerAppFetching(state),
    filters: stats.selectors.getFilters(state),
  }),
  dispatch => ({
    dispatch,
    fetchData: bindActionCreators(
      stats.actions.fetchDailyActiveUsersPerApp,
      dispatch
    ),
  })
);

export default enhance(DailyActiveUsersPerAppChart);
