import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import Select from 'react-select';

class MultiSelectField extends PureComponent {
  render() {
    const { name, placeholder, options, value, onChange } = this.props;

    return (
      <Select
        multi
        name={name}
        value={value}
        onChange={onChange}
        options={options}
        clearable={false}
        className="form__form-group-select"
        closeOnSelect={false}
        removeSelected={false}
        placeholder={placeholder}
      />
    );
  }
}

MultiSelectField.propTypes = {
  onChange: PropTypes.func.isRequired,
  name: PropTypes.string.isRequired,
  placeholder: PropTypes.string,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.string,
      label: PropTypes.string,
    })
  ),
  value: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.arrayOf(
      PropTypes.shape({
        value: PropTypes.string,
        label: PropTypes.string,
      })
    ),
  ]).isRequired,
};

MultiSelectField.defaultProps = {
  placeholder: '',
  options: [],
  defaultValue: {},
};

const renderMultiSelectField = props => (
  <div className="form__form-group-input-wrap">
    <MultiSelectField
      {...props.input}
      options={props.options}
      value={props.value}
      onChange={props.onChange}
      name={props.name}
    />
  </div>
);

renderMultiSelectField.propTypes = {
  options: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.string,
      label: PropTypes.string,
    })
  ),
};

renderMultiSelectField.defaultProps = {
  options: [],
};

export default renderMultiSelectField;
