import idx from 'idx';

import { NAME } from './constants';

export const pagesFetching = state => idx(state, p => p[NAME].pages.isFetching);

export const getPages = state => idx(state, p => p[NAME].pages.data);

export const elementsFetching = state =>
  idx(state, p => p[NAME].elements.isFetching);

export const getElementByName = (state, name) =>
  idx(state, p => p[NAME].elements.data.find(obj => obj.name === name).content);

export const getPageId = (state, name) =>
  idx(state, p => p[NAME].pages.data.find(obj => obj.name === name).id);
