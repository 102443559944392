import React, { PureComponent } from 'react';
import { Field, reduxForm } from 'redux-form';
import PropTypes from 'prop-types';

import { bindActionCreators } from 'redux';
import { compose } from 'recompose';
import { connect } from 'react-redux';
import BottomScrollListener from 'react-bottom-scroll-listener';

import users from '../../../../users';
import CheckBox from '../../../components/CheckBox';

class TosAcceptForm extends PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      tosAgreeCheckboxEnabled: false,
    };
  }

  createTosAgreement() {
    const {
      createTosAgreement,
      isCreateTosAgreementFetching,
      tosVersion,
    } = this.props;
    if (!isCreateTosAgreementFetching) {
      const data = JSON.stringify({
        version: tosVersion,
      });
      createTosAgreement(data);
    }
  }

  tosAccepted(value) {
    return value
      ? undefined
      : 'You have to agree to ToS in order to download our SDK.';
  }

  render() {
    const { handleSubmit, tosData } = this.props;

    return (
      <div>
        <BottomScrollListener
          onBottom={() => this.setState({ tosAgreeCheckboxEnabled: true })}
        >
          {scrollRef => (
            <div
              ref={scrollRef}
              dangerouslySetInnerHTML={{ __html: tosData.content }}
              className="mb-3 tos-text-block"
            />
          )}
        </BottomScrollListener>
        <form
          className="form"
          onSubmit={handleSubmit(values => this.createTosAgreement(values))}
        >
          <div className="form__form-group">
            <div className="form__form-group-field">
              <Field
                name="tos_agree"
                component={CheckBox}
                type="text"
                label="I agree to Terms of Service"
                validate={[this.tosAccepted]}
                disabled={!this.state.tosAgreeCheckboxEnabled}
              />
            </div>
          </div>
          <button type="submit" className="btn btn-primary form-control">
            Download SDK
          </button>
        </form>
      </div>
    );
  }
}

TosAcceptForm.propTypes = {
  tosData: PropTypes.shape().isRequired,

  isCreateTosAgreementFetching: PropTypes.bool.isRequired,
  tosVersion: PropTypes.string,
  createTosAgreement: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,
};

TosAcceptForm.defaultProps = {
  tosVersion: null,
};

const enhance = compose(
  connect(
    state => ({
      isCreateTosAgreementFetching: users.selectors.isCreateTosAgreementFetching(
        state
      ),
      tosVersion: users.selectors.getTosVersion(state),
    }),
    dispatch => ({
      dispatch,
      createTosAgreement: bindActionCreators(
        users.actions.createTosAgreement,
        dispatch
      ),
    })
  ),
  reduxForm({ form: 'tosAcceptForm' })
);

export default enhance(TosAcceptForm);
