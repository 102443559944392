import React from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { compose } from 'recompose';
import { connect } from 'react-redux';

import auth from '../../../../auth';
import TopbarSidebarButton from './TopbarSidebarButton';
import TopbarProfile from './TopbarProfile';

const Topbar = props => {
  const {
    changeMobileSidebarVisibility,
    changeSidebarVisibility,
    logout,
    username,
  } = props;
  return (
    <div className="topbar">
      <div className="topbar__wrapper">
        <div className="topbar__left">
          <TopbarSidebarButton
            changeMobileSidebarVisibility={changeMobileSidebarVisibility}
            changeSidebarVisibility={changeSidebarVisibility}
          />
          <Link className="topbar__logo" to="/dashboard" />
        </div>
        <div className="topbar__right">
          <TopbarProfile username={username} logout={logout} />
        </div>
      </div>
    </div>
  );
};

Topbar.propTypes = {
  changeMobileSidebarVisibility: PropTypes.func.isRequired,
  changeSidebarVisibility: PropTypes.func.isRequired,
  username: PropTypes.string,
  logout: PropTypes.func.isRequired,
};

Topbar.defaultProps = {
  username: '',
};

const enhance = compose(
  connect(
    state => ({
      username: auth.selectors.getUsername(state),
    }),
    dispatch => ({
      dispatch,
      logout: bindActionCreators(auth.actions.logout, dispatch),
    })
  )
);

export default enhance(Topbar);
