import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { compose } from 'recompose';
import { Field, reduxForm } from 'redux-form';
import MailRuIcon from 'mdi-react/MailRuIcon';
import PropTypes from 'prop-types';

import auth from '../../../../auth';
import CheckBox from '../../../components/CheckBox';
import Input from '../../../components/Input';
import { email, required } from '../../../utils/validators';
import hideable from '../../../utils/hideable';

class RegisterForm extends PureComponent {
  state = {
    privacyPolicyAgreed: false,
  };

  renderErrors() {
    const { registerErrors } = this.props;

    return registerErrors.map((error, i) => <li key={i}>{error.error_msg}</li>);
  }

  render() {
    const { handleSubmit, isFetching } = this.props;
    const { privacyPolicyAgreed } = this.state;

    return (
      <form className="form register-form" onSubmit={handleSubmit}>
        <h3>Create an account</h3>
        <ul className="mb-3 errors-list">{this.renderErrors()}</ul>
        <div className="form__form-group">
          <span className="form__form-group-label">E-mail</span>
          <div className="form__form-group-field">
            <div className="form__form-group-icon">
              <MailRuIcon />
            </div>
            <Field
              name="email"
              component={Input}
              type="email"
              label="example@email.com"
              validate={[required, email]}
            />
          </div>
        </div>
        <div className="form__form-group">
          <div className="form__form-group-field">
            <Field
              onChange={(e, val) => this.setState({ privacyPolicyAgreed: val })}
              name="privacyPolicyAgreed"
              component={CheckBox}
              type="text"
              label={
                <div>
                  <span>I have read and agree to the </span>
                  <a
                    href="https://globalhop.net/privacy-policy/"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Privacy Policy
                  </a>
                </div>
              }
            />
          </div>
        </div>
        <div className="account__btns">
          <button
            types="submit"
            className="btn btn-primary account__btn"
            disabled={!privacyPolicyAgreed || isFetching}
          >
            Sign Up
          </button>
        </div>
      </form>
    );
  }
}

RegisterForm.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  isFetching: PropTypes.bool.isRequired,
  registerErrors: PropTypes.arrayOf(PropTypes.shape()).isRequired,
};

const mapStateToProps = state => ({
  registerErrors: auth.selectors.getRegisterErrors(state),
  isFetching: auth.selectors.isRegisterFetching(state),
  isVisible: !auth.selectors.isCheckYourEmailScreenVisible(state),
});

const mapDispatchToProps = {
  onSubmit: auth.actions.sendConfirmationEmail,
};

const enhance = compose(
  connect(
    mapStateToProps,
    mapDispatchToProps
  ),
  reduxForm({
    form: 'registerForm',
  })
);

export default enhance(hideable(RegisterForm));
