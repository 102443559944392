import React, { PureComponent } from 'react';
import { Field, reduxForm } from 'redux-form';
import PropTypes from 'prop-types';
import { compose } from 'recompose';
import { connect } from 'react-redux';

import apps from '../../../../apps';
import renderTextField from '../../../components/Form/TextField';
import renderSelectField from '../../../components/Form/Select';
import { required, minLength6, maxLength30 } from '../../../utils/validators';

const DISTRIBUTION_PLATFORM_OTHER = 'other';

class RequestSDKForm extends PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      app_name: '',
      app_url: '',
      distribution_platform: '',
      distribution_platform_other: '',
    };
  }

  componentDidMount() {
    this.props.fetchDistributionPlatforms();
  }

  getDistributionPlatformName(values) {
    const distributionPlatform = values.distribution_platform.value.toLowerCase();
    return distributionPlatform !== DISTRIBUTION_PLATFORM_OTHER
      ? distributionPlatform
      : values.distribution_platform_other;
  }

  createApp(values) {
    const { createApp, isCreateAppFetching } = this.props;
    const data = JSON.stringify({
      name: values.app_name,
      url: values.app_url,
      distribution_platform: {
        name: this.getDistributionPlatformName(values),
      },
    });
    if (!isCreateAppFetching) {
      createApp(data);
    }
  }

  handleChange = (name, value) => {
    this.setState({
      [name]: value,
    });
  };

  renderErrors() {
    return this.props.errors.map((error, i) => (
      <li key={i}>{error.error_msg}</li>
    ));
  }

  renderDistributionPlatformOptions() {
    const options = [];
    this.props.distributionPlatforms.forEach(platform => {
      options.push({
        label: platform.name,
        value: platform.name,
      });
    });
    options.push({ label: 'Other', value: 'other' });
    return options;
  }

  render() {
    const { handleSubmit } = this.props;

    return (
      <div>
        <ul className="mb-3 errors-list">{this.renderErrors()}</ul>
        <form
          className="material-form"
          onSubmit={handleSubmit(values => this.createApp(values))}
        >
          <div>
            <Field
              name="app_name"
              component={renderTextField}
              type="text"
              label="App Name"
              defaultValue={this.state.app_name}
              validate={[required, minLength6, maxLength30]}
              onChange={event =>
                this.handleChange('app_name', event.target.value)
              }
            />
            <Field
              name="app_url"
              component={renderTextField}
              type="text"
              label="App URL"
              defaultValue={this.state.app_url}
              validate={[required]}
              onChange={event =>
                this.handleChange('app_url', event.target.value)
              }
            />
            <div className="form__form-group">
              <span className="form__form-group-label">
                Distribution platform
              </span>
              <div className="form__form-group-field">
                <Field
                  name="distribution_platform"
                  component={renderSelectField}
                  validate={[required]}
                  options={this.renderDistributionPlatformOptions()}
                  onChange={event =>
                    this.handleChange('distribution_platform', event.value)
                  }
                />
              </div>
            </div>
            {this.state.distribution_platform.toLowerCase() ===
              DISTRIBUTION_PLATFORM_OTHER && (
              <Field
                name="distribution_platform_other"
                component={renderTextField}
                type="text"
                label="Enter Other Distribution Platform"
                defaultValue={this.state.distribution_platform_other}
                validate={[required]}
                onChange={event =>
                  this.handleChange(
                    'distribution_platform_other',
                    event.target.value
                  )
                }
              />
            )}
          </div>
          <button type="submit" className="btn btn-primary form-control mt-2">
            Request
          </button>
        </form>
      </div>
    );
  }
}

RequestSDKForm.propTypes = {
  isCreateAppFetching: PropTypes.bool.isRequired,
  errors: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  distributionPlatforms: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  createApp: PropTypes.func.isRequired,
  fetchDistributionPlatforms: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,
};

const enhance = compose(
  connect(
    state => ({
      isCreateAppFetching: apps.selectors.isCreateAppFetching(state),
      errors: apps.selectors.getCreateAppErrors(state),
      distributionPlatforms: apps.selectors.getDistributionPlatforms(state),
    }),
    dispatch => ({
      createApp: data => dispatch(apps.actions.createApp(data)),
      fetchDistributionPlatforms: () =>
        dispatch(apps.actions.fetchDistributionPlatforms()),
    })
  ),
  reduxForm({ form: 'requestSDKForm' })
);

export default enhance(RequestSDKForm);
