import Immutable from 'seamless-immutable';

import * as types from './actionTypes';

const DEFAULT_PAYOUTS_STATE = {
  data: [],
  isFetching: false,
  chartData: [],
};

const DEFAULT_INVOICES_STATE = {
  data: [],
  isFetching: false,
};

const DEFAULT_CREATE_INVOICE_STATE = {
  errors: [],
  isFetching: false,
};

const DEFAULT_STATE = Immutable({
  payouts: DEFAULT_PAYOUTS_STATE,
  invoices: DEFAULT_INVOICES_STATE,
  createInvoice: DEFAULT_CREATE_INVOICE_STATE,
});

export default (state = DEFAULT_STATE, { payload, type }) => {
  switch (type) {
    case types.FETCH_PAYOUTS_REQUEST:
      return Immutable.set(state, 'payouts', {
        ...state.payouts,
        isFetching: true,
      });
    case types.FETCH_PAYOUTS_SUCCESS:
      return Immutable.set(state, 'payouts', {
        ...state.payouts,
        data: payload,
        isFetching: false,
      });
    case types.FETCH_PAYOUTS_FAILURE:
      return Immutable.set(state, 'payouts', {
        ...DEFAULT_PAYOUTS_STATE,
        isFetching: false,
      });
    case types.UPDATE_PAYMENTS_CHART_VALUE:
      return Immutable.set(state, 'payouts', {
        ...state.payouts,
        chartData: payload,
      });

    case types.FETCH_INVOICES_REQUEST:
      return Immutable.set(state, 'invoices', {
        ...DEFAULT_INVOICES_STATE,
        isFetching: true,
      });
    case types.SET_INVOICES_DATA:
      return Immutable.set(state, 'invoices', {
        ...DEFAULT_INVOICES_STATE,
        data: payload,
        isFetching: false,
      });
    case types.FETCH_INVOICES_FAILURE:
      return Immutable.set(state, 'invoices', {
        ...DEFAULT_INVOICES_STATE,
        isFetching: false,
      });

    case types.CREATE_INVOICE_REQUEST:
      return Immutable.set(state, 'createInvoice', {
        ...DEFAULT_CREATE_INVOICE_STATE,
        isFetching: true,
      });
    case types.CREATE_INVOICE_SUCCESS:
      return Immutable.set(state, 'createInvoice', {
        ...DEFAULT_CREATE_INVOICE_STATE,
        isFetching: false,
      });
    case types.CREATE_INVOICE_FAILURE:
      return Immutable.set(state, 'createInvoice', {
        ...DEFAULT_CREATE_INVOICE_STATE,
        isFetching: false,
        errors: payload,
      });

    case types.UPDATE_SELECTED_INVOICE:
      return Immutable.set(state, 'invoices', {
        ...state.invoices,
        data: state.invoices.data.map(invoice => ({
          ...invoice,
          selected: invoice.uuid === payload,
        })),
      });
    default:
      return state;
  }
};
