import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import SidebarLink from './SidebarLink';
import { tosAgreed } from '../../../../users/selectors';

class SidebarContent extends Component {
  hideSidebar = () => {
    this.props.onClick();
  };

  render() {
    const { isSdkInstructionsVisible } = this.props;

    return (
      <div className="sidebar__content">
        <ul className="sidebar__block">
          <SidebarLink
            title="Dashboard"
            route="/dashboard"
            onClick={this.hideSidebar}
          />
          <SidebarLink title="SDK" route="/sdk" onClick={this.hideSidebar} />
          <SidebarLink
            title="Pricing"
            route="/pricing"
            onClick={this.hideSidebar}
          />
          <SidebarLink
            title="Billing"
            route="/billing"
            onClick={this.hideSidebar}
          />
          {isSdkInstructionsVisible && (
            <SidebarLink
              title="SDK Integration instructions"
              route="/instructions"
              onClick={this.hideSidebar}
            />
          )}
          <SidebarLink
            title="Statistics"
            route="/statistics"
            onClick={this.hideSidebar}
          />
          <SidebarLink title="FAQ" route="/faq" onClick={this.hideSidebar} />
        </ul>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  isSdkInstructionsVisible: tosAgreed(state),
});

SidebarContent.propTypes = {
  onClick: PropTypes.func.isRequired,
  isSdkInstructionsVisible: PropTypes.bool.isRequired,
};

export default connect(mapStateToProps)(SidebarContent);
