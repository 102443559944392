import { connect } from 'react-redux';

import PayoutCard from './PayoutCard';
import billing from '../../billing';

const title = 'Total Earned';
const isTotal = true;

const mapStateToProps = state => ({
  title,
  label: `$${billing.selectors.getPaidAmount(state).toLocaleString()}`,
  isTotal,
});

export default connect(mapStateToProps)(PayoutCard);
