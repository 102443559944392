import React from 'react';
import PropTypes from 'prop-types';
import ChevronLeftIcon from 'mdi-react/ChevronLeftIcon';

import logo from '../img/logo/logo.svg';
import noAds from '../img/login/no-ads.svg';
import noShadyPermissions from '../img/login/no-shady-permissions.svg';
import noDataCollection from '../img/login/no-data-collection.svg';

const LoginWrapper = ({ children }) => (
  <div className="account">
    <div className="header">
      <img src={logo} alt="globalhop" />
      <a href="https://globalhop.net/" rel="noopener noreferrer">
        <ChevronLeftIcon size="28" /> Back to homepage
      </a>
    </div>
    <div className="account__wrapper">
      <div id="mobile-bg" className="overlay">
        <div className="info-text">
          <p>
            The most seamless <span>app monetization</span> solution{' '}
            <span>for developers</span> is now here.
          </p>
        </div>
      </div>
      <div id="show-bg" className="overlay">
        <div className="info-text">
          <p>
            The most seamless <span>app monetization</span> solution{' '}
            <span>for developers</span> is now here.
          </p>
        </div>
        <div className="info-img-container">
          <div className="info-img">
            <img src={noAds} alt="" />
            <p>No in-app ads or UX changes</p>
          </div>
          <div className="info-img">
            <img className="shady-img" src={noShadyPermissions} alt="" />
            <p>No shady permission requests</p>
          </div>
          <div className="info-img">
            <img src={noDataCollection} alt="" />
            <p>No private user data collection</p>
          </div>
        </div>
      </div>
      {children}
    </div>
  </div>
);

LoginWrapper.propTypes = {
  children: PropTypes.node.isRequired,
};

export default LoginWrapper;
