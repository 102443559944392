import React from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { compose } from 'recompose';
import { hot } from 'react-hot-loader';
import { withRouter } from 'react-router';

import Topbar from './Topbar';
import Sidebar from './Sidebar';
import ui from '../../../ui';

const Layout = props => {
  const {
    sidebar,
    changeSidebarVisibility,
    changeMobileSidebarVisibility,
  } = props;
  return (
    <div>
      <Topbar
        changeMobileSidebarVisibility={changeMobileSidebarVisibility}
        changeSidebarVisibility={changeSidebarVisibility}
      />
      <Sidebar
        sidebar={sidebar}
        changeMobileSidebarVisibility={changeMobileSidebarVisibility}
      />
    </div>
  );
};

Layout.propTypes = {
  changeSidebarVisibility: PropTypes.func.isRequired,
  changeMobileSidebarVisibility: PropTypes.func.isRequired,
  sidebar: PropTypes.shape({}).isRequired,
};

const enhance = compose(
  withRouter,
  connect(
    state => ({
      sidebar: ui.selectors.getSidebar(state),
    }),
    dispatch => ({
      dispatch,
      changeSidebarVisibility: bindActionCreators(
        ui.actions.changeSidebarVisibility,
        dispatch
      ),
      changeMobileSidebarVisibility: bindActionCreators(
        ui.actions.changeMobileSidebarVisibility,
        dispatch
      ),
    })
  ),
  hot(module)
);

export default enhance(Layout);
