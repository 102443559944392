import React, { PureComponent } from 'react';
import { Col, Container, Row } from 'reactstrap';
import PropTypes from 'prop-types';

import FiltersForm from './components/FiltersForm';
import DailyActiveUsersChart from '../../components/Charts/DailyActiveUsersChart';
import DailyActiveUsersPerTierChart from '../../components/Charts/DailyActiveUsersPerTierChart';
import DailyActiveUsersPerAppChart from '../../components/Charts/DailyActiveUsersPerAppChart';
import DailyEarningsPerTierChart from '../../components/Charts/DailyEarningsPerTierChart';
import DailyEarningsChartContainer from '../../components/Charts/DailyEarningsChart/DailyEarningsChartContainer';
import MapContainer from './components/MapContainer';
import DetailedCountryStatisticsTable from './components/CountryStatisticsTable/Container';

class Statistics extends PureComponent {
  componentDidMount() {
    this.props.onMount();
  }

  render() {
    return (
      <Container className="statistics">
        <Row>
          <Col md={12}>
            <h3 className="page-title">Statistics</h3>
          </Col>
        </Row>
        <Row>
          <Col md={12}>
            <FiltersForm />
          </Col>
        </Row>
        <MapContainer />
        <Row>
          <Col md={12}>
            <DailyActiveUsersChart />
          </Col>
        </Row>
        <Row>
          <Col md={12}>
            <DailyActiveUsersPerTierChart />
          </Col>
        </Row>
        <Row>
          <Col md={12}>
            <DailyActiveUsersPerAppChart />
          </Col>
        </Row>
        <Row>
          <Col md={12}>
            <DailyEarningsPerTierChart />
          </Col>
        </Row>
        <Row>
          <Col md={12}>
            <DetailedCountryStatisticsTable />
          </Col>
        </Row>
        <Row>
          <Col md={12}>
            <DailyEarningsChartContainer />
          </Col>
        </Row>
      </Container>
    );
  }
}

Statistics.propTypes = {
  onMount: PropTypes.func.isRequired,
};

export default Statistics;
