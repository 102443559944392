import React from 'react';
import { connect } from 'react-redux';

import { isWindowsInstructionsVisible } from '../../../ui/selectors';
import hideable from '../../utils/hideable';

const WindowsInstructions = () => (
  <div>
    <h2>Installing the Windows Globalhop SDK</h2>
    <p className="note">
      Integration using Visual Studio. Assuming SDK assembly is called MySdk.dll
    </p>
    <ol>
      <li className="point">Add SDK assembly to your project</li>
      <ul>
        <li className="subpoint">
          Right click on References in the project you want to integrate with
        </li>
        <li className="subpoint">Choose Add reference</li>
        <li className="subpoint">Choose Browse on the left</li>
        <li className="subpoint">Then Browse on the bottom right</li>
        <li className="subpoint">
          Choose <span className="inline-code">mysdk.dll</span>
        </li>
      </ul>
      <li className="point">Add SDK dependencies to your project</li>
      <ul>
        <li className="subpoint">
          Right click on the project you want to integrate with
        </li>
        <li className="subpoint">Choose Manage NuGet Packages</li>
        <li className="subpoint">Select Version 1.7.3.4 and Install</li>
        <li className="subpoint">
          In the Browse tab again search for WebSocket4Net
        </li>
        <li className="subpoint">Select Version 0.15.2 and Install</li>
      </ul>
      <li className="point">Use SDK in your project</li>
      <ul>
        <li className="subpoint">
          Its best to start the SDK on application startup.
        </li>
        <li className="subpoint">
          In your code to start the SDK call:{' '}
          <span className="inline-code">MySdk.Handler.Start();</span>
        </li>
        <li className="subpoint">
          You can also log errors to standard output by starting the SDK with a
          call: <span className="inline-code">MySdk.Handler.Start(true);</span>
        </li>
        <li className="subpoint">
          In your code to stop the SDK call:{' '}
          <span className="inline-code">MySdk.Handler.Stop();</span>
        </li>
      </ul>
      <li className="point">Distribute SDK with your application</li>
      <p className="note">
        In your projects build output directory there should be at least the
        following assemblies:
      </p>
      <ul>
        <li className="subpoint">MySdk.dll;</li>
        <li className="subpoint">MessagePack.dll</li>
        <li className="subpoint">SuperSocket.ClientEngine.dll</li>
        <li className="subpoint">WebSocket4Net.dll</li>
        <li className="subpoint">
          Depending on your targeted .Net version there could be several System
          assemblies.
        </li>
      </ul>
      <p className="note">
        To ensure proper usage of the SDK all those assemblies must be delivered
        to end user’s system.
      </p>
    </ol>
  </div>
);

const mapStateToProps = state => ({
  isVisible: isWindowsInstructionsVisible(state),
});

export default connect(mapStateToProps)(hideable(WindowsInstructions));
