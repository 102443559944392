import React from 'react';
import PropTypes from 'prop-types';
import { Card, CardBody } from 'reactstrap';

import MaterialTable from './Table';

const InvoicesTable = ({ data, rowsPerPage, onRowClick, cols, title }) => (
  <Card>
    <CardBody>
      {title && (
        <div className="card__title">
          <h5 className="bold-text">{title}</h5>
        </div>
      )}
      <MaterialTable
        paginationEnable
        data={data}
        cols={cols}
        order="desc"
        orderBy="date"
        rowsPerPage={rowsPerPage}
        onRowClick={row => {
          onRowClick(row);
        }}
      />
    </CardBody>
  </Card>
);

InvoicesTable.defaultProps = {
  onRowClick: () => {},
};

InvoicesTable.propTypes = {
  title: PropTypes.string.isRequired,
  data: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  cols: PropTypes.func.isRequired,
  onRowClick: PropTypes.func.isRequired,
  rowsPerPage: PropTypes.number.isRequired,
};

export default InvoicesTable;
