import React from 'react';
import { connect } from 'react-redux';
import { Redirect } from 'react-router-dom';
import { compose } from 'recompose';
import { bindActionCreators } from 'redux';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router';

import auth from '../../auth';

export const redirectIfAuthenticated = state => {
  const token = sessionStorage.getItem('token');
  if (token !== null) {
    const data = {
      token,
      username: sessionStorage.getItem('username'),
      email: sessionStorage.getItem('email'),
      is_superuser: sessionStorage.getItem('is_superuser') === 'true',
      uuid: sessionStorage.getItem('uuid'),
      min_payout_amount: parseInt(
        sessionStorage.getItem('min_payout_amount'),
        10
      ),
      min_payout_days: parseInt(sessionStorage.getItem('min_payout_days'), 10),
    };
    state.dispatch(auth.actions.authValidated(data));
  }
};

export default function requireAuth(Component) {
  class AuthComponent extends React.Component {
    componentDidMount() {
      this.verifyAuth();
    }

    componentDidUpdate() {
      this.verifyAuth();
    }

    verifyAuth() {
      const { isAuthenticated, verifyToken } = this.props;

      if (isAuthenticated) {
        const data = JSON.stringify({
          token: sessionStorage.getItem('token'),
        });
        verifyToken(data);
      }
    }

    sessionExists() {
      const token = sessionStorage.getItem('token');
      const username = sessionStorage.getItem('username');
      return token && username;
    }

    render() {
      const { isAuthenticated } = this.props;
      return isAuthenticated || this.sessionExists() ? (
        <Component {...this.props} />
      ) : (
        <Redirect to={'/'} />
      );
    }
  }

  AuthComponent.propTypes = {
    isAuthenticated: PropTypes.bool.isRequired,
    verifyToken: PropTypes.func.isRequired,
  };

  const enhance = compose(
    withRouter,
    connect(
      state => ({
        isAuthenticated: auth.selectors.isAuthenticated(state),
      }),
      dispatch => ({
        verifyToken: bindActionCreators(auth.actions.verifyToken, dispatch),
      })
    )
  );

  return enhance(AuthComponent);
}
