import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import idx from 'idx';
import { connect } from 'react-redux';
import chroma from 'chroma-js';

import stats from '../../../stats';
import Chart from './Chart';

const DARK_BLUE = '#572ffd';
const LIGHT_BLUE = '#ebe6ff';

class DailyEarningsPerTierChart extends PureComponent {
  componentDidMount() {
    const { isFetching, fetchData, filters, overrideFilters } = this.props;
    if (!isFetching) {
      fetchData(overrideFilters || filters);
    }
  }

  componentDidUpdate(prevProps) {
    const { fetchData, filters, overrideFilters } = this.props;
    if (overrideFilters === null && filters !== prevProps.filters) {
      fetchData(filters);
    }
  }

  getTiers() {
    const tiers = [];
    this.props.data.forEach(item => {
      item.values.forEach(tier => {
        if (!tiers.includes(tier.tier_name)) {
          tiers.push(tier.tier_name);
        }
      });
    });

    return tiers;
  }

  getLines(tiers) {
    return tiers.map((tierValue, index) => ({
      name: `${tierValue} (USD)`,
      type: 'monotone',
      dataKey: dataEntry =>
        idx(dataEntry, p =>
          p.values
            .find(obj => obj.tier_name === tierValue)
            .daily_earnings.toFixed(2)
        ) || 0,
      fillOpacity: 0.8,
      stackId: 'stacked',
      fill: chroma
        .scale([DARK_BLUE, LIGHT_BLUE])
        .domain([0, tiers.length])(index)
        .hex(),
    }));
  }

  render() {
    const tiers = this.getTiers();
    return (
      <Chart
        type={'bar'}
        title={'Daily Earnings Per Tier'}
        data={this.props.data}
        XAxisDataKey={'date'}
        lines={this.getLines(tiers)}
        isFetching={this.props.isFetching}
      />
    );
  }
}

DailyEarningsPerTierChart.propTypes = {
  overrideFilters: PropTypes.shape(),

  data: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  isFetching: PropTypes.bool.isRequired,
  filters: PropTypes.shape().isRequired,
  fetchData: PropTypes.func.isRequired,
};

DailyEarningsPerTierChart.defaultProps = {
  overrideFilters: null,
};

const enhance = connect(
  state => ({
    data: stats.selectors.getFormatedDailyEarningsPerTierData(state),
    isFetching: stats.selectors.isDailyEarningsPerTierFetching(state),
    filters: stats.selectors.getFilters(state),
  }),
  dispatch => ({
    fetchData: filter =>
      dispatch(stats.actions.fetchDailyEarningsPerTier(filter)),
  })
);

export default enhance(DailyEarningsPerTierChart);
