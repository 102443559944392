import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import Panel from './Panel';

const PayoutCard = ({ title, label, isUnpaid, isTotal, isPending, isPaid }) => (
  <Panel titleElementVisible={false}>
    <div className="dashboard__stat--budget stat-card">
      <div className="dashboard__stat-main">
        <p className="dashboard__stat-main-title">{title}</p>
        <p
          className={classNames('dashboard__stat-main-number', {
            'current-balance': isUnpaid,
            'total-balance': isTotal,
            pending: isPending,
            paid: isPaid,
          })}
        >
          {label}
        </p>
      </div>
    </div>
  </Panel>
);

PayoutCard.defaultProps = {
  isUnpaid: false,
  isPaid: false,
  isPending: false,
  isTotal: false,
};

PayoutCard.propTypes = {
  title: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  isUnpaid: PropTypes.bool,
  isTotal: PropTypes.bool,
  isPaid: PropTypes.bool,
  isPending: PropTypes.bool,
};

export default PayoutCard;
