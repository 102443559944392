import React, { PureComponent } from 'react';
import { Field, reduxForm } from 'redux-form';
import PropTypes from 'prop-types';
import { ButtonToolbar } from 'reactstrap';
import { bindActionCreators } from 'redux';
import { compose } from 'recompose';
import { connect } from 'react-redux';

import users from '../../../../users';
import Action from '../../../components/Form/Actions/Action';
import renderTextField from '../../../components/Form/TextField';
import renderRadioButtonField from '../../../components/Form/RadioButton';
import Loader from '../../../components/Loader';
import {
  required,
  minLength2,
  minLength6,
  alphaNumeric,
  email,
} from '../../../utils/validators';

class PaymentInfoForm extends PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      paymentType: props.initialValues.payment_type || 'paypal',
    };
  }

  componentDidMount() {
    const { fetchPaymentInfo, isPaymentInfoFetching } = this.props;
    if (!isPaymentInfoFetching) {
      fetchPaymentInfo();
    }
  }

  componentDidUpdate(prevProps) {
    const {
      fetchPaymentInfo,
      isPaymentInfoFetching,
      initialValues,
      submitSucceeded,
    } = this.props;

    if (!isPaymentInfoFetching && submitSucceeded) {
      fetchPaymentInfo();
    }
    if (
      initialValues.payment_type &&
      initialValues.payment_type !== prevProps.initialValues.payment_type
    ) {
      this.setState({
        paymentType: initialValues.payment_type,
      });
    }
  }

  updatePaymentType(val) {
    this.setState({
      paymentType: val,
    });
  }

  updatePaymentInfo(values) {
    const { updatePaymentInfo, isPaymentInfoFetching } = this.props;
    if (!isPaymentInfoFetching) {
      updatePaymentInfo(JSON.stringify(values));
    }
  }

  renderOptions() {
    return (
      <div className="form__form-group-field">
        <Field
          name="payment_type"
          component={renderRadioButtonField}
          label="Paypal"
          className="colored-click"
          radioValue="paypal"
          value={this.state.paymentType}
          defaultChecked={this.state.paymentType === 'paypal'}
          onChange={(e, val) => this.updatePaymentType(val)}
        />
        <Field
          name="payment_type"
          component={renderRadioButtonField}
          label="Wire"
          className="colored-click"
          radioValue="wire"
          value={this.state.paymentType}
          defaultChecked={this.state.paymentType === 'wire'}
          onChange={(e, val) => this.updatePaymentType(val)}
        />
      </div>
    );
  }

  renderPaypalFields() {
    return (
      <div>
        <div>
          <Field
            className="material-form__field"
            name="paypal_account"
            label="Account"
            component={renderTextField}
            placeholder="Enter Paypal Account"
            type="text"
            validate={[required, email, minLength2]}
          />
        </div>
      </div>
    );
  }

  renderWireFields() {
    return (
      <div>
        <div>
          <Field
            className="material-form__field"
            name="wire_account"
            label="Account"
            component={renderTextField}
            placeholder="Enter Wire Account"
            type="text"
            validate={[required, alphaNumeric, minLength2]}
          />
        </div>
        <div>
          <Field
            className="material-form__field"
            name="bank"
            label="Bank"
            component={renderTextField}
            placeholder="Enter Bank"
            type="text"
            validate={[required, minLength2]}
          />
        </div>
        <div>
          <Field
            className="material-form__field"
            name="swift_code"
            label="Swift Code"
            component={renderTextField}
            placeholder="Enter Swift Code"
            type="text"
            validate={[required, alphaNumeric, minLength6]}
          />
        </div>
      </div>
    );
  }

  render() {
    const { handleSubmit, isPaymentInfoFetching } = this.props;

    return (
      <form
        className="material-form"
        onSubmit={handleSubmit(values => this.updatePaymentInfo(values))}
      >
        <Loader isLoading={isPaymentInfoFetching} />
        <div className="form__form-group-list">
          <div className="form__form-group">{this.renderOptions()}</div>
        </div>
        <div>
          {this.state.paymentType === 'paypal'
            ? this.renderPaypalFields()
            : this.renderWireFields()}
        </div>
        <ButtonToolbar className="form">
          <Action type="submit" content="Update" className="btn-success" />
        </ButtonToolbar>
      </form>
    );
  }
}

PaymentInfoForm.propTypes = {
  initialValues: PropTypes.shape({ payment_type: PropTypes.string }).isRequired,
  submitSucceeded: PropTypes.bool.isRequired,
  isPaymentInfoFetching: PropTypes.bool.isRequired,
  updatePaymentInfo: PropTypes.func.isRequired,
  fetchPaymentInfo: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,
};

const enhance = compose(
  connect(
    state => ({
      initialValues: users.selectors.getPaymentInfoData(state),
      isPaymentInfoFetching: users.selectors.isPaymentInfoFetching(state),
    }),
    dispatch => ({
      dispatch,
      updatePaymentInfo: bindActionCreators(
        users.actions.updatePaymentInfo,
        dispatch
      ),
      fetchPaymentInfo: bindActionCreators(
        users.actions.fetchPaymentInfo,
        dispatch
      ),
    })
  ),
  reduxForm({ form: 'profile_payments_form', enableReinitialize: true })
);

export default enhance(PaymentInfoForm);
