import React from 'react';
import PropTypes from 'prop-types';
import { Card, CardBody, Container, Col, Row } from 'reactstrap';
import { connect } from 'react-redux';

import billing from '../../../../billing';
import PayoutsTable from './PayoutsTable';

const Invoice = ({
  amount,
  invoiceId,
  invoiceDate,
  legalInfo,
  paymentInfo,
  companyInfo,
}) => (
  <Container className="invoice">
    <Row>
      <Col md={12}>
        <Card>
          <CardBody>
            <p>
              <strong>Date: {invoiceDate}</strong>
            </p>
            <p>
              <strong>ID: {invoiceId}</strong>
            </p>
          </CardBody>
        </Card>
      </Col>
    </Row>
    <hr />
    <Row>
      <Col md={12}>
        <Card>
          <CardBody>
            <Row>
              <Col md={6} className="info-block">
                <div className="card__title">
                  <h6 className="bold-text">Issuer</h6>
                </div>
                <ul>
                  {legalInfo.map((value, index) => (
                    <li key={index}>{value.field_value}</li>
                  ))}
                  {paymentInfo.map((value, index) => (
                    <li key={index}>{value.field_value}</li>
                  ))}
                </ul>
              </Col>
              <Col md={6} className="info-block">
                <div className="card__title">
                  <h6 className="bold-text">To</h6>
                </div>
                <ul>
                  {companyInfo.map((value, index) => (
                    <li key={index}>{value.field_value}</li>
                  ))}
                </ul>
              </Col>
            </Row>
          </CardBody>
        </Card>
      </Col>
    </Row>
    <hr />
    <Row>
      <Col md={12}>
        <Card>
          <CardBody>
            <div className="card__title">
              <h5 className="bold-text">Details</h5>
            </div>
            <PayoutsTable />
            <h4 className="total-payout mt-2">
              <strong>Total: ${amount}</strong>
            </h4>
          </CardBody>
        </Card>
      </Col>
    </Row>
  </Container>
);

Invoice.propTypes = {
  amount: PropTypes.string.isRequired,
  invoiceId: PropTypes.string.isRequired,
  invoiceDate: PropTypes.string.isRequired,
  legalInfo: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  paymentInfo: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  companyInfo: PropTypes.arrayOf(PropTypes.shape()).isRequired,
};

const mapStateToProps = state => ({
  amount: billing.selectors.getSelectedInvoiceAmount(state),
  invoiceId: billing.selectors.getSelectedInvoiceId(state),
  invoiceDate: billing.selectors.getSelectedInvoiceDate(state),
  legalInfo: billing.selectors.getSelectedInvoiceUserLegalInfo(state),
  paymentInfo: billing.selectors.getSelectedInvoiceUserPaymentInfo(state),
  companyInfo: billing.selectors.getSelectedInvoiceComponyInfo(state),
});

export default connect(mapStateToProps)(Invoice);
