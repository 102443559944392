import React from 'react';
import { connect } from 'react-redux';

import billing from '../../../../../billing';
import InvoicesTableButton from './InvoicesTableButton';
import MaterialTableCard from '../../../../components/Table/MaterialTable';

const cols = data => [
  {
    dataKey: 'invoiceId',
    label: 'ID',
    numeric: false,
  },
  {
    dataKey: 'date',
    label: 'Request Date',
    numeric: true,
  },
  {
    dataKey: 'amount',
    label: 'Amount ($)',
    numeric: true,
  },
  {
    dataKey: 'status',
    label: 'Status',
    numeric: true,
  },
  {
    dataKey: 'paymentType',
    label: 'Payment',
    numeric: false,
  },
  {
    dataKey: 'downloadInvoice',
    label: 'Download',
    numeric: false,
    Component: <InvoicesTableButton data={data} />,
  },
];

const title = 'Invoices';
const MAX_NUMBER_ROWS_PER_PAGE = 5;

const mapStateToProps = state => {
  const rowsCount = billing.selectors.getInvoicesTableData(state).length;
  const rowsPerPage =
    rowsCount < MAX_NUMBER_ROWS_PER_PAGE ? rowsCount : MAX_NUMBER_ROWS_PER_PAGE;

  return {
    data: billing.selectors.getInvoicesTableData(state),
    paginationEnable: true,
    rowsPerPage,
    cols,
    title,
  };
};

const mapDispatchToProps = dispatch => ({
  onRowClick: ({ uuid }) =>
    dispatch(billing.actions.onInvoiceTableRowClick(uuid)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(MaterialTableCard);
