import { connect } from 'react-redux';

import stats from '../../../stats';
import Statistics from './Statistics';

const mapStateToProps = null;
const mapDispatchToProps = {
  onMount: stats.actions.onStatisticsPageMount,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Statistics);
