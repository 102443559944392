import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import ui from '../../../../ui';
import users from '../../../../users';
import Modal from '../../../components/Modal';
import RequestSDKForm from './RequestSDKForm';
import UserDetailsForm from './UserDetailsForm';
import TosAcceptForm from './TosAcceptForm';
import { TOS_MODAL, SDK_MODAL, USER_LEGAL_INFO_MODAL } from './modalNames';

const SdkModals = ({
  isSDKModalOpen,
  isUserLegalInfoModalOpen,
  isTosModalOpen,
  toggleModal,
  tosData,
}) => (
  <>
    <Modal
      color="primary"
      title="Request SDK"
      btn="Request SDK"
      message={<RequestSDKForm />}
      footer={false}
      toggle={() => toggleModal(SDK_MODAL)}
      isOpen={isSDKModalOpen}
    />
    <Modal
      color="primary"
      title="Your Details"
      message={
        <UserDetailsForm
          toggleModal={() => toggleModal(USER_LEGAL_INFO_MODAL)}
        />
      }
      footer={false}
      toggle={() => toggleModal(USER_LEGAL_INFO_MODAL)}
      isOpen={isUserLegalInfoModalOpen}
    />
    <Modal
      className="tos-modal"
      color="primary"
      title="Terms of Service"
      message={<TosAcceptForm tosData={tosData} />}
      footer={false}
      toggle={() => toggleModal(TOS_MODAL)}
      isOpen={isTosModalOpen}
    />
  </>
);

SdkModals.propTypes = {
  tosData: PropTypes.shape().isRequired,
  isSDKModalOpen: PropTypes.bool.isRequired,
  isUserLegalInfoModalOpen: PropTypes.bool.isRequired,
  isTosModalOpen: PropTypes.bool.isRequired,
  toggleModal: PropTypes.func.isRequired,
};

const mapStateToProps = state => ({
  isSDKModalOpen: ui.selectors.isSDKModalOpen(state),
  isUserLegalInfoModalOpen: ui.selectors.isUserLegalInfoModalOpen(state),
  isTosModalOpen: ui.selectors.isTosModalOpen(state),
  tosData: users.selectors.getTosData(state),
});

const mapDispatchToProps = dispatch => ({
  toggleModal: modalName => dispatch(ui.actions.toggleModal(modalName)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(SdkModals);
