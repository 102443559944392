import * as types from './actionTypes';
import { callAPI } from '../api';
import { requestHeaders, buildUrlWithQueryParams } from '../api/utils';
import { actionCreator } from '../app/utils/actionCreator';
import { getFilters } from './selectors';

export const updateFilters = actionCreator(types.UPDATE_FILTERS);

export const fetchDailyActiveUsers = data =>
  callAPI({
    method: 'GET',
    endpoint: buildUrlWithQueryParams(
      '/api/v1/stats/daily-active-users/',
      data
    ),
    headers: requestHeaders(true),
    types: [
      types.FETCH_DAILY_ACTIVE_USERS_REQUEST,
      types.FETCH_DAILY_ACTIVE_USERS_SUCCESS,
      types.FETCH_DAILY_ACTIVE_USERS_FAILURE,
    ],
  });

export const fetchDailyActiveUsersPerTier = data =>
  callAPI({
    method: 'GET',
    endpoint: buildUrlWithQueryParams(
      '/api/v1/stats/daily-active-users/tier/',
      data
    ),
    headers: requestHeaders(true),
    types: [
      types.FETCH_DAILY_ACTIVE_USERS_PER_TIER_REQUEST,
      types.FETCH_DAILY_ACTIVE_USERS_PER_TIER_SUCCESS,
      types.FETCH_DAILY_ACTIVE_USERS_PER_TIER_FAILURE,
    ],
  });

export const fetchDailyActiveUsersPerApp = data =>
  callAPI({
    method: 'GET',
    endpoint: buildUrlWithQueryParams(
      '/api/v1/stats/daily-active-users/app/',
      data
    ),
    headers: requestHeaders(true),
    types: [
      types.FETCH_DAILY_ACTIVE_USERS_PER_APP_REQUEST,
      types.FETCH_DAILY_ACTIVE_USERS_PER_APP_SUCCESS,
      types.FETCH_DAILY_ACTIVE_USERS_PER_APP_FAILURE,
    ],
  });

export const fetchDailyActiveUsersPerCountry = data =>
  callAPI({
    method: 'GET',
    endpoint: buildUrlWithQueryParams(
      '/api/v1/stats/daily-active-users/country/',
      data
    ),
    headers: requestHeaders(true),
    types: [
      types.FETCH_DAILY_ACTIVE_USERS_PER_COUNTRY_REQUEST,
      types.FETCH_DAILY_ACTIVE_USERS_PER_COUNTRY_SUCCESS,
      types.FETCH_DAILY_ACTIVE_USERS_PER_COUNTRY_FAILURE,
    ],
  });

export const fetchDailyEarnings = data =>
  callAPI({
    method: 'GET',
    endpoint: buildUrlWithQueryParams('/api/v1/stats/daily-earnings/', data),
    headers: requestHeaders(true),
    types: [
      types.FETCH_DAILY_EARNINGS_REQUEST,
      types.FETCH_DAILY_EARNINGS_SUCCESS,
      types.FETCH_DAILY_EARNINGS_FAILURE,
    ],
  });

export const fetchDailyEarningsPerTier = data =>
  callAPI({
    method: 'GET',
    endpoint: buildUrlWithQueryParams(
      '/api/v1/stats/daily-earnings/tier/',
      data
    ),
    headers: requestHeaders(true),
    types: [
      types.FETCH_DAILY_EARNINGS_PER_TIER_REQUEST,
      types.FETCH_DAILY_EARNINGS_PER_TIER_SUCCESS,
      types.FETCH_DAILY_EARNINGS_PER_TIER_FAILURE,
    ],
  });

export const fetchAllUsers = () =>
  callAPI({
    method: 'GET',
    endpoint: '/api/v1/users/?tos_accepted=1',
    headers: requestHeaders(true),
    types: [
      types.FETCH_ALL_USERS_REQUEST,
      types.FETCH_ALL_USERS_SUCCESS,
      types.FETCH_ALL_USERS_FAILURE,
    ],
  });

export const fetchTierPricePerCountry = () =>
  callAPI({
    method: 'GET',
    endpoint: '/api/v1/apps/tier-price-per-country/',
    headers: requestHeaders(true),
    types: [
      types.FETCH_TIER_PRICE_PER_COUNTRY_REQUEST,
      types.FETCH_TIER_PRICE_PER_COUNTRY_SUCCESS,
      types.FETCH_TIER_PRICE_PER_COUNTRY_FAILURE,
    ],
  });

export const onFilterFormSubmit = data => dispatch => {
  dispatch(updateFilters(data));

  dispatch(fetchDailyActiveUsersPerCountry(data));
};

export const onStatisticsPageMount = () => (dispatch, getState) => {
  const state = getState();
  const filters = getFilters(state);
  dispatch(fetchDailyActiveUsersPerCountry(filters));
};
