export const NAME = 'auth';

export const EMAIL_CONFIRMATION_EMAIL = '/api/v1/users/confirmation_token/';

export const LOGIN = 'login';
export const REGISTER = 'register';

export const TOKEN_EXPIRED_CODE = 1001;

export const INVALID_CREDENTIAL_ERROR = 'Invalid login credentials.';
export const SESSION_EXPIRED_ERROR =
  'Your session has expired, please login to continue.';
