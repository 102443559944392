import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Button } from 'reactstrap';

import apps from '../../../../../apps';

const PAID_STATUS = 'paid';

const handleClick = (e, downloadSdk, url) => {
  e.stopPropagation();
  downloadSdk(url);
};

const InvoicesTableButton = ({ data, isDisabled, downloadPdf }) => (
  <Button
    color="primary"
    style={{ marginBottom: '0' }}
    disabled={isDisabled}
    onClick={e => handleClick(e, downloadPdf, data.pdf)}
  >
    Download
  </Button>
);

InvoicesTableButton.propTypes = {
  data: PropTypes.shape({}),
  downloadPdf: PropTypes.func.isRequired,
  isDisabled: PropTypes.bool.isRequired,
};

InvoicesTableButton.defaultProps = {
  data: {},
};

const mapStateToProps = (state, { data: { status } }) => ({
  isDisabled: status !== PAID_STATUS,
});

const mapDispatchToProps = dispatch => ({
  downloadPdf: url => dispatch(apps.actions.downloadInvoicePdf(url)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(InvoicesTableButton);
