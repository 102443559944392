import React, { PureComponent } from 'react';
import DatePicker from 'react-datepicker';
import MinusIcon from 'mdi-react/MinusIcon';
import PropTypes from 'prop-types';
import moment from 'moment';

import { capitalizeFirstLetter } from '../../utils/stringOperations';

class IntervalDatePickerField extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      startDate: moment().subtract(30, 'days'),
      endDate: moment().subtract(1, 'days'),
    };
    this.handleChange = this.handleChange.bind(this);
  }

  handleChange({ startDate, endDate }) {
    const newStartDate = startDate || this.state.startDate;
    let newEndDate = endDate || this.state.endDate;

    if (newStartDate !== null && newStartDate.isAfter(newEndDate)) {
      newEndDate = newStartDate;
    }
    if (newEndDate !== null && newEndDate.isAfter(this.props.maxDate)) {
      newEndDate = this.props.maxDate;
    }
    this.setState({
      startDate: newStartDate,
      endDate: newEndDate,
    });
    this.props.onChange({ start: newStartDate, end: newEndDate });
  }

  handleChangeStart = startDate => this.handleChange({ startDate });

  handleChangeEnd = endDate => this.handleChange({ endDate });

  render() {
    return (
      <div className="date-picker date-picker--interval">
        <DatePicker
          selected={this.state.startDate}
          selectsStart
          startDate={this.state.startDate}
          endDate={this.state.endDate}
          onChange={this.handleChangeStart}
          dateFormat="YYYY-MM-DD"
          placeholderText="From"
        />
        <MinusIcon className="date-picker__svg" />
        <DatePicker
          selected={this.state.endDate}
          selectsEnd
          startDate={this.state.startDate}
          endDate={this.state.endDate}
          onChange={this.handleChangeEnd}
          dateFormat="YYYY-MM-DD"
          placeholderText="To"
          maxDate={this.props.maxDate}
        />
      </div>
    );
  }
}

IntervalDatePickerField.defaultProps = {
  maxDate: null,
};

IntervalDatePickerField.propTypes = {
  onChange: PropTypes.func.isRequired,
  maxDate: PropTypes.instanceOf(moment),
};

const renderIntervalDatePickerField = props => {
  const { meta } = props;
  const { touched, error } = meta;
  return (
    <div className="custom-input-wrapper">
      <IntervalDatePickerField {...props.input} maxDate={props.maxDate} />
      {touched &&
        (error && (
          <span className="field-error">{capitalizeFirstLetter(error)}</span>
        ))}
    </div>
  );
};

renderIntervalDatePickerField.propTypes = {
  input: PropTypes.shape({
    onChange: PropTypes.func,
  }).isRequired,
  maxDate: PropTypes.instanceOf(moment),
};

renderIntervalDatePickerField.defaultProps = {
  maxDate: null,
};

export default renderIntervalDatePickerField;
