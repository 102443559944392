import Immutable from 'seamless-immutable';
import moment from 'moment';

import * as types from './actionTypes';

const DEFAULT_FILTERS_STATE = {
  data: {
    date_from: moment()
      .subtract(30, 'days')
      .format('YYYY-MM-DD'),
    date_to: moment()
      .subtract(1, 'days')
      .format('YYYY-MM-DD'),
    app_uuid: null,
    country_code: null,
  },
};

const DEFAULT_DAILY_ACTIVE_USERS_STATE = {
  data: [],
  isFetching: false,
  isFetched: false,
};

const DEFAULT_DAILY_ACTIVE_USERS_PER_TIER_STATE = {
  data: [],
  isFetching: false,
  isFetched: false,
};

const DEFAULT_DAILY_ACTIVE_USERS_PER_APP_STATE = {
  data: [],
  isFetching: false,
  isFetched: false,
};

const DEFAULT_DAILY_ACTIVE_USERS_PER_COUNTRY_STATE = {
  data: [],
  isFetching: false,
  isFetched: false,
};

const DEFAULT_DAILY_EARNINGS_STATE = {
  data: [],
  isFetching: false,
  isFetched: false,
};

const DEFAULT_DAILY_EARNINGS_PER_TIER_STATE = {
  data: [],
  isFetching: false,
  isFetched: false,
};

const DEFAULT_ALL_USERS_STATE = {
  data: [],
  isFetching: false,
  isFetched: false,
};

const DEFAULT_TIER_PRICE_PER_COUNTRY_STATE = {
  data: [],
  isFetching: false,
  isFetched: false,
};

const DEFAULT_STATE = Immutable({
  filters: DEFAULT_FILTERS_STATE,
  dailyActiveUsers: DEFAULT_DAILY_ACTIVE_USERS_STATE,
  dailyActiveUsersPerTier: DEFAULT_DAILY_ACTIVE_USERS_PER_TIER_STATE,
  dailyActiveUsersPerApp: DEFAULT_DAILY_ACTIVE_USERS_PER_APP_STATE,
  dailyActiveUsersPerCountry: DEFAULT_DAILY_ACTIVE_USERS_PER_COUNTRY_STATE,
  dailyEarnings: DEFAULT_DAILY_EARNINGS_STATE,
  dailyEarningsPerTier: DEFAULT_DAILY_EARNINGS_PER_TIER_STATE,
  allUsers: DEFAULT_ALL_USERS_STATE,
  tierPricePerCountry: DEFAULT_TIER_PRICE_PER_COUNTRY_STATE,
});

export default (state = DEFAULT_STATE, { payload, type }) => {
  switch (type) {
    case types.UPDATE_FILTERS:
      return Immutable.set(state, 'filters', {
        ...state.filters,
        data: {
          ...state.filters.data,
          ...payload,
        },
      });

    case types.FETCH_DAILY_ACTIVE_USERS_REQUEST:
      return Immutable.set(state, 'dailyActiveUsers', {
        ...DEFAULT_DAILY_ACTIVE_USERS_STATE,
        isFetching: true,
      });
    case types.FETCH_DAILY_ACTIVE_USERS_SUCCESS:
      return Immutable.set(state, 'dailyActiveUsers', {
        ...DEFAULT_DAILY_ACTIVE_USERS_STATE,
        data: payload,
        isFetching: false,
        isFetched: true,
      });
    case types.FETCH_DAILY_ACTIVE_USERS_FAILURE:
      return Immutable.set(state, 'dailyActiveUsers', {
        ...DEFAULT_DAILY_ACTIVE_USERS_STATE,
        isFetching: false,
      });

    case types.FETCH_DAILY_ACTIVE_USERS_PER_TIER_REQUEST:
      return Immutable.set(state, 'dailyActiveUsersPerTier', {
        ...DEFAULT_DAILY_ACTIVE_USERS_PER_TIER_STATE,
        isFetching: true,
      });
    case types.FETCH_DAILY_ACTIVE_USERS_PER_TIER_SUCCESS:
      return Immutable.set(state, 'dailyActiveUsersPerTier', {
        ...DEFAULT_DAILY_ACTIVE_USERS_PER_TIER_STATE,
        data: payload,
        isFetching: false,
        isFetched: true,
      });
    case types.FETCH_DAILY_ACTIVE_USERS_PER_TIER_FAILURE:
      return Immutable.set(state, 'dailyActiveUsersPerTier', {
        ...DEFAULT_DAILY_ACTIVE_USERS_PER_TIER_STATE,
        isFetching: false,
      });

    case types.FETCH_DAILY_ACTIVE_USERS_PER_APP_REQUEST:
      return Immutable.set(state, 'dailyActiveUsersPerApp', {
        ...DEFAULT_DAILY_ACTIVE_USERS_PER_APP_STATE,
        isFetching: true,
      });
    case types.FETCH_DAILY_ACTIVE_USERS_PER_APP_SUCCESS:
      return Immutable.set(state, 'dailyActiveUsersPerApp', {
        ...DEFAULT_DAILY_ACTIVE_USERS_PER_APP_STATE,
        data: payload,
        isFetching: false,
        isFetched: true,
      });
    case types.FETCH_DAILY_ACTIVE_USERS_PER_APP_FAILURE:
      return Immutable.set(state, 'dailyActiveUsersPerApp', {
        ...DEFAULT_DAILY_ACTIVE_USERS_PER_APP_STATE,
        isFetching: false,
      });

    case types.FETCH_DAILY_ACTIVE_USERS_PER_COUNTRY_REQUEST:
      return Immutable.set(state, 'dailyActiveUsersPerCountry', {
        ...DEFAULT_DAILY_ACTIVE_USERS_PER_COUNTRY_STATE,
        isFetching: true,
      });
    case types.FETCH_DAILY_ACTIVE_USERS_PER_COUNTRY_SUCCESS:
      return Immutable.set(state, 'dailyActiveUsersPerCountry', {
        ...DEFAULT_DAILY_ACTIVE_USERS_PER_COUNTRY_STATE,
        data: payload,
        isFetching: false,
        isFetched: true,
      });
    case types.FETCH_DAILY_ACTIVE_USERS_PER_COUNTRY_FAILURE:
      return Immutable.set(state, 'dailyActiveUsersPerCountry', {
        ...DEFAULT_DAILY_ACTIVE_USERS_PER_COUNTRY_STATE,
        isFetching: false,
      });

    case types.FETCH_DAILY_EARNINGS_REQUEST:
      return Immutable.set(state, 'dailyEarnings', {
        ...DEFAULT_DAILY_EARNINGS_STATE,
        isFetching: true,
      });
    case types.FETCH_DAILY_EARNINGS_SUCCESS:
      return Immutable.set(state, 'dailyEarnings', {
        ...DEFAULT_DAILY_EARNINGS_STATE,
        data: payload,
        isFetching: false,
        isFetched: true,
      });
    case types.FETCH_DAILY_EARNINGS_FAILURE:
      return Immutable.set(state, 'dailyEarnings', {
        ...DEFAULT_DAILY_EARNINGS_STATE,
        isFetching: false,
      });

    case types.FETCH_DAILY_EARNINGS_PER_TIER_REQUEST:
      return Immutable.set(state, 'dailyEarningsPerTier', {
        ...DEFAULT_DAILY_EARNINGS_PER_TIER_STATE,
        isFetching: true,
      });
    case types.FETCH_DAILY_EARNINGS_PER_TIER_SUCCESS:
      return Immutable.set(state, 'dailyEarningsPerTier', {
        ...DEFAULT_DAILY_EARNINGS_PER_TIER_STATE,
        data: payload,
        isFetching: false,
        isFetched: true,
      });
    case types.FETCH_DAILY_EARNINGS_PER_TIER_FAILURE:
      return Immutable.set(state, 'dailyEarningsPerTier', {
        ...DEFAULT_DAILY_EARNINGS_PER_TIER_STATE,
        isFetching: false,
      });

    case types.FETCH_ALL_USERS_REQUEST:
      return Immutable.set(state, 'allUsers', {
        ...DEFAULT_ALL_USERS_STATE,
        isFetching: true,
      });
    case types.FETCH_ALL_USERS_SUCCESS:
      return Immutable.set(state, 'allUsers', {
        ...DEFAULT_ALL_USERS_STATE,
        data: payload,
        isFetching: false,
        isFetched: true,
      });
    case types.FETCH_ALL_USERS_FAILURE:
      return Immutable.set(state, 'allUsers', {
        ...DEFAULT_ALL_USERS_STATE,
        isFetching: false,
      });

    case types.FETCH_TIER_PRICE_PER_COUNTRY_REQUEST:
      return Immutable.set(state, 'tierPricePerCountry', {
        ...DEFAULT_TIER_PRICE_PER_COUNTRY_STATE,
        isFetching: true,
      });
    case types.FETCH_TIER_PRICE_PER_COUNTRY_SUCCESS:
      return Immutable.set(state, 'tierPricePerCountry', {
        ...DEFAULT_TIER_PRICE_PER_COUNTRY_STATE,
        data: payload,
        isFetching: false,
        isFetched: true,
      });
    case types.FETCH_TIER_PRICE_PER_COUNTRY_FAILURE:
      return Immutable.set(state, 'tierPricePerCountry', {
        ...DEFAULT_TIER_PRICE_PER_COUNTRY_STATE,
        isFetching: false,
      });

    default:
      return state;
  }
};
