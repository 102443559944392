import React from 'react';
import { Card, CardBody, Col } from 'reactstrap';
import PropTypes from 'prop-types';
import ReactTooltip from 'react-tooltip';
import {
  ComposableMap,
  ZoomableGroup,
  Geographies,
  Geography,
} from 'react-simple-maps';

import topoData from './config/maptopo';
import codes from './config/codes';
import { scaleLinear } from 'd3-scale';

const MAP_COLORS = {
  HOVER: '#572FFD',
  MIN: '#EBE6FF',
  MAX: '#1A017E',
  WHITE: '#FFFFFF'
};

let popScale;

const setColors = colorValues => {
  if (!popScale) {
    popScale = scaleLinear()
      .domain(getMinMaxValues(colorValues))
      .range([MAP_COLORS.MIN, MAP_COLORS.MAX]);
  } else {
    popScale.domain(getMinMaxValues(colorValues));
  }
}

const getMinMaxValues = values => values.length > 1 ? [Math.min(...values), Math.max(...values)] : [0, ...values];

const getColor = countryValue => popScale(countryValue);

const getCountry = (data, geography) => {
  let country = data[codes[geography.id]];

  if (country) {
    return country;
  }

  return null;
};

const Map = ({ dataIsFetching, colorValues, data, createTooltip }) => (
  <CardBody>
    {!dataIsFetching &&
      <>
        <ComposableMap
          projectionConfig={{
            scale: 110,
          }}
          height={300}
          style={{ width: "100%", height: "100%" }}
        >
          <ZoomableGroup disablePanning zoom={1} center={[0, 30]}>
            <Geographies geography={topoData}>
              {(geographies, projection) => {
                setColors(colorValues);

                return (
                  geographies.map((geography, i) => {

                    let country = getCountry(data, geography);
                    if (country === null || country.value === null) {
                      country = {
                        code: geography.properties.name,
                        colorValue: null,
                        name: geography.properties.name,
                      };
                    }

                    return <Geography
                      key={i}
                      geography={geography}
                      projection={projection}
                      data-tip={createTooltip(country)}
                      style={{
                        default: {
                          fill: country.colorValue != null ? getColor(country.colorValue) : MAP_COLORS.MIN,
                          stroke: MAP_COLORS.WHITE,
                          strokeWidth: 0.75,
                          outline: "none",
                        },
                        hover: {
                          fill: country.colorValue != null ? MAP_COLORS.HOVER : MAP_COLORS.MIN,
                          stroke: MAP_COLORS.WHITE,
                          strokeWidth: 0.75,
                          outline: "none",
                        },
                        pressed: {
                          fill: country.colorValue != null ? getColor(country.colorValue) : MAP_COLORS.MIN,
                          stroke: MAP_COLORS.WHITE,
                          strokeWidth: 0.75,
                          outline: 'none'
                        }
                      }}
                    />
                  })
                )
              }}
            </Geographies>
          </ZoomableGroup>
        </ComposableMap>
        <ReactTooltip html={true} className="map-popup" />
      </>
    }
  </CardBody>
);

Map.defaultProps = { title: '' };
Map.propTypes = {
  dataIsFetching: PropTypes.bool,
  payoutsValues: PropTypes.arrayOf(PropTypes.number),
  data: PropTypes.shape().isRequired,
  getColorValues: PropTypes.func,
};

export default Map;
