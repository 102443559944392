import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Container, Col, Row, Card, CardBody } from 'reactstrap';

import Navigation from './Navigation';
import AndroidInstructions from './AndroidInstructions';
import MacosInstructions from './MacosInstructions';
import UnityInstructions from './UnityInstructions';
import WindowsInstructions from './WindowsInstructions';
import { DASHBOARD } from '../../utils/routes';
import { tosAgreed, isTosFetching } from '../../../users/selectors';

class SdkInstructions extends PureComponent {
  componentDidMount() {
    const {
      history: { push },
      shouldRedirect,
    } = this.props;

    if (shouldRedirect) {
      push(DASHBOARD);
    }
  }

  render() {
    return (
      <Container className="sdk-instructions">
        <Row>
          <Col md={12}>
            <h3 className="page-title">SDK Integration instructions</h3>
          </Col>
        </Row>
        <Row className="page-container">
          <Col md={2}>
            <Navigation />
          </Col>
          <Col md={10}>
            <Card>
              <CardBody className="instructions-container">
                <AndroidInstructions />
                <MacosInstructions />
                <UnityInstructions />
                <WindowsInstructions />
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    );
  }
}

SdkInstructions.propTypes = {
  shouldRedirect: PropTypes.bool.isRequired,
  history: PropTypes.shape({
    push: PropTypes.func.isRequired,
  }).isRequired,
};

const mapStateToProps = state => ({
  shouldRedirect: !tosAgreed(state) && !isTosFetching(state),
});

export default connect(mapStateToProps)(SdkInstructions);
