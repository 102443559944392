import React from 'react';
import PropTypes from 'prop-types';
import ReactCountryFlag from 'react-country-flag';

const FlagCell = ({ countryCode, countryName }) => (
  <div>
    <ReactCountryFlag code={countryCode} svg />
    <span className="p-lg-2">{countryName}</span>
  </div>
);

FlagCell.propTypes = {
  countryCode: PropTypes.string.isRequired,
  countryName: PropTypes.string.isRequired,
};

export default FlagCell;
