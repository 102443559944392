import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import androidGrey from '../../img/sdk/android_1.svg';
import unityGrey from '../../img/sdk/unity_1.svg';
import windowsGrey from '../../img/sdk/windows_1.svg';
import appleGrey from '../../img/sdk/apple_1.svg';
import androidPurple from '../../img/sdk/android_2.svg';
import unityPurple from '../../img/sdk/unity_2.svg';
import windowsPurple from '../../img/sdk/windows_2.svg';
import applePurple from '../../img/sdk/apple_2.svg';
import {
  handleAndroidInstuctionsClick,
  handleUnityInstuctionsClick,
  handleWindowsInstuctionsClick,
  handleMacosInstuctionsClick,
} from '../../../ui/actions';
import {
  isAndroidInstructionsVisible,
  isUnityInstructionsVisible,
  isWindowsInstructionsVisible,
  isMacosInstructionsVisible,
} from '../../../ui/selectors';

const Navigation = ({
  onAndroidClick,
  onUnityClick,
  onWindowsClick,
  onMacosClick,
  isAndroidActive,
  isUnityActive,
  isWindowsActive,
  isMacosActive,
}) => (
  <div className="sdk-navigation">
    <button
      onClick={onAndroidClick}
      className={classNames('navigation-button', {
        'active-nav-button': isAndroidActive,
      })}
    >
      <img src={isAndroidActive ? androidPurple : androidGrey} alt="android" />
      <p>Android</p>
    </button>
    <button
      className={classNames('navigation-button', {
        'active-nav-button': isUnityActive,
      })}
      onClick={onUnityClick}
    >
      <img src={isUnityActive ? unityPurple : unityGrey} alt="unity" />
      <p>Unity</p>
    </button>
    <button
      className={classNames('navigation-button', {
        'active-nav-button': isWindowsActive,
      })}
      onClick={onWindowsClick}
    >
      <img src={isWindowsActive ? windowsPurple : windowsGrey} alt="windows" />
      <p>Windows</p>
    </button>
    <button
      className={classNames('navigation-button', {
        'active-nav-button': isMacosActive,
      })}
      onClick={onMacosClick}
    >
      <img src={isMacosActive ? applePurple : appleGrey} alt="macOS" />
      <p>MacOS</p>
    </button>
  </div>
);

const mapStateToProps = state => ({
  isAndroidActive: isAndroidInstructionsVisible(state),
  isUnityActive: isUnityInstructionsVisible(state),
  isWindowsActive: isWindowsInstructionsVisible(state),
  isMacosActive: isMacosInstructionsVisible(state),
});

const mapDispatchToProps = {
  onAndroidClick: handleAndroidInstuctionsClick,
  onUnityClick: handleUnityInstuctionsClick,
  onWindowsClick: handleWindowsInstuctionsClick,
  onMacosClick: handleMacosInstuctionsClick,
};

Navigation.propTypes = {
  onAndroidClick: PropTypes.func.isRequired,
  onUnityClick: PropTypes.func.isRequired,
  onWindowsClick: PropTypes.func.isRequired,
  onMacosClick: PropTypes.func.isRequired,
  isAndroidActive: PropTypes.bool.isRequired,
  isUnityActive: PropTypes.bool.isRequired,
  isWindowsActive: PropTypes.bool.isRequired,
  isMacosActive: PropTypes.bool.isRequired,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Navigation);
