import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { Field, reduxForm } from 'redux-form';
import { ButtonToolbar } from 'reactstrap';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { compose } from 'recompose';

import users from '../../../../users';
import renderTextField from '../../../components/Form/TextField';
import Action from '../../../components/Form/Actions/Action';
import Loader from '../../../components/Loader';

import {
  required,
  minLength6,
  passwordsMustMatch,
} from '../../../utils/validators';

class PasswordForm extends PureComponent {
  handleSubmit(values) {
    const { updateUser, isUpdateUserFetching } = this.props;
    if (!isUpdateUserFetching) {
      const data = JSON.stringify({
        password: values.new_password,
      });
      updateUser(data);
    }
  }

  render() {
    const { handleSubmit, isUpdateUserFetching } = this.props;
    return (
      <form
        className="material-form"
        onSubmit={handleSubmit(values => this.handleSubmit(values))}
      >
        <div>
          <Loader isLoading={isUpdateUserFetching} />
          <Field
            name="new_password"
            label="New password"
            component={renderTextField}
            placeholder="Enter new password"
            type="password"
            validate={[required, minLength6]}
          />
          <Field
            name="repeat_new_password"
            label="Repeat new password"
            component={renderTextField}
            placeholder="Enter new password again"
            type="password"
            validate={[required, minLength6, passwordsMustMatch]}
          />
        </div>
        <ButtonToolbar className="form">
          <Action
            type="submit"
            content="Change Password"
            className="btn-success"
          />
        </ButtonToolbar>
      </form>
    );
  }
}

PasswordForm.propTypes = {
  handleSubmit: PropTypes.func.isRequired,

  isUpdateUserFetching: PropTypes.bool.isRequired,
  updateUser: PropTypes.func.isRequired,
};

const enhance = compose(
  connect(
    state => ({
      isUpdateUserFetching: users.selectors.isUpdateUserFetching(state),
    }),
    dispatch => ({
      dispatch,
      updateUser: bindActionCreators(users.actions.updateUser, dispatch),
    })
  ),
  reduxForm({ form: 'profile_change_password_form' })
);

export default enhance(PasswordForm);
