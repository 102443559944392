import React, { PureComponent } from 'react';
import DownIcon from 'mdi-react/ChevronDownIcon';
import PropTypes from 'prop-types';
import { Collapse } from 'reactstrap';
import TopbarMenuLink from './TopbarMenuLink';

const Ava = `${process.env.PUBLIC_URL}/img/ava.png`;

export default class TopbarProfile extends PureComponent {
  constructor() {
    super();
    this.state = {
      collapse: false,
    };
  }

  toggle = () => {
    this.setState({ collapse: !this.state.collapse });
  };

  render() {
    return (
      <div className="topbar__profile">
        <button className="topbar__avatar" onClick={this.toggle}>
          <img className="topbar__avatar-img" src={Ava} alt="avatar" />
          <p className="topbar__avatar-name">{this.props.username}</p>
          <DownIcon className="topbar__icon" />
        </button>
        {this.state.collapse && (
          <button className="topbar__back" onClick={this.toggle} />
        )}
        <Collapse isOpen={this.state.collapse} className="topbar__menu-wrap">
          <div className="topbar__menu">
            <TopbarMenuLink
              title="Profile"
              icon="cog"
              path="/profile"
              onClick={this.toggle}
            />
            <TopbarMenuLink
              title="Log Out"
              icon="exit"
              path="/"
              onClick={this.props.logout}
            />
          </div>
        </Collapse>
      </div>
    );
  }
}

TopbarProfile.propTypes = {
  username: PropTypes.string,
  logout: PropTypes.func.isRequired,
};

TopbarProfile.defaultProps = {
  username: '',
};
