import idx from 'idx';
import { createSelector } from 'reselect';

import { NAME, PAYMENT_TYPE, UNPAID, PAID, PENDING } from './constants';

export const isPayoutsFetching = state =>
  idx(state, p => p[NAME].payouts.isFetching);

const filterPayoutsByStatus = (data, status) =>
  data.filter(item => item.status === status);

const filterPayoutsByInvoiceUUID = (data, invoiceUUID) =>
  data.filter(item => item.invoice.uuid === invoiceUUID);

const getAmount = (data, status) =>
  data
    .filter(item => item.status === status)
    .reduce((sum, item) => sum + item.amount, 0);

const getCount = (data, status) =>
  data.filter(item => item.status === status).length;

export const getAllPayouts = state => idx(state, p => p[NAME].payouts.data);

export const getAllPayoutsChartData = state =>
  idx(state, p => p[NAME].payouts.chartData);

export const getAllPayoutsChartDataBrushPosition = state =>
  idx(state, p => p[NAME].payouts.chartData).length - 30;

export const getUnpaidPayouts = state =>
  filterPayoutsByStatus(idx(state, p => p[NAME].payouts.data) || [], UNPAID);

export const getInvoicePayouts = (state, invoice) =>
  filterPayoutsByInvoiceUUID(
    idx(state, p => p[NAME].payouts.data) || [],
    invoice !== undefined ? invoice.uuid : undefined
  );

export const getPaidAmount = state =>
  getAmount(idx(state, p => p[NAME].payouts.data) || [], PAID);

export const getUnpaidAmount = state =>
  getAmount(idx(state, p => p[NAME].payouts.data) || [], UNPAID);

export const getPendingAmount = state =>
  getAmount(idx(state, p => p[NAME].payouts.data) || [], PENDING);

export const getUnpaidDaysCount = state =>
  getCount(idx(state, p => p[NAME].payouts.data) || [], UNPAID);

export const isInvoicesFetching = state =>
  idx(state, p => p[NAME].invoices.isFetching);

export const getInvoicesData = state => idx(state, p => p[NAME].invoices.data);

export const isCreateInvoiceFetching = state =>
  idx(state, p => p[NAME].createInvoice.isFetching);

export const getSelectedInvoice = state => {
  const data = idx(state, p => p[NAME].invoices.data);
  return idx(data, p => p.find(item => item.selected));
};

export const getSelectedInvoiceId = createSelector(
  getSelectedInvoice,
  data => data && data.invoice_id
);

export const getSelectedInvoiceDate = createSelector(
  getSelectedInvoice,
  data => data && data.requested_at
);

export const getSelectedInvoiceUserLegalInfo = createSelector(
  getSelectedInvoice,
  data => data && data.user_legal_info
);

export const getSelectedInvoiceUserPaymentInfo = createSelector(
  getSelectedInvoice,
  data => data && data.user_payment_info
);

export const getSelectedInvoiceComponyInfo = createSelector(
  getSelectedInvoice,
  data => data && data.company_info
);

export const getSelectedInvoiceAmount = createSelector(
  getSelectedInvoice,
  data => data && data.amount
);

export const getInvoicesTableData = createSelector(getInvoicesData, data => {
  if (data.length) {
    return data.map(
      ({
        uuid,
        invoice_id: invoiceId,
        requested_at: requestedAt,
        amount,
        status,
        user_payment_info: userPaymentInfo,
        pdf,
      }) => ({
        uuid,
        invoiceId,
        date: requestedAt,
        amount,
        status,
        paymentType: idx(
          userPaymentInfo,
          p => p.find(item => item.field_name === PAYMENT_TYPE).field_value
        ),
        pdf,
      })
    );
  }

  return null;
});
