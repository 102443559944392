import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import auth from '../../../../auth';
import time from '../../../img/login/time.svg';
import hideable from '../../../utils/hideable';

const ConfirmationExpired = ({ email, onClick, isButtonDisabled }) => (
  <div className="confirmation-info">
    <img src={time} alt="" />
    <h3>Confirmation link expired</h3>
    <p>
      The confirmation link we have sent to
      <span className="email"> {email}</span> has already expired. Click on the
      <span className="marked"> button below</span> to get a new one.
    </p>
    <button
      disabled={isButtonDisabled}
      onClick={onClick}
      className="btn btn-primary account__btn"
    >
      Resend confirmation Link
    </button>
  </div>
);

ConfirmationExpired.propTypes = {
  email: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired,
  isButtonDisabled: PropTypes.bool.isRequired,
};

const mapStateToProps = state => ({
  isVisible: auth.selectors.isRegistrationTokenExpired(state),
  email: auth.selectors.getConfirmationEmail(state),
  isButtonDisabled: auth.selectors.isResendClicked(state),
});

const mapDispatchToProps = {
  onClick: auth.actions.resendConfirmation,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(hideable(ConfirmationExpired));
