import { NAME } from './constants';

export const LOGIN_REQUEST = `${NAME}.LOGIN_REQUEST`;
export const LOGIN_SUCCESS = `${NAME}.LOGIN_SUCCESS`;
export const LOGIN_FAILURE = `${NAME}.LOGIN_FAILURE`;

export const VERIFY_TOKEN_REQUEST = `${NAME}.VERIFY_TOKEN_REQUEST`;
export const VERIFY_TOKEN_SUCCESS = `${NAME}.VERIFY_TOKEN_SUCCESS`;
export const VERIFY_TOKEN_FAILURE = `${NAME}.VERIFY_TOKEN_FAILURE`;

export const CHECK_CONFIRMATION_TOKEN_REQUEST = `${NAME}.CHECK_CONFIRMATION_TOKEN_REQUEST`;
export const CHECK_CONFIRMATION_TOKEN_SUCCESS = `${NAME}.CHECK_CONFIRMATION_TOKEN_SUCCESS`;
export const CHECK_CONFIRMATION_TOKEN_FAILURE = `${NAME}.CHECK_CONFIRMATION_TOKEN_FAILURE`;

export const REGISTER_REQUEST = `${NAME}.REGISTER_REQUEST`;
export const REGISTER_SUCCESS = `${NAME}.REGISTER_SUCCESS`;
export const REGISTER_FAILURE = `${NAME}.REGISTER_FAILURE`;

export const SEND_CONFIRMATION_REQUEST = `${NAME}.SEND_CONFIRMATION_REQUEST`;
export const SEND_CONFIRMATION_SUCCESS = `${NAME}.SEND_CONFIRMATION_SUCCESS`;
export const SEND_CONFIRMATION_FAILURE = `${NAME}.SEND_CONFIRMATION_FAILURE`;

export const SET_CONFIRMATION_EMAIL = `${NAME}.SET_CONFIRMATION_EMAIL`;

export const HANDLE_REGISTRATION_COMPLETION = `${NAME}.HANDLE_REGISTRATION_COMPLETION`;

export const SET_TOKEN_EXPIRED = `${NAME}.SET_TOKEN_EXPIRED`;

export const SET_RESEND_CLICKED = `${NAME}.SET_RESEND_CLICKED`;

export const SET_TOKEN = `${NAME}.SET_TOKEN`;

export const AUTH_VALIDATED = `${NAME}.AUTH_VALIDATED`;

export const LOGOUT = `${NAME}.LOGOUT`;
