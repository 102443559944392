import React from 'react';
import PropTypes from 'prop-types';
import { Card, CardBody, Col, Row } from 'reactstrap';
import Map from '../../../components/Map/Map';

const MapWrapper = ({
  title,
  data,
  colorValues,
  createTooltip,
  dataIsFetching,
}) => (
  <Row>
    <Col md={12}>
      <Card>
        <CardBody>
          <div className="card__title">
            <h5 className="bold-text">{title}</h5>
          </div>
          <Map
            data={data}
            colorValues={colorValues}
            createTooltip={createTooltip}
            dataIsFetching={dataIsFetching}
          />
        </CardBody>
      </Card>
    </Col>
  </Row>
);

MapWrapper.propTypes = {
  title: PropTypes.string.isRequired,
  data: PropTypes.shape().isRequired,
  colorValues: PropTypes.arrayOf(PropTypes.number),
  createTooltip: PropTypes.func.isRequired,
  dataIsFetching: PropTypes.bool.isRequired,
};

MapWrapper.defaultProps = {
  colorValues: [],
};

export default MapWrapper;
