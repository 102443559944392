import { compose, createStore } from 'redux';
import { composeWithDevTools } from 'redux-devtools-extension';

const state = {};

const initialize = (history, middleware, reducers) => {
  const store = compose(
    process.env.NODE_ENV === 'production'
      ? middleware
      : composeWithDevTools(middleware)
  )(createStore)(reducers);

  state.store = store;
  state.history = history;
};

export default {
  get store() {
    return state.store;
  },
  get history() {
    return state.history;
  },
};

export { initialize };
