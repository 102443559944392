import { RSAA, getJSON, ApiError } from 'redux-api-middleware';

import { config } from './config';

export const callAPI = (apiOptions, options = {}) => {
  const reduxAction = Object.assign({}, config.DEFAULT_EVENT, apiOptions);
  let reduxErrorAction = reduxAction.types[2];
  reduxErrorAction =
    typeof reduxErrorAction === 'string'
      ? { type: reduxErrorAction }
      : reduxErrorAction;

  if (typeof reduxErrorAction.payload === 'undefined') {
    let errorInterceptor = options.errorInterceptor;
    if (typeof errorInterceptor === 'undefined') {
      errorInterceptor = config.ERROR_INTERCEPTOR;
    }

    reduxErrorAction.payload = (action, state, res) =>
      getJSON(res).then(json => {
        const error = new ApiError(res.status, res.statusText, json);
        if (errorInterceptor) errorInterceptor(error);
        return error;
      });
    reduxAction.types[2] = reduxErrorAction;
  }
  return { [RSAA]: reduxAction };
};
