import { connect } from 'react-redux';

import stats from '../../../../../stats';
import MaterialTableCard from '../../../../components/Table/MaterialTable/TableCard';
import hideable from '../../../../utils/hideable';

const cols = () => [
  {
    dataKey: 'countryName',
    label: 'Country Name',
    numeric: false,
    align: 'left',
  },
  {
    dataKey: 'code',
    label: 'Country Code',
    numeric: false,
  },
  {
    dataKey: 'avgDailyActiveUsers',
    label: 'Average DAU',
    numeric: true,
  },
  {
    dataKey: 'avgEarnings',
    label: 'Average earnings',
    numeric: true,
  },
  {
    dataKey: 'percentage',
    label: '% of Total',
    numeric: true,
  },
];

const title = 'DETAILED COUNTRY STATISTICS';
const MAX_ROWS_NUMBER_PER_PAGE = 10;

const mapStateToProps = state => {
  const data = stats.selectors.getFormatedDailyActiveUsersPerCountryData(state);
  const rowsCount = data.length;
  const rowsPerPage =
    rowsCount < MAX_ROWS_NUMBER_PER_PAGE ? rowsCount : MAX_ROWS_NUMBER_PER_PAGE;
  return {
    data,
    rowsPerPage,
    cols,
    title,
    isVisible: data.length,
  };
};

export default connect(mapStateToProps)(hideable(MaterialTableCard));
