import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { Card, CardBody, Col, Container, Row } from 'reactstrap';
import { connect } from 'react-redux';

import auth from '../../../auth';
import billing from '../../../billing';
import ui from '../../../ui';
import users from '../../../users';
import Modal from '../../components/Modal';
import Invoice from './components/Invoice';
import Tabs from '../shared/UserProfile/Tabs';
import PaymentInfoForm from '../shared/UserProfile/PaymentInfoForm';
import LegalInfoForm from '../shared/UserProfile/LegalInfoForm';

import Payments from './components/Payments/PaymentsContainer';
import CurrentBalanceCardContainer from '../../components/CurrentBalanceCardContainer';
import PendingBalanceCardContainer from '../../components/PendingBalanceCardContainer';
import TotalEarnedCardContainer from '../../components/TotalEarnedCardContainer';
import InvoicesTable from './components/InvoiceTable/InvoicesTableContainer';

class Billing extends PureComponent {
  componentDidMount() {
    const {
      isPayoutsFetching,
      fetchPayouts,
      isInvoicesFetching,
      fetchInvoices,
    } = this.props;
    if (!isPayoutsFetching) {
      fetchPayouts();
    }
    if (!isInvoicesFetching) {
      fetchInvoices();
    }
  }

  createInvoice() {
    const { isCreateInvoiceFetching, createInvoice } = this.props;
    if (!isCreateInvoiceFetching) {
      createInvoice();
    }
  }

  canRequestInvoice() {
    const {
      unpaidAmount,
      unpaidDaysCount,
      minPayoutAmount,
      minPayoutDays,
      hadPayout,
    } = this.props;

    return (
      minPayoutAmount !== null &&
      unpaidAmount >= minPayoutAmount &&
      ((minPayoutDays !== null && unpaidDaysCount >= minPayoutDays) ||
        !hadPayout)
    );
  }

  userDetailsEmpty() {
    const { legalInfoData, paymentInfoData } = this.props;
    return (
      legalInfoData.legal_type === null || paymentInfoData.payment_type === null
    );
  }

  render() {
    const {
      createInvoice,
      isInvoicesTableVisible,
      isPaymentsChartVisible,
      onRequestPayoutClick,
    } = this.props;
    const canRequestInvoice = this.canRequestInvoice();
    const userDetailsEmpty = this.userDetailsEmpty();
    return (
      <Container className="billing">
        <Row>
          <Col md={12}>
            <h3 className="page-title">Billing</h3>
          </Col>
        </Row>
        <Row>
          <Col md={12} lg={12} xl={3}>
            <CurrentBalanceCardContainer />
          </Col>
          <Col md={12} lg={12} xl={3}>
            <PendingBalanceCardContainer />
          </Col>
          <Col md={12} lg={12} xl={3}>
            <TotalEarnedCardContainer />
          </Col>
          <Col md={12} lg={12} xl={3}>
            <Modal
              className="user-details-modal"
              title="Your Information"
              message={
                <div>
                  {userDetailsEmpty && (
                    <div className="mb-3 error-block">
                      Please fill out your personal and payment details.
                    </div>
                  )}
                  <Tabs
                    tabs={[
                      {
                        index: '1',
                        name: 'details',
                        tabText: 'Details',
                        component: LegalInfoForm,
                      },
                      {
                        index: '2',
                        name: 'payments',
                        tabText: 'payments',
                        component: PaymentInfoForm,
                      },
                    ]}
                  />
                  <hr />
                </div>
              }
              toggle={() =>
                this.props.toggleModal(ui.constants.MODALS.USER_DETAILS_MODAL)
              }
              isOpen={this.props.isUserDetailsModalOpen}
              confirmBtn={{
                text: 'Confirm',
                onClick: () => {
                  if (!userDetailsEmpty) {
                    this.props.toggleModal(
                      ui.constants.MODALS.USER_DETAILS_MODAL
                    );
                    createInvoice();
                  }
                },
                visible: true,
                className: 'btn btn-primary form-control',
              }}
              cancelBtn={{
                visible: false,
              }}
            />
            <button
              className={`btn request-payout-button form-control ${'btn-primary'}`}
              onClick={onRequestPayoutClick}
              disabled={!canRequestInvoice}
            >
              Request Payout
            </button>
          </Col>
        </Row>
        {isPaymentsChartVisible && (
          <Row>
            <Col md={12}>
              <Card>
                <CardBody>
                  <Payments />
                </CardBody>
              </Card>
            </Col>
          </Row>
        )}
        {isInvoicesTableVisible && (
          <Row>
            <Col md={12}>
              <InvoicesTable />
            </Col>
          </Row>
        )}
        <Modal
          className="invoice-modal"
          color="primary"
          title="Payment note"
          footer={false}
          message={<Invoice />}
          toggle={() =>
            this.props.toggleModal(ui.constants.MODALS.INVOICE_TABLE_MODAL)
          }
          isOpen={this.props.isInvoiceModalOpen}
        />
      </Container>
    );
  }
}

Billing.propTypes = {
  isPayoutsFetching: PropTypes.bool.isRequired,
  isInvoicesFetching: PropTypes.bool.isRequired,
  isCreateInvoiceFetching: PropTypes.bool.isRequired,
  hadPayout: PropTypes.bool.isRequired,
  unpaidAmount: PropTypes.number.isRequired,
  minPayoutAmount: PropTypes.number,
  minPayoutDays: PropTypes.number,
  unpaidDaysCount: PropTypes.number.isRequired,
  isInvoicesTableVisible: PropTypes.bool.isRequired,
  legalInfoData: PropTypes.shape().isRequired,
  paymentInfoData: PropTypes.shape().isRequired,
  isInvoiceModalOpen: PropTypes.bool.isRequired,
  isUserDetailsModalOpen: PropTypes.bool.isRequired,
  fetchPayouts: PropTypes.func.isRequired,
  fetchInvoices: PropTypes.func.isRequired,
  createInvoice: PropTypes.func.isRequired,
  toggleModal: PropTypes.func.isRequired,
  onRequestPayoutClick: PropTypes.func.isRequired,
  isPaymentsChartVisible: PropTypes.bool.isRequired,
};

Billing.defaultProps = {
  minPayoutAmount: null,
  minPayoutDays: null,
};

const enhance = connect(
  state => ({
    isPayoutsFetching: billing.selectors.isPayoutsFetching(state),
    isPaymentsChartVisible:
      billing.selectors.getAllPayoutsChartData(state).length !== 0,
    isInvoicesFetching: billing.selectors.isInvoicesFetching(state),
    isCreateInvoiceFetching: billing.selectors.isCreateInvoiceFetching(state),
    unpaidAmount: billing.selectors.getUnpaidAmount(state),
    unpaidDaysCount: billing.selectors.getUnpaidDaysCount(state),
    minPayoutAmount: auth.selectors.getMinPayoutAmount(state),
    minPayoutDays: auth.selectors.getMinPayoutDays(state),
    hadPayout: auth.selectors.hadPayout(state),
    isInvoicesTableVisible: billing.selectors.getInvoicesData(state).length > 0,
    legalInfoData: users.selectors.getLegalInfoData(state),
    paymentInfoData: users.selectors.getPaymentInfoData(state),
    isInvoiceModalOpen: ui.selectors.isInvoiceModalOpen(state),
    isUserDetailsModalOpen: ui.selectors.isUserDetailsModalOpen(state),
  }),
  dispatch => ({
    fetchPayouts: () => dispatch(billing.actions.fetchPayouts()),
    fetchInvoices: () => dispatch(billing.actions.fetchInvoices()),
    createInvoice: () => dispatch(billing.actions.createInvoice()),
    toggleModal: data => dispatch(ui.actions.toggleModal(data)),
    onRequestPayoutClick: () =>
      dispatch(billing.actions.onRequestPayoutClick()),
  })
);

export default enhance(Billing);
