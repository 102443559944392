import { replaceUnderscoresBySpaces } from './stringOperations';

export const required = (value, values, props, name) =>
  value || typeof value === 'number'
    ? undefined
    : `${replaceUnderscoresBySpaces(name)} is required.`;

export const dateRangeRequired = value =>
  value && value.start !== null && value.end !== null
    ? undefined
    : 'Full date range is required.';

const minLength = min => (value, values, props, name) =>
  value && value.length < min
    ? `${replaceUnderscoresBySpaces(name)} must be ${min} characters or more.`
    : undefined;

export const minLength2 = minLength(2);
export const minLength6 = minLength(6);

const maxLength = max => (value, values, props, name) =>
  value && value.length > max
    ? `${replaceUnderscoresBySpaces(name)} must be ${max} characters or less.`
    : undefined;

export const maxLength100 = maxLength(100);
export const maxLength35 = maxLength(35);
export const maxLength30 = maxLength(30);

export const email = value =>
  value && !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(value)
    ? 'Invalid email address.'
    : undefined;

export const alphaNumeric = (value, values, props, name) =>
  value && /[^a-zA-Z0-9]/i.test(value)
    ? `${replaceUnderscoresBySpaces(name)} must be alphanumeric.`
    : undefined;

export const alphabetic = (value, values, props, name) =>
  value && /[^a-zA-Z]/i.test(value)
    ? `${replaceUnderscoresBySpaces(name)} must be alphabethic.`
    : undefined;

export const names = (value, values, props, name) =>
  value && /[^a-zA-Z ]/i.test(value)
    ? `${replaceUnderscoresBySpaces(name)} has invalid character "${
        /[^a-zA-Z ]/i.exec(value)[0]
      }".`
    : undefined;

export const numeric = (value, values, props, name) =>
  value && /[^0-9]/i.test(value)
    ? `${replaceUnderscoresBySpaces(name)} must be numeric.`
    : undefined;

export const address = value =>
  value && /[^a-zA-Z0-9/,-. ]/i.test(value)
    ? `Address is not valid.`
    : undefined;

export const companyName = (value, values, props, name) =>
  value && /[^a-zA-Z0-9/,-. ]/i.test(value)
    ? `${replaceUnderscoresBySpaces(name)} has invalid character "${
        /[^a-zA-Z0-9/,-. ]/i.exec(value)[0]
      }".`
    : undefined;

export const passwordsMustMatch = (value, allValues) =>
  value !== allValues.new_password ? 'Passwords do not match' : undefined;
