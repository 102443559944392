import React from 'react';
import PropTypes from 'prop-types';
import LoadingIcon from 'mdi-react/LoadingIcon';

const Loader = props => (
  <div>
    {props.isLoading && (
      <div className="panel__refresh">
        <LoadingIcon />
      </div>
    )}
  </div>
);

Loader.propTypes = {
  isLoading: PropTypes.bool,
};

Loader.defaultProps = {
  isLoading: false,
};

export default Loader;
