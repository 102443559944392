import apps from '../../apps';
import auth from '../../auth';
import users from '../../users';
import billing from '../../billing';

export const analyticsMiddleware = () => next => action => {
  const userUUID = sessionStorage.getItem('uuid');

  switch (action.type) {
    case apps.types.DOWNLOAD_INVOICE_PDF_SUCCESS: {
      window.dataLayer.push({
        event: 'InvoicePdfDownload',
        userID: userUUID,
      });
      break;
    }
    case apps.types.DOWNLOAD_SDK_REQUEST: {
      window.dataLayer.push({
        event: 'tos_accept',
      });
      break;
    }
    case auth.types.REGISTER_SUCCESS: {
      window.dataLayer.push({
        event: 'registration_success',
      });
      break;
    }
    case apps.types.CREATE_APP_SUCCESS: {
      window.dataLayer.push({
        event: 'sdk_request',
      });
      break;
    }
    case auth.types.LOGIN_SUCCESS: {
      window.dataLayer.push({
        event: 'login_success',
        username: action.payload.username,
      });
      break;
    }
    case apps.types.UPDATE_SELECTED_APP: {
      window.dataLayer.push({
        event: 'sdk_download',
        userID: userUUID,
      });
      break;
    }
    case users.types.UPDATE_LEGAL_INFO_SUCCESS: {
      window.dataLayer.push({
        event: 'userinfo_continue',
        userID: userUUID,
      });
      break;
    }
    case users.types.CREATE_TOS_AGREEMENT_SUCCESS: {
      window.dataLayer.push({
        event: 'ToS_download',
        userID: userUUID,
      });
      break;
    }
    case billing.types.REQUEST_PAYOUT_GA_EVENT: {
      window.dataLayer.push({
        event: 'request_payout',
        userID: userUUID,
      });
      break;
    }
    default:
  }
  next(action);
};
