import Cookies from 'js-cookie';

import { parseQueryParamsFromURL } from './urlHelpers';

export const setCookiesFromQueryParams = () => {
  const queryParams = parseQueryParamsFromURL(['coupon']);
  Object.entries(queryParams).forEach(([paramName, paramVal]) => {
    if (paramVal !== null) {
      Cookies.set(`globalhop_${paramName}`, paramVal, { expires: 1 });
    }
  });
};
