import { connect } from 'react-redux';

import PayoutCard from './PayoutCard';
import billing from '../../billing';

const title = 'Current Balance';
const isUnpaid = true;

const mapStateToProps = state => ({
  title,
  label: `$${billing.selectors.getUnpaidAmount(state).toLocaleString()}`,
  isUnpaid,
});

export default connect(mapStateToProps)(PayoutCard);
