import React from 'react';

import { capitalizeFirstLetter } from '../utils/stringOperations';

const Input = ({ input, label, type, meta: { touched, error } }) => (
  <div className="custom-input-wrapper">
    <input
      {...input}
      placeholder={label}
      type={type}
      className={touched && error ? 'error' : null}
    />
    {touched &&
      (error && (
        <span className="field-error">{capitalizeFirstLetter(error)}</span>
      ))}
  </div>
);

export default Input;
