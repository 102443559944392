import * as types from './actionTypes';

export const sessionStorageMiddleware = () => next => action => {
  if (action.type === types.LOGIN_SUCCESS) {
    sessionStorage.setItem('token', action.payload.token);
    sessionStorage.setItem('username', action.payload.username);
    sessionStorage.setItem('email', action.payload.email);
    sessionStorage.setItem('uuid', action.payload.uuid);
    sessionStorage.setItem(
      'min_payout_amount',
      action.payload.min_payout_amount
    );
    sessionStorage.setItem('min_payout_days', action.payload.min_payout_days);
    sessionStorage.setItem('is_superuser', action.payload.is_superuser);
  } else if (
    action.type === types.LOGIN_FAILURE ||
    action.type === types.LOGOUT ||
    action.type === types.VERIFY_TOKEN_FAILURE
  ) {
    sessionStorage.removeItem('token');
    sessionStorage.removeItem('username');
    sessionStorage.removeItem('email');
    sessionStorage.removeItem('uuid');
    sessionStorage.removeItem('min_payout_amount');
    sessionStorage.removeItem('min_payout_days');
  }
  next(action);
};
