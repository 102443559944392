import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { Container, Col, Row } from 'reactstrap';

import content from '../../../content';

class Faq extends PureComponent {
  componentDidMount() {
    const { fetchElements, elementsFetching, pageId } = this.props;
    if (!elementsFetching && pageId) {
      fetchElements(pageId);
    }
  }

  componentDidUpdate(prevProps) {
    const { pageId, fetchElements } = this.props;
    if (prevProps.pageId !== pageId) {
      fetchElements(pageId);
    }
  }

  render() {
    const { element } = this.props;
    return (
      <Container>
        <Row>
          <Col md={12}>
            <h3 className="page-title">FAQ</h3>
          </Col>
        </Row>
        <Row>
          <Col md={12}>
            <div dangerouslySetInnerHTML={{ __html: element }} />
          </Col>
        </Row>
      </Container>
    );
  }
}

Faq.propTypes = {
  elementsFetching: PropTypes.bool.isRequired,
  fetchElements: PropTypes.func.isRequired,
  element: PropTypes.string,
  pageId: PropTypes.number,
};

Faq.defaultProps = {
  element: '',
  pageId: null,
};

const enhance = connect(
  state => ({
    elementsFetching: content.selectors.elementsFetching(state),
    element: content.selectors.getElementByName(state, 'Body'),
    pageId: content.selectors.getPageId(state, 'FAQ'),
  }),
  dispatch => ({
    dispatch,
    fetchElements: bindActionCreators(content.actions.fetchElements, dispatch),
  })
);

export default enhance(Faq);
