import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableHead from '@material-ui/core/TableHead';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';

import billing from '../../../../billing';

const cols = () => [
  {
    dataKey: 'period_from',
    label: 'Date From',
    numeric: false,
  },
  {
    dataKey: 'period_to',
    label: 'Date To',
    numeric: false,
  },
  {
    dataKey: 'amount',
    label: 'Amount ($)',
    numeric: true,
  },
];

const InvoiceModalTable = ({ data }) => (
  <Table className="material-table">
    <TableHead>
      <TableRow>
        {cols().map(col => (
          <TableCell
            className="material-table__cell"
            key={col.dataKey}
            numeric={col.numeric}
          >
            {col.label}
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
    <TableBody>
      <TableRow>
        {cols().map(col => (
          <TableCell key={col.dataKey} className="material-table__cell">
            {data[col.dataKey]}
          </TableCell>
        ))}
      </TableRow>
    </TableBody>
  </Table>
);

InvoiceModalTable.propTypes = {
  data: PropTypes.shape().isRequired,
};

const mapStateToProps = state => ({
  data: billing.selectors.getSelectedInvoice(state),
});

export default connect(mapStateToProps)(InvoiceModalTable);
