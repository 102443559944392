import Immutable from 'seamless-immutable';

import * as types from './actionTypes';

const DEFAULT_TOS_STATE = {
  data: {
    user_agreed: false,
  },
  isFetching: false,
};

const DEFAULT_CREATE_TOS_AGREEMENT_STATE = {
  isFetching: false,
  error: null,
};

const DEFAULT_LEGAL_INFO_STATE = {
  data: {},
  isFetching: false,
  errors: [],
};

const DEFAULT_PAYMENT_INFO_STATE = {
  data: {},
  isFetching: false,
  errors: [],
};

const DEFAULT_UPDATE_USER_STATE = {
  isFetching: false,
  errors: [],
};

const DEFAULT_STATE = Immutable({
  tos: DEFAULT_TOS_STATE,
  createTosAgreement: DEFAULT_CREATE_TOS_AGREEMENT_STATE,
  legalInfo: DEFAULT_LEGAL_INFO_STATE,
  paymentInfo: DEFAULT_PAYMENT_INFO_STATE,
  updateUser: DEFAULT_UPDATE_USER_STATE,
});

export default (state = DEFAULT_STATE, { payload, type }) => {
  switch (type) {
    case types.FETCH_TOS_REQUEST:
      return Immutable.set(state, 'tos', {
        ...DEFAULT_TOS_STATE,
        isFetching: true,
      });
    case types.FETCH_TOS_SUCCESS:
      return Immutable.set(state, 'tos', {
        ...DEFAULT_TOS_STATE,
        data: payload,
        isFetching: false,
      });
    case types.FETCH_TOS_FAILURE:
      return Immutable.set(state, 'tos', {
        ...DEFAULT_TOS_STATE,
        isFetching: false,
      });

    case types.UPDATE_LEGAL_INFO_REQUEST:
      return Immutable.set(state, 'legalInfo', {
        ...state.legalInfo,
        isFetching: true,
      });
    case types.UPDATE_LEGAL_INFO_SUCCESS:
      return Immutable.set(state, 'legalInfo', {
        ...state.legalInfo,
        isFetching: false,
      });
    case types.UPDATE_LEGAL_INFO_FAILURE:
      return Immutable.set(state, 'legalInfo', {
        ...state.legalInfo,
        isFetching: false,
      });

    case types.FETCH_LEGAL_INFO_SUCCESS:
      return Immutable.set(state, 'legalInfo', {
        ...DEFAULT_LEGAL_INFO_STATE,
        data: payload,
      });

    case types.UPDATE_PAYMENT_INFO_REQUEST:
      return Immutable.set(state, 'paymentInfo', {
        ...state.paymentInfo,
        isFetching: true,
      });
    case types.UPDATE_PAYMENT_INFO_SUCCESS:
      return Immutable.set(state, 'paymentInfo', {
        ...state.paymentInfo,
        isFetching: false,
      });
    case types.UPDATE_PAYMENT_INFO_FAILURE:
      return Immutable.set(state, 'paymentInfo', {
        ...state.paymentInfo,
        isFetching: false,
      });

    case types.FETCH_PAYMENT_INFO_SUCCESS:
      return Immutable.set(state, 'paymentInfo', {
        ...DEFAULT_PAYMENT_INFO_STATE,
        data: payload,
      });

    case types.CREATE_TOS_AGREEMENT_REQUEST:
      return Immutable.set(state, 'createTosAgreement', {
        ...DEFAULT_CREATE_TOS_AGREEMENT_STATE,
        isFetching: true,
      });
    case types.CREATE_TOS_AGREEMENT_SUCCESS:
      return Immutable.set(state, 'createTosAgreement', {
        ...DEFAULT_CREATE_TOS_AGREEMENT_STATE,
        isFetching: false,
      });
    case types.CREATE_TOS_AGREEMENT_FAILURE:
      return Immutable.set(state, 'createTosAgreement', {
        ...DEFAULT_CREATE_TOS_AGREEMENT_STATE,
        isFetching: false,
        error: payload.response,
      });

    case types.UPDATE_USER_REQUEST:
      return Immutable.set(state, 'updateUser', {
        ...DEFAULT_UPDATE_USER_STATE,
        isFetching: true,
      });
    case types.UPDATE_USER_SUCCESS:
      return Immutable.set(state, 'updateUser', {
        ...DEFAULT_UPDATE_USER_STATE,
        isFetching: false,
      });
    case types.UPDATE_USER_FAILURE:
      return Immutable.set(state, 'updateUser', {
        ...DEFAULT_UPDATE_USER_STATE,
        isFetching: false,
        error: payload.response,
      });

    case types.UPDATE_TOS:
      return Immutable.set(state, 'tos', {
        ...state.tos,
        data: payload.data,
      });

    default:
      return state;
  }
};
