import idx from 'idx';

import { NAME } from './constants';

export const isCreateAppFetching = state =>
  idx(state, p => p[NAME].createdApp.isFetching);

export const getCreateAppErrors = state =>
  idx(state, p => p[NAME].createdApp.errors);

export const appsFetching = state => idx(state, p => p[NAME].apps.isFetching);

export const getApps = state => idx(state, p => p[NAME].apps.data);

export const isTosFetching = state => idx(state, p => p[NAME].tos.isFetching);

export const getTosData = state => idx(state, p => p[NAME].tos.data);

export const getTosVersion = state => idx(state, p => p[NAME].tos.data.version);

export const tosAgreed = state => idx(state, p => p[NAME].tos.data.user_agreed);

export const isLegalInfoFetching = state =>
  idx(state, p => p[NAME].legalInfo.isFetching);

export const getLegalInfoData = state =>
  idx(state, p => p[NAME].legalInfo.data);

export const getUpdateLegalInfoErrors = state =>
  idx(state, p => p[NAME].legalInfo.errors);

export const isPaymentInfoFetching = state =>
  idx(state, p => p[NAME].paymentInfo.isFetching);

export const getPaymentInfoData = state =>
  idx(state, p => p[NAME].paymentInfo.data);

export const isCreateTosAgreementFetching = state =>
  idx(state, p => p[NAME].createTosAgreement.isFetching);

export const isUpdateUserFetching = state =>
  idx(state, p => p[NAME].updateUser.isFetching);
