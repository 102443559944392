import { NotificationManager } from 'react-notifications';

import { LOGIN } from '../app/utils/routes';
import * as types from './actionTypes';
import { EMAIL_CONFIRMATION_EMAIL, TOKEN_EXPIRED_CODE } from './constants';
import {
  isRegisterFetching,
  getConfirmationEmail,
  getRegistrationToken,
} from './selectors';
import { callAPI } from '../api';

export const login = data =>
  callAPI(
    {
      method: 'POST',
      endpoint: '/api/v1/login/',
      types: [types.LOGIN_REQUEST, types.LOGIN_SUCCESS, types.LOGIN_FAILURE],
      body: data,
    },
    {
      errorInterceptor: () => {},
    }
  );

export const verifyToken = data =>
  callAPI(
    {
      method: 'POST',
      endpoint: '/api/v1/verify-auth-token/',
      types: [
        types.VERIFY_TOKEN_REQUEST,
        types.VERIFY_TOKEN_SUCCESS,
        types.VERIFY_TOKEN_FAILURE,
      ],
      body: data,
    },
    {
      errorInterceptor: () => {},
    }
  );

export const tokenVerificationFailure = () => ({
  type: types.VERIFY_TOKEN_FAILURE,
});

export const setResendClicked = () => ({
  type: types.SET_RESEND_CLICKED,
});

export const setConfirmationEmail = email => ({
  type: types.SET_CONFIRMATION_EMAIL,
  payload: email,
});

export const handleRegistrationCompletion = value => ({
  type: types.HANDLE_REGISTRATION_COMPLETION,
  payload: value,
});

export const setTokenExpired = value => ({
  type: types.SET_TOKEN_EXPIRED,
  payload: value,
});

export const setToken = value => ({
  type: types.SET_TOKEN,
  payload: value,
});

export const sendConfirmationEmail = ({ email, privacyPolicyAgreed }) => (
  dispatch,
  getState
) => {
  if (isRegisterFetching(getState())) {
    return;
  }

  dispatch(
    callAPI({
      method: 'POST',
      endpoint: EMAIL_CONFIRMATION_EMAIL,
      types: [
        types.SEND_CONFIRMATION_REQUEST,
        {
          type: types.SEND_CONFIRMATION_SUCCESS,
          payload: () => {
            dispatch(setConfirmationEmail(email));
          },
        },
        types.SEND_CONFIRMATION_FAILURE,
      ],
      body: JSON.stringify({
        email,
        privacy_policy_agreed: privacyPolicyAgreed,
      }),
    })
  );
};

export const checkConfirmationToken = (token, push) => dispatch => {
  dispatch(
    callAPI({
      method: 'GET',
      endpoint: `/api/v1/users/${token}/confirmation_token/`,
      types: [
        types.CHECK_CONFIRMATION_TOKEN_REQUEST,
        {
          type: types.CHECK_CONFIRMATION_TOKEN_SUCCESS,
          payload: (action, state, res) =>
            res.json().then(json => {
              const { email } = json;

              dispatch(setToken(token));
              dispatch(setConfirmationEmail(email));
              dispatch(handleRegistrationCompletion(true));
            }),
        },
        {
          type: types.CHECK_CONFIRMATION_TOKEN_FAILURE,
          payload: (action, state, res) =>
            res.json().then(json => {
              const { code, email } = json;

              if (code === TOKEN_EXPIRED_CODE) {
                dispatch(setTokenExpired(true));
                dispatch(setConfirmationEmail(email));
              } else {
                push(LOGIN);
              }
            }),
        },
      ],
    })
  );
};

export const resendConfirmation = () => (dispatch, getState) => {
  const email = getConfirmationEmail(getState());

  dispatch(setResendClicked());
  dispatch(setTokenExpired(false));
  dispatch(sendConfirmationEmail({ email, privacyPolicyAgreed: true }));
};

export const register = data => (dispatch, getState) => {
  const store = getState();
  const email = getConfirmationEmail(store);
  const token = getRegistrationToken(store);
  const { username, password } = data;
  const body = JSON.stringify({
    email,
    token,
    ...data,
  });
  const loginBody = JSON.stringify({ username, password });

  dispatch(
    callAPI(
      {
        method: 'POST',
        endpoint: '/api/v1/users/',
        types: [
          types.REGISTER_REQUEST,
          {
            type: types.REGISTER_SUCCESS,
            payload: (action, state, res) =>
              res.json().then(json => {
                dispatch(login(loginBody));
                const { coupon } = json;
                if (coupon) {
                  const { code, balance } = coupon;
                  NotificationManager.success(
                    `Thank you for signing up. $${balance} has been added to your ` +
                      `account for using coupon code: ${code} during registration.`,
                    '',
                    60000
                  );
                }
                return json;
              }),
          },
          types.REGISTER_FAILURE,
        ],
        body,
      },
      {
        errorInterceptor: () => {},
      }
    )
  );
};

export const authValidated = data => ({
  type: types.AUTH_VALIDATED,
  payload: data,
});

export const logout = () => ({
  type: types.LOGOUT,
});
