import React, { PureComponent } from 'react';
import { Field, reduxForm } from 'redux-form';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { compose } from 'recompose';
import { connect } from 'react-redux';

import users from '../../../../users';
import renderRadioButtonField from '../../../components/Form/RadioButton';
import renderTextField from '../../../components/Form/TextField';
import {
  address,
  alphabetic,
  maxLength35,
  minLength2,
  required,
} from '../../../utils/validators';

class UserDetailsForm extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      legalType: 'person',
    };
  }

  updateLegalType(val) {
    this.setState({
      legalType: val,
    });
  }

  updateLegalInfo(values) {
    const { updateLegalInfo, isLegalInfoFetching } = this.props;
    if (!isLegalInfoFetching) {
      updateLegalInfo(JSON.stringify(values), true);
    }
  }

  renderErrors() {
    return this.props.errors.map((error, i) => <li key={i}>{error}</li>);
  }

  renderPersonFields() {
    return (
      <div>
        <div>
          <Field
            className="material-form__field"
            name="first_name"
            label="First Name"
            component={renderTextField}
            placeholder="Enter First Name"
            type="text"
            validate={[required, alphabetic, minLength2]}
          />
        </div>
        <div>
          <Field
            className="material-form__field"
            name="last_name"
            label="Last Name"
            component={renderTextField}
            placeholder="Enter Last Name"
            type="text"
            validate={[required, alphabetic, minLength2]}
          />
        </div>
        <div>
          <Field
            className="material-form__field"
            name="address"
            label="Address"
            component={renderTextField}
            placeholder="Enter Address"
            type="text"
            validate={[required, address, maxLength35]}
          />
        </div>
      </div>
    );
  }

  renderCompanyFields() {
    return (
      <div>
        <div>
          <Field
            className="material-form__field"
            name="company_name"
            label="Company Name"
            component={renderTextField}
            placeholder="Enter Company Name"
            type="text"
            validate={[required, minLength2]}
          />
        </div>
        <div>
          <Field
            className="material-form__field"
            name="company_address"
            label="Company Address"
            component={renderTextField}
            placeholder="Enter Company Address"
            type="text"
            validate={[required, address, maxLength35]}
          />
        </div>
      </div>
    );
  }

  render() {
    const { handleSubmit } = this.props;

    return (
      <div>
        Please tell us a bit more about your app.
        <ul className="mb-3 errors-list">{this.renderErrors()}</ul>
        <form
          className="material-form"
          onSubmit={handleSubmit(values => {
            this.props.toggleModal();
            this.updateLegalInfo(values);
          })}
        >
          <div className="form__form-group">
            <div className="form__form-group-field">
              <Field
                onChange={(e, val) => this.updateLegalType(val)}
                name="legal_type"
                component={renderRadioButtonField}
                label="Person"
                radioValue="person"
                value={this.state.legalType}
                defaultChecked={this.state.legalType === 'person'}
              />
              <Field
                onChange={(e, val) => this.updateLegalType(val)}
                name="legal_type"
                component={renderRadioButtonField}
                label="Company"
                radioValue="company"
                value={this.state.legalType}
                defaultChecked={this.state.legalType === 'company'}
              />
            </div>
          </div>
          {this.state.legalType === 'person'
            ? this.renderPersonFields()
            : this.renderCompanyFields()}
          <button type="submit" className="btn btn-primary form-control">
            Continue
          </button>
        </form>
      </div>
    );
  }
}

UserDetailsForm.propTypes = {
  toggleModal: PropTypes.func.isRequired,

  isLegalInfoFetching: PropTypes.bool.isRequired,
  errors: PropTypes.arrayOf(PropTypes.string).isRequired,
  updateLegalInfo: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,
};

const enhance = compose(
  connect(
    state => ({
      isLegalInfoFetching: users.selectors.isLegalInfoFetching(state),
      errors: users.selectors.getUpdateLegalInfoErrors(state),
    }),
    dispatch => ({
      dispatch,
      updateLegalInfo: bindActionCreators(
        users.actions.updateLegalInfo,
        dispatch
      ),
    })
  ),
  reduxForm({ form: 'userDetailsForm' })
);

export default enhance(UserDetailsForm);
