import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';

import Chart from '../Chart';

class DailyEarningsChart extends PureComponent {
  componentDidMount() {
    const { isFetching, fetchData, filters, overrideFilters } = this.props;
    if (!isFetching) {
      fetchData(overrideFilters || filters);
    }
  }

  componentDidUpdate(prevProps) {
    const { fetchData, filters, overrideFilters } = this.props;
    if (overrideFilters === null && filters !== prevProps.filters) {
      fetchData(filters);
    }
  }

  render() {
    const { data, lines, isFetching } = this.props;

    return (
      <Chart
        type="bar"
        title="Daily Earnings"
        data={data}
        XAxisDataKey="date"
        lines={lines}
        isFetching={isFetching}
      />
    );
  }
}

DailyEarningsChart.propTypes = {
  overrideFilters: PropTypes.shape(),
  data: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  lines: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  isFetching: PropTypes.bool.isRequired,
  filters: PropTypes.shape().isRequired,
  fetchData: PropTypes.func.isRequired,
};

DailyEarningsChart.defaultProps = {
  overrideFilters: null,
};

export default DailyEarningsChart;
