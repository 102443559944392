import React, { PureComponent } from 'react';
import { Field, reduxForm } from 'redux-form';
import PropTypes from 'prop-types';
import { ButtonToolbar } from 'reactstrap';
import { bindActionCreators } from 'redux';
import { compose } from 'recompose';
import { connect } from 'react-redux';

import users from '../../../../users';
import Action from '../../../components/Form/Actions/Action';
import renderTextField from '../../../components/Form/TextField';
import renderRadioButtonField from '../../../components/Form/RadioButton';
import Loader from '../../../components/Loader';
import {
  required,
  minLength2,
  maxLength35,
  maxLength100,
} from '../../../utils/validators';

class LegalInfoForm extends PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      legalType: props.initialValues.legal_type || 'person',
    };
  }

  componentDidMount() {
    const { fetchLegalInfo, isLegalInfoFetching } = this.props;
    if (!isLegalInfoFetching) {
      fetchLegalInfo();
    }
  }

  componentDidUpdate(prevProps) {
    const {
      fetchLegalInfo,
      isLegalInfoFetching,
      initialValues,
      submitSucceeded,
    } = this.props;
    if (!isLegalInfoFetching && submitSucceeded) {
      fetchLegalInfo();
    }
    if (
      initialValues.legal_type &&
      initialValues.legal_type !== prevProps.initialValues.legal_type
    ) {
      this.setState({
        legalType: initialValues.legal_type,
      });
    }
  }

  updateLegalType(val) {
    this.setState({
      legalType: val,
    });
  }

  updateLegalInfo(values) {
    const { updateLegalInfo, isLegalInfoFetching } = this.props;
    if (!isLegalInfoFetching) {
      updateLegalInfo(JSON.stringify(values));
    }
  }

  renderOptions() {
    return (
      <div className="form__form-group-field">
        <Field
          name="legal_type"
          component={renderRadioButtonField}
          label="Person"
          className="colored-click"
          radioValue="person"
          value={this.state.legalType}
          defaultChecked={this.state.legalType === 'person'}
          onChange={(e, val) => this.updateLegalType(val)}
        />
        <Field
          name="legal_type"
          component={renderRadioButtonField}
          label="Company"
          className="colored-click"
          radioValue="company"
          value={this.state.legalType}
          defaultChecked={this.state.legalType === 'company'}
          onChange={(e, val) => this.updateLegalType(val)}
        />
      </div>
    );
  }

  renderPersonFields() {
    return (
      <div>
        <div>
          <Field
            className="material-form__field"
            name="first_name"
            label="First Name"
            component={renderTextField}
            placeholder="Enter First Name"
            type="text"
            validate={[required, minLength2]}
          />
        </div>
        <div>
          <Field
            className="material-form__field"
            name="last_name"
            label="Last Name"
            component={renderTextField}
            placeholder="Enter Last Name"
            type="text"
            validate={[required, minLength2]}
          />
        </div>
        <div>
          <Field
            className="material-form__field"
            name="address"
            label="Address"
            component={renderTextField}
            placeholder="Enter Address"
            type="text"
            validate={[required, maxLength100]}
          />
        </div>
      </div>
    );
  }

  renderCompanyFields() {
    return (
      <div>
        <div>
          <Field
            className="material-form__field"
            name="company_name"
            label="Company Name"
            component={renderTextField}
            placeholder="Enter Company Name"
            type="text"
            validate={[required, minLength2]}
          />
        </div>
        <div>
          <Field
            className="material-form__field"
            name="company_address"
            label="Company Address"
            component={renderTextField}
            placeholder="Enter Company Address"
            type="text"
            validate={[required, maxLength35]}
          />
        </div>
      </div>
    );
  }

  render() {
    const { handleSubmit, isLegalInfoFetching } = this.props;

    return (
      <form
        className="material-form"
        onSubmit={handleSubmit(values => this.updateLegalInfo(values))}
      >
        <Loader isLoading={isLegalInfoFetching} />
        <div className="form__form-group-list">
          <div className="form__form-group">{this.renderOptions()}</div>
        </div>
        <div>
          {this.state.legalType === 'person'
            ? this.renderPersonFields()
            : this.renderCompanyFields()}
        </div>
        <ButtonToolbar className="form">
          <Action type="submit" content="Update" className="btn-success" />
        </ButtonToolbar>
      </form>
    );
  }
}

LegalInfoForm.propTypes = {
  initialValues: PropTypes.shape({ legal_type: PropTypes.string }).isRequired,
  submitSucceeded: PropTypes.bool.isRequired,
  isLegalInfoFetching: PropTypes.bool.isRequired,
  updateLegalInfo: PropTypes.func.isRequired,
  fetchLegalInfo: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,
};

const enhance = compose(
  connect(
    state => ({
      initialValues: users.selectors.getLegalInfoData(state),
      isLegalInfoFetching: users.selectors.isLegalInfoFetching(state),
    }),
    dispatch => ({
      dispatch,
      updateLegalInfo: bindActionCreators(
        users.actions.updateLegalInfo,
        dispatch
      ),
      fetchLegalInfo: bindActionCreators(
        users.actions.fetchLegalInfo,
        dispatch
      ),
    })
  ),
  reduxForm({ form: 'profile_settings_form', enableReinitialize: true })
);

export default enhance(LegalInfoForm);
