import * as types from './actionTypes';

export const changeSidebarVisibility = () => ({
  type: types.CHANGE_SIDEBAR_VISIBILITY,
});

export const changeMobileSidebarVisibility = () => ({
  type: types.CHANGE_MOBILE_SIDEBAR_VISIBILITY,
});

export const toggleModal = modalName => ({
  type: types.TOGGLE_MODAL,
  payload: {
    modalName,
  },
});

export const handleAndroidInstuctionsClick = () => ({
  type: types.SET_ANDROID_INSTRUCTION_VISIBILITY,
});

export const handleUnityInstuctionsClick = () => ({
  type: types.SET_UNITY_INSTRUCTION_VISIBILITY,
});

export const handleWindowsInstuctionsClick = () => ({
  type: types.SET_WINDOWS_INSTRUCTION_VISIBILITY,
});

export const handleMacosInstuctionsClick = () => ({
  type: types.SET_MACOS_INSTRUCTION_VISIBILITY,
});
