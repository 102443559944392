import * as types from './actionTypes';
import { callAPI } from '../api';
import { requestHeaders } from '../api/utils';
import { verifyToken } from '../auth/actions';
import { toggleModal } from '../ui/actions';

export const downloadFile = (data, url) => {
  const fileURL = URL.createObjectURL(data);
  const fileName = url.substring(url.lastIndexOf('/') + 1);

  const link = document.createElement('a');
  link.href = fileURL;
  link.download = fileName;
  link.click();

  URL.revokeObjectURL(url);
};

export const createApp = data => dispatch =>
  dispatch(
    callAPI(
      {
        method: 'POST',
        endpoint: '/api/v1/apps/',
        headers: requestHeaders(true),
        types: [
          types.CREATE_APP_REQUEST,
          {
            type: types.CREATE_APP_SUCCESS,
            payload: (action, state, res) =>
              res.json().then(json => {
                dispatch(toggleModal('SDKModal'));
                return json;
              }),
          },
          types.CREATE_APP_FAILURE,
        ],
        body: data,
      },
      {
        errorInterceptor: () =>
          dispatch(verifyToken({ token: sessionStorage.getItem('token') })),
      }
    )
  );

export const fetchApps = () =>
  callAPI({
    method: 'GET',
    endpoint: '/api/v1/apps/',
    headers: requestHeaders(true),
    types: [
      types.FETCH_APPS_REQUEST,
      types.FETCH_APPS_SUCCESS,
      types.FETCH_APPS_FAILURE,
    ],
  });

export const downloadSdk = url =>
  callAPI({
    method: 'GET',
    endpoint: url,
    headers: {
      Authorization: `JWT ${sessionStorage.getItem('token')}`,
    },
    types: [
      types.DOWNLOAD_SDK_REQUEST,
      {
        type: types.DOWNLOAD_SDK_SUCCESS,
        payload: (action, state, res) =>
          res.blob().then(blob => {
            downloadFile(blob, url);
          }),
      },
      types.DOWNLOAD_SDK_FAILURE,
    ],
  });

export const downloadInvoicePdf = url =>
  callAPI({
    method: 'GET',
    endpoint: url,
    headers: {
      Authorization: `JWT ${sessionStorage.getItem('token')}`,
    },
    types: [
      types.DOWNLOAD_INVOICE_PDF_REQUEST,
      {
        type: types.DOWNLOAD_INVOICE_PDF_SUCCESS,
        payload: (action, state, res) =>
          res.blob().then(blob => {
            downloadFile(blob, url);
          }),
      },
      types.DOWNLOAD_INVOICE_PDF_FAILURE,
    ],
  });

export const updateSelectedApp = app => ({
  type: types.UPDATE_SELECTED_APP,
  payload: app,
});

export const fetchDistributionPlatforms = () =>
  callAPI({
    method: 'GET',
    endpoint: '/api/v1/distribution-platforms/',
    headers: requestHeaders(true),
    types: [
      types.FETCH_DISTRIBUTION_PLATFORMS_REQUEST,
      types.FETCH_DISTRIBUTION_PLATFORMS_SUCCESS,
      types.FETCH_DISTRIBUTION_PLATFORMS_FAILURE,
    ],
  });
