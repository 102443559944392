import React, { PureComponent } from 'react';
import {
  Card,
  Col,
  Nav,
  NavItem,
  NavLink,
  TabContent,
  TabPane,
} from 'reactstrap';
import PropTypes from 'prop-types';
import classnames from 'classnames';

export default class Tabs extends PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      activeTab: '1',
    };
  }

  toggle(tab) {
    if (this.state.activeTab !== tab) {
      this.setState({
        activeTab: tab,
      });
    }
  }

  renderTabs() {
    return this.props.tabs.map((item, index) => (
      <NavItem key={index}>
        <NavLink
          className={classnames({
            active: this.state.activeTab === item.index,
          })}
          onClick={() => {
            this.toggle(item.index);
          }}
        >
          {item.tabText}
        </NavLink>
      </NavItem>
    ));
  }

  renderTabsContent() {
    return this.props.tabs.map((item, index) => {
      const Cmpnt = item.component;

      return (
        <TabPane key={index} tabId={item.index}>
          <Cmpnt />
        </TabPane>
      );
    });
  }

  render() {
    return (
      <Col md={12} lg={12} xl={12}>
        <Card>
          <div className="profile__card tabs tabs--bordered-bottom">
            <div className="tabs__wrap">
              <Nav tabs>{this.renderTabs()}</Nav>
              <TabContent activeTab={this.state.activeTab}>
                {this.renderTabsContent()}
              </TabContent>
            </div>
          </div>
        </Card>
      </Col>
    );
  }
}

Tabs.propTypes = {
  tabs: PropTypes.arrayOf(
    PropTypes.shape({
      index: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired,
    })
  ).isRequired,
};
