import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { Link, Redirect } from 'react-router-dom';
import { branch, renderComponent, compose } from 'recompose';

import CompleteRegisterForm from './components/CompleteRegisterForm';
import ConfirmationExpired from './components/ConfirmationExpired';
import CheckYourEmail from './components/CheckYourEmail';
import LoginWrapper from '../../components/LoginWrapper';
import Loader from '../../components/Loader';
import auth from '../../../auth';
import { LOGIN, SDK } from '../../utils/routes';

class CompleteRegistration extends PureComponent {
  componentDidMount() {
    const {
      onMount,
      match: {
        params: { token },
      },
      history: { push },
    } = this.props;

    onMount(token, push);
  }

  render() {
    const { shouldShowCard } = this.props;

    return shouldShowCard ? (
      <LoginWrapper>
        <div className="account__card">
          <CompleteRegisterForm />
          <ConfirmationExpired />
          <CheckYourEmail />
          <div className="account__have-account">
            <p>
              Already have an account? <Link to={LOGIN}>Login</Link>
            </p>
          </div>
        </div>
      </LoginWrapper>
    ) : (
      <Loader isLoading />
    );
  }
}

CompleteRegistration.propTypes = {
  match: PropTypes.shape({
    params: PropTypes.shape({ token: PropTypes.string.isRequired }).isRequired,
  }).isRequired,
  history: PropTypes.shape({
    push: PropTypes.func.isRequired,
  }).isRequired,
  onMount: PropTypes.func.isRequired,
  shouldShowCard: PropTypes.bool.isRequired,
};

const mapStateToProps = state => ({
  isAuthenticated: auth.selectors.isAuthenticated(state),
  shouldShowCard:
    auth.selectors.isRegistrationCompletionVisible(state) ||
    auth.selectors.isRegistrationTokenExpired(state) ||
    auth.selectors.isCheckYourEmailScreenVisible(state),
});

const mapDispatchToProps = {
  onMount: auth.actions.checkConfirmationToken,
};

const enhance = compose(
  connect(
    mapStateToProps,
    mapDispatchToProps
  ),
  branch(
    props => props.isAuthenticated,
    renderComponent(() => <Redirect to={SDK} />)
  )
);

export default enhance(CompleteRegistration);
