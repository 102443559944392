import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import CODE_SNIPPETS from './codeSnippets';
import CodeHighlight from '../../components/CodeHighlight';
import { isAndroidInstructionsVisible } from '../../../ui/selectors';
import hideable from '../../utils/hideable';

const AndroidInstructions = ({ codeLanguage, dependenciesLanguage }) => (
  <div>
    <h2 className="title">Installing the Android Globalhop SDK</h2>
    <p className="note">
      This manual is made referring to Google Android studio IDE, so there may
      be some differences when using other IDEs, but the idea, in general,
      remains the same
    </p>
    <ol className="list">
      <li className="point">Import the SDK your project</li>
      <li className="point">
        Create a directory if it doesn`t exist{' '}
        <span className="inline-code">[Project directory]/app/libs</span>
      </li>
      <li className="point">
        Copy your <span className="inline-code">.aar</span> file to that
        directory
      </li>
      <li className="point">
        Add the dependencies to{' '}
        <span className="inline-code">
          Gradle Scripts &gt; build.Gradle (Module:app):
        </span>
      </li>
      <CodeHighlight
        code={CODE_SNIPPETS.ANDROID_DEPENDENCIES}
        language={dependenciesLanguage}
      />
      <li className="point">
        Start the SDK in onCreate method of the starting activity. The company
        name will be shown in the consent dialog
      </li>
      <CodeHighlight
        code={CODE_SNIPPETS.ANDROID_ONCREATE}
        language={codeLanguage}
      />
      <p className="note">
        If you need to know if the user accepted the consent, pass
        ConsentListener as the last parameter
      </p>
      <CodeHighlight
        code={CODE_SNIPPETS.ANDROID_ACTIVITY}
        language={codeLanguage}
      />
      <h3 className="subtitle">OPT-OUT option</h3>
      <p className="note">
        It is mandatory to add access for the user to opt out from the SDK usage
        in an easily accessible place such as ToggleButton in Settings screen
        (or other your preferred method):
      </p>
      <li className="point">
        Call <span className="inline-code">ServiceSetup.isEnabled()</span> when
        you want to know if SDK is enabled
      </li>
      <li className="point">
        Call <span className="inline-code">ServiceSetup.disable(context)</span>{' '}
        when the user wants to opt out
      </li>
      <li className="point">
        Call <span className="inline-code">ServiceSetup.enable()</span> when the
        user wants to opt in
      </li>
      <CodeHighlight
        code={CODE_SNIPPETS.ANDROID_CONSENT_DIALOG}
        language={codeLanguage}
      />
      <h3 className="subtitle">
        Example code with integrated OPT-OUT option using the toggle button:
      </h3>
      <CodeHighlight
        code={CODE_SNIPPETS.ANDROID_EXAMPLE}
        language={codeLanguage}
      />
    </ol>
  </div>
);

AndroidInstructions.propTypes = {
  codeLanguage: PropTypes.string.isRequired,
  dependenciesLanguage: PropTypes.string.isRequired,
};

const mapStateToProps = state => ({
  isVisible: isAndroidInstructionsVisible(state),
  dependenciesLanguage: 'gradle',
  codeLanguage: 'java',
});

export default connect(mapStateToProps)(hideable(AndroidInstructions));
