import { handleErrors, requestHeaders } from './utils';

export const config = {
  ERROR_INTERCEPTOR: handleErrors,
  DEFAULT_EVENT: {
    method: 'GET',
    credentials: 'include',
    headers: requestHeaders(false),
  },
};
